import 'select2';
import 'select2/dist/css/select2.css';
import sweetAlert from 'sweetalert';
import SidePanel from './sidepanel';

const AttendanceLimit = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('.js-limit-not-available').on('click', this.limitNotAvailable);
      $sidepanel.find('.attendance-limit-radio').on('change', this.changeAttendanceLimitOptions);
      const $select2 = $sidepanel.find('.js-select2-list-items');
      if ($select2.length === 0) {
        return;
      }
      $select2.on('change.select2', this.changedSelection);
      $select2.select2({
        closeOnSelect: false,
        width: '100%',
        placeholder: $select2.data('placeholder')
      });
      this.changedSelection({ currentTarget: $select2 });
      $sidepanel
        .find('.select2-search__field')
        .attr('data-dirrty-initial-value', '')
        .change();
    });
  },

  limitNotAvailable(event) {
    event.preventDefault();
    const { sweetAlertMessage, sweetAlertTitle } = event.currentTarget.dataset;
    sweetAlert({
      title: sweetAlertTitle,
      text: sweetAlertMessage,
      icon: 'warning',
      buttons: ['Cancel', 'Upgrade']
    }).then((willSubmit) => {
      if (willSubmit) {
        SidePanel.load(event.currentTarget.dataset.sidepanelHref);
      }
    });
  },

  changeAttendanceLimitOptions(event) {
    const selectedValue = event.currentTarget.value;
    const $form = $(event.target).closest('form');
    const $addWaitlist = $form.find('.js-add-waitlist');
    if (selectedValue === 'none') {
      $addWaitlist.addClass('is-hidden');
      $addWaitlist.find('input[value="false"]').prop('checked', true);
    } else {
      $addWaitlist.find('input[value="true"]').prop('checked', true);
      $addWaitlist.removeClass('is-hidden');
    }
    $form
      .find('.js-attendance-options')
      .addClass('is-hidden')
      .find('select, input, textarea')
      .prop('disabled', true);
    $form
      .find(`.js-attendance-options--${selectedValue}`)
      .removeClass('is-hidden')
      .find('select, input, textarea')
      .prop('disabled', false);
  },

  changedSelection(event) {
    const reducer = (accumulator, option) => accumulator + Number(option.dataset.spotsCount);
    const counter = $(event.currentTarget)
      .find('option:selected')
      .toArray()
      .reduce(reducer, 0);
    const $form = $(event.currentTarget).closest('form');
    const $spotsPlaceholder = $form.find('.js-spots-placeholder');
    $spotsPlaceholder.data('spotsCount', counter);
    const spotsName = $spotsPlaceholder.data('spotsName');
    $spotsPlaceholder.text(`${counter} ${spotsName}`);
  }
};

export default AttendanceLimit;
