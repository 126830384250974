const ToggleOnOff = {
  start() {
    $('[data-toggle-on-off]').click(this.toggle.bind(this));
  },
  toggle(e) {
    e.preventDefault();
    const $button = $(e.currentTarget);
    const $target = $($button.data('toggleOnOff'));
    if ($target.hasClass('is-hidden')) {
      this.show($target);
    } else {
      this.hide($target);
    }
    $button.toggleClass('is-on').toggleClass('is-off');
  },
  hide($element) {
    $element.removeClass('is-shown').addClass('is-hidden');
  },
  show($element) {
    $element.removeClass('is-hidden').addClass('is-shown');
  }
};

export default ToggleOnOff;
