<template>
  <chartist
    type="Bar"
    :data="chartData"
    :options="chartOptions"
    :event-handlers="events"
  />
</template>

<script>
export default {
  props: ['series', 'absoluteValue'],
  data() {
    return {
      chartData: {
        series: JSON.parse(this.series)
      },
      chartOptions: {
        distributeSeries: true,
        horizontalBars: true,
        showLabel: false,
        high: parseInt(this.absoluteValue),
        axisX: {
          onlyInteger: true,
          labelOffset: {
            x: -3
          },
        },
        axisY: {
          onlyInteger: true,
          offset: -10,
          showGrid: false
        },
        chartPadding: {
          right: 30,
          left: 30
        },
      },
      events: [{
        event: 'draw',
        fn: data => {
          if (data.type === 'bar') {
            data.element.attr({ style: 'stroke-width: 30px'});
          }
        }
      }]
    }
  }
}
</script>
