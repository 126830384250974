const SwitchOnOff = {
  start() {
    $('[data-switch-on-off]').click(this.selectFilter);
  },

  selectFilter(e) {
    e.preventDefault();
    const $selectedButton = $(e.currentTarget);
    const $target = $(e.currentTarget.dataset.switchOnOff);
    if ($selectedButton.hasClass('is-on')) {
      $target.removeClass('is-visible').addClass('is-hidden');
      $selectedButton.removeClass('is-on').addClass('is-off');
    } else {
      $target.removeClass('is-hidden').addClass('is-visible');
      $selectedButton.removeClass('is-off').addClass('is-on');
    }
  }
};

export default SwitchOnOff;
