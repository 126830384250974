<template>
  <chartist
    :class="className"
    type="Pie"
    :data="chartData"
    :options="chartOptions"
  />
</template>

<script>
export default {
  props: ['series', 'className'],
  data() {
    return {
      chartData: {
        series: JSON.parse(this.series)
      },
      chartOptions: {
        donut: true,
        fullWidth: true,
        donutWidth: 40,
        startAngle: 270,
        total: 200,
        showLabel: false,
        classNames: {
          label: 'chart__label'
        }
      }
    }
  }
}
</script>

