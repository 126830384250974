import SidePanel from './sidepanel';

const ToggleShown = {
  start() {
    $('[data-change-toggle-shown]').on('keyup', this.toggle);
    $(document).on('change', '[data-radio-toggle-shown]', this.toggleRadio);
    $('[data-hide-on-click]').on('click', this.hide);
    $('[data-show-on-click]').on('click', this.show);
    $('[data-toggle-shown-trigger]').each((_, element) => {
      if ($(element).data('toggle-shown-trigger') === true) {
        $(element).change();
      }
    });
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('[data-hide-on-click]').on('click', this.hide);
      $sidepanel.find('[data-show-on-click]').on('click', this.show);
    });
  },

  hide(e) {
    const $target = $(e.currentTarget.dataset.hideOnClick);
    $target.addClass('is-hidden').removeClass('is-visible');
  },

  show(e) {
    const $target = $(e.currentTarget.dataset.showOnClick);
    $target.removeClass('is-hidden').addClass('is-visible');
  },

  toggle(e) {
    const $target = $(e.currentTarget.dataset.changeToggleShown);
    if ($target && $target.hasClass('is-hidden')) {
      $target.removeClass('is-hidden').addClass('is-shown');
      $target.find('input:disabled').attr('disabled', false);
    }
  },

  toggleRadio(e) {
    const selector = `[name="${e.currentTarget.name}"]`;
    const selectedIndex = $(selector).index(e.currentTarget);
    const $targets = $(e.currentTarget.dataset.radioToggleShown);
    $targets.each((index, target) => {
      const $target = $(target);
      if (index === selectedIndex) {
        $target
          .removeClass('is-hidden')
          .addClass('is-shown')
          .find('form')
          .removeClass('form--readonly');
        $target
          .find('input:disabled, textarea:disabled')
          .not('.input--readonly')
          .attr('disabled', false);
      } else {
        $target
          .addClass('is-hidden')
          .removeClass('is-shown')
          .find('form')
          .addClass('form--readonly');
        $target.find('input, textarea').attr('disabled', true);
      }
      $(e.currentTarget).trigger('toggle:changed');
    });
  }
};

export default ToggleShown;
