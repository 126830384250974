import displayDatatablesPagination from '../shared/datatables_pagination';
import { withSaveState } from '../shared/datatables_state';
import {
  overridePaginationClasses,
  customizeDOMPaginationClasses
} from '../../datatables/customization-v2';
import {
  setup as tableSetup,
  setupPaginationOnDOM,
  customizePageLength,
  evaluateSelectedRows,
  defaultTableOptions
} from '../../datatables/table-setup';

export const LISTS_TABLE_SELECTOR = '.events-table';

const EventsTable = {
  start() {
    const $table = $(`${LISTS_TABLE_SELECTOR}:not(.dataTable)`);
    if (!$table.length) {
      return;
    }
    this.setupDataTable($table);
  },

  tearDown() {
    $(`${LISTS_TABLE_SELECTOR}.dataTable`).DataTable().destroy();
  },

  _toggleEventCompleted(event, table) {
    const isChecked = $(event.currentTarget)[0].checked;
    const regExSearch = '^Pending|Active$';
    if (isChecked) {
      table.columns().search('').draw();
    } else {
      table.columns(5).search(regExSearch, true, false, false).draw();
    }
  },

  _orderTiles($tableContainer, ids) {
    const $tilesContainer = $tableContainer.find('.js-tiles-in-progress,.js-tiles-upcoming,.js-tiles-completed');
    const $blankSlate = $tableContainer.find('.js-blank-slate').children();
    $tilesContainer.empty();
    const $cards = $tableContainer.find('.js-all-events-tiles');
    const $inProgressTilesContainer = $tableContainer.find('.js-tiles-in-progress');
    const $upcomingTilesContainer = $tableContainer.find('.js-tiles-upcoming');
    const $completedTilesContainer = $tableContainer.find('.js-tiles-completed');
    const $inProgressSubtitle = $tableContainer.find('.js-subtitle-in-progress');
    const $upcomingSubtitle = $tableContainer.find('.js-subtitle-upcoming');
    const $completedSubtitle = $tableContainer.find('.js-subtitle-completed');

    ids.each((id) => {
      const $card = $cards.find(`.js-event-card[data-id="${id}"]`).first().clone(true);
      switch ($card.data('status')) {
        case 'Active':
          $inProgressTilesContainer.append($card);
          break;
        case 'Pending':
          $upcomingTilesContainer.append($card);
          break;
        case 'Completed':
          $completedTilesContainer.append($card);
          break;
        default:
          break;
      }
    });
    const inProgressEventsCount = $inProgressTilesContainer.find('.js-event-card').length;
    const upcomingEventsCount = $upcomingTilesContainer.find('.js-event-card').length;
    const completedEventsCount = $completedTilesContainer.find('.js-event-card').length;
    $inProgressSubtitle.text(`${$inProgressSubtitle.data('subtitle')} (${inProgressEventsCount})`);
    $upcomingSubtitle.text(`${$upcomingSubtitle.data('subtitle')} (${upcomingEventsCount})`);
    $completedSubtitle.text(`${$completedSubtitle.data('subtitle')} (${completedEventsCount})`);
    if (inProgressEventsCount === 0) {
      const $blankSlateClone = $blankSlate.clone();
      $inProgressTilesContainer.append($blankSlateClone);
    }
    if (upcomingEventsCount === 0) {
      const $blankSlateClone = $blankSlate.clone();
      $upcomingTilesContainer.append($blankSlateClone);
    }
    if (completedEventsCount === 0) {
      const $blankSlateClone = $blankSlate.clone();
      $completedTilesContainer.append($blankSlateClone);
    }
  },

  _renderTiles(event) {
    const $table = $(event.currentTarget);
    const $tableContainer = $table.closest('.js-events-table-container');
    const table = $table.dataTable();
    const ids = table.api().rows({ order: 'current', page: 'all', search: 'applied' }).ids();
    this._orderTiles($tableContainer, ids);
    $(document).trigger('lazyload:update');
  },

  _updateItemsCounter(event) {
    const $table = $(event.currentTarget);
    const tableApi = $table.dataTable().api();
    const $totalItems = $table.closest('.js-events-table-container').find('[data-total-items]');
    const totalItemsLabel = $totalItems.data('totalItems');
    const itemsWithSearchCount = tableApi.rows({ order: 'current', page: 'all', search: 'applied' })
      .count();
    const allItemsCount = tableApi.rows({ order: 'current', page: 'all' }).count();
    if (itemsWithSearchCount === allItemsCount) {
      $totalItems.text(totalItemsLabel);
    } else {
      $totalItems.text(`${itemsWithSearchCount} of ${totalItemsLabel}`);
    }
  },

  setupDataTable($table) {
    overridePaginationClasses();
    const $checkAllCheckbox = $('#checkall');
    let table;
    const tableOptions = {
      ...defaultTableOptions,
      order: [[7, 'desc']],
      dom: 'rtp',
      select: {
        style: 'multi',
        selector: 'label.js-checkbox-for-row',
        className: 'is-selected'
      },
      columnDefs: [
        { targets: [0], orderable: false, width: '7%' },
        { targets: [1], searchable: true, width: '20%' },
        { targets: [5], searchable: true },
        { targets: [-1], orderable: false, width: '9%' },
        { targets: '_all', searchable: false }
      ],
      drawCallback() {
        customizePageLength(this);
        customizeDOMPaginationClasses();
        evaluateSelectedRows(table, $checkAllCheckbox);
        displayDatatablesPagination(this);
      },
      initComplete: () => {
        setupPaginationOnDOM();
      },
      language: {
        ...defaultTableOptions.language,
        zeroRecords: '<span class="alert alert--info">Sorry, no matching Events were found</span>',
        emptyTable: '<span class="alert alert--info">No Events available</span>'
      }
    };
    const tableOptionsWithSaveState = withSaveState($table, tableOptions);
    table = $table.DataTable(tableOptionsWithSaveState);
    table.on('draw', this._renderTiles.bind(this));
    table.on('draw', this._updateItemsCounter.bind(this));
    const $completedToggle = $table
      .closest('.js-events-table-container')
      .find('.js-toggle-event-completed');
    table.on('stateLoaded.dt', function (e, settings, data) {
      const statusColumn = data.columns[4];
      const { search: { search } } = statusColumn;
      const hideCompleted = search === '^Pending|Active$';
      if (hideCompleted) {
        $completedToggle[0].checked = false;
      }
    });
    $completedToggle
      .on('change', (event) => this._toggleEventCompleted(event, table));
    tableSetup(table);
  }
};

export default EventsTable;
