<template>
  <div>
    <div class="sidepanel__block">
      <h6 class="subtitle">2. Select Event Activity to Copy</h6>
      <hr>
      <fieldset>
        <select v-model="selected" class="select select--full">
          <option v-for="option in eventParts" v-bind:value="option.value">
            {{ option.name }}
          </option>
        </select>
        <input v-model="selected" name="event_part[copy_from_id]" type="hidden">
      </fieldset>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">3. Activity Name</h6>
      <hr>
      <fieldset>
        <input v-model="name" maxlength="50" size="50" type="text" name="event_part[name]" id="event_part_name" class="input input--full input-max-length">
        <div v-for="error in nameError" class="error">{{error}}</div>
      </fieldset>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">4. Starting Date/Time</h6>
      <hr>
      <fieldset>
        <datepicker-with-timezone
          row-label="Start"
          :initial-time=time
          :initial-timezone=timezone
          datetime-field-name="event_part[start_date_time]"
          timezone-field-name="event_part[start_time_zone]"
          required="true"/>
        <div v-for="error in startError" class="error">{{error}}</div>
      </fieldset>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">5. Description</h6>
      <hr>
      <fieldset>
        <textarea v-model="description" name="event_part[description]" id="event_part_description" rows="4"></textarea>
        <div v-for="error in descriptionError" class="error">{{error}}</div>
      </fieldset>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">6. Location</h6>
      <hr>
      <fieldset>
        <location-autocomplete
          :initialLocation="location"
          :initialLocationName="locationName"
          :initialLocationLat="locationLat"
          :initialLocationLng="locationLng"
          :errors="locationError"
          resourceName="event_part"
        ></location-autocomplete>
      </fieldset>
    </div>
  </div>
</template>

<script>

export default {
  props: ['event-parts',
          'fixed-initial-time',
          'initial-name',
          'initial-description',
          'initial-time',
          'initial-timezone',
          'initial-selected',
          'start-error',
          'name-error',
          'location-error',
          'description-error'],

  data() {
    return {
      selected: this.initialSelected,
      name: this.initialName,
      time: (this.fixedInitialTime || this.initialTime),
      timezone: this.initialTimezone,
      description: this.initialDescription,
      location: "",
      locationName: "",
      locationLat: null,
      locationLng: null
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.selected = this.selected || (this.eventParts[0] && this.eventParts[0].value) || null;
    });
  },

  watch: {
    selected() {
      this.updateCopyEventPart()
    }
  },

  methods: {
    updateCopyEventPart() {
      if (!this.selected) {
        this.time = this.fixedInitialTime || moment();
        return;
      }
      this.name = `Copy of ${this.selectedEventPart.name}`;
      this.time = this.fixedInitialTime || this.selectedEventPart.start_date_time;
      this.timezone = this.selectedEventPart.timezone;
      this.location = this.selectedEventPart.location;
      this.locationName = this.selectedEventPart.location_name;
      this.locationLat = this.selectedEventPart.location_lat;
      this.locationLng = this.selectedEventPart.location_lng;
      this.description = this.selectedEventPart.description;
    }
  },

  computed: {
    selectedEventPart() {
      return this.eventParts.find(eventPart => eventPart.value === this.selected);
    }
  }
}
</script>
