import SidePanel from './sidepanel';

const AttendanceChangeToggle = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel
        .find('.js-ask-if-attending-toggle .toggle__el')
        .change(this.updateQuestionVisibility);
    });
  },

  updateQuestionVisibility(event) {
    const $currentTarget = $(event.currentTarget);
    const questionSelector = $currentTarget
      .closest('.js-ask-if-attending-toggle')
      .data('askIfAttendingQuestionSelector');
    const $questionFieldset = $currentTarget
      .closest('form')
      .find(questionSelector);
    if (event.currentTarget.value === 'false') {
      $questionFieldset
        .addClass('is-hidden')
        .find('input')
        .prop('disabled', 'disabled');
    } else {
      $questionFieldset
        .removeClass('is-hidden')
        .find('input')
        .prop('disabled', '');
    }
  }
};

export default AttendanceChangeToggle;
