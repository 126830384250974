/* jshint esversion: 6 */

export const timezones = [
  {'value': 'American Samoa', 'text': '(GMT-11:00) American Samoa', 'identifier': 'Pacific/Pago_Pago'},
  {'value': 'International Date Line West', 'text': '(GMT-11:00) International Date Line West', 'identifier': 'Etc/GMT+12'},
  {'value': 'Midway Island', 'text': '(GMT-11:00) Midway Island', 'identifier': 'Pacific/Midway'},
  {'value': 'Pacific/Midway', 'text': '(GMT-11:00) Pacific/Midway', 'identifier': 'Pacific/Midway'},
  {'value': 'Hawaii', 'text': '(GMT-10:00) Hawaii', 'identifier': 'Pacific/Honolulu'},
  {'value': 'Alaska', 'text': '(GMT-09:00) Alaska', 'identifier': 'America/Juneau'},
  {'value': 'Pacific Time (US & Canada)', 'text': '(GMT-08:00) Pacific Time (US & Canada)', 'identifier': 'America/Los_Angeles'},
  {'value': 'Tijuana', 'text': '(GMT-08:00) Tijuana', 'identifier': 'America/Tijuana'},
  {'value': 'Arizona', 'text': '(GMT-07:00) Arizona', 'identifier': 'America/Phoenix'},
  {'value': 'Chihuahua', 'text': '(GMT-07:00) Chihuahua', 'identifier': 'America/Chihuahua'},
  {'value': 'Mazatlan', 'text': '(GMT-07:00) Mazatlan', 'identifier': 'America/Mazatlan'},
  {'value': 'Mountain Time (US & Canada)', 'text': '(GMT-07:00) Mountain Time (US & Canada)', 'identifier': 'America/Denver'},
  {'value': 'Central America', 'text': '(GMT-06:00) Central America', 'identifier': 'America/Guatemala'},
  {'value': 'Central Time (US & Canada)', 'text': '(GMT-06:00) Central Time (US & Canada)', 'identifier': 'America/Chicago'},
  {'value': 'Guadalajara', 'text': '(GMT-06:00) Guadalajara', 'identifier': 'America/Mexico_City'},
  {'value': 'Mexico City', 'text': '(GMT-06:00) Mexico City', 'identifier': 'America/Mexico_City'},
  {'value': 'Monterrey', 'text': '(GMT-06:00) Monterrey', 'identifier': 'America/Monterrey'},
  {'value': 'Saskatchewan', 'text': '(GMT-06:00) Saskatchewan', 'identifier': 'America/Regina'},
  {'value': 'Bogota', 'text': '(GMT-05:00) Bogota', 'identifier': 'America/Bogota'},
  {'value': 'Eastern Time (US & Canada)', 'text': '(GMT-05:00) Eastern Time (US & Canada)', 'identifier': 'America/New_York'},
  {'value': 'Indiana (East)', 'text': '(GMT-05:00) Indiana (East)', 'identifier': 'America/Indiana/Indianapolis'},
  {'value': 'Lima', 'text': '(GMT-05:00) Lima', 'identifier': 'America/Lima'},
  {'value': 'Quito', 'text': '(GMT-05:00) Quito', 'identifier': 'America/Lima'},
  {'value': 'Atlantic Time (Canada)', 'text': '(GMT-04:00) Atlantic Time (Canada)', 'identifier': 'America/Halifax'},
  {'value': 'Caracas', 'text': '(GMT-04:00) Caracas', 'identifier': 'America/Caracas'},
  {'value': 'Georgetown', 'text': '(GMT-04:00) Georgetown', 'identifier': 'America/Guyana'},
  {'value': 'La Paz', 'text': '(GMT-04:00) La Paz', 'identifier': 'America/La_Paz'},
  {'value': 'Santiago', 'text': '(GMT-04:00) Santiago', 'identifier': 'America/Santiago'},
  {'value': 'Newfoundland', 'text': '(GMT-03:30) Newfoundland', 'identifier': 'America/St_Johns'},
  {'value': 'Brasilia', 'text': '(GMT-03:00) Brasilia', 'identifier': 'America/Sao_Paulo'},
  {'value': 'Buenos Aires', 'text': '(GMT-03:00) Buenos Aires', 'identifier': 'America/Argentina/Buenos_Aires'},
  {'value': 'Greenland', 'text': '(GMT-03:00) Greenland', 'identifier': 'America/Godthab'},
  {'value': 'Montevideo', 'text': '(GMT-03:00) Montevideo', 'identifier': 'America/Montevideo'},
  {'value': 'Mid-Atlantic', 'text': '(GMT-02:00) Mid-Atlantic', 'identifier': 'Atlantic/South_Georgia'},
  {'value': 'Azores', 'text': '(GMT-01:00) Azores', 'identifier': 'Atlantic/Azores'},
  {'value': 'Cape Verde Is.', 'text': '(GMT-01:00) Cape Verde Is.', 'identifier': 'Atlantic/Cape_Verde'},
  {'value': 'Casablanca', 'text': '(GMT+00:00) Casablanca', 'identifier': 'Africa/Casablanca'},
  {'value': 'Dublin', 'text': '(GMT+00:00) Dublin', 'identifier': 'Europe/Dublin'},
  {'value': 'Edinburgh', 'text': '(GMT+00:00) Edinburgh', 'identifier': 'Europe/London'},
  {'value': 'Lisbon', 'text': '(GMT+00:00) Lisbon', 'identifier': 'Europe/Lisbon'},
  {'value': 'London', 'text': '(GMT+00:00) London', 'identifier': 'Europe/London'},
  {'value': 'Monrovia', 'text': '(GMT+00:00) Monrovia', 'identifier': 'Africa/Monrovia'},
  {'value': 'UTC', 'text': '(GMT+00:00) UTC', 'identifier': 'Etc/UTC'},
  {'value': 'Amsterdam', 'text': '(GMT+01:00) Amsterdam', 'identifier': 'Europe/Amsterdam'},
  {'value': 'Belgrade', 'text': '(GMT+01:00) Belgrade', 'identifier': 'Europe/Belgrade'},
  {'value': 'Berlin', 'text': '(GMT+01:00) Berlin', 'identifier': 'Europe/Berlin'},
  {'value': 'Bern', 'text': '(GMT+01:00) Bern', 'identifier': 'Europe/Zurich'},
  {'value': 'Bratislava', 'text': '(GMT+01:00) Bratislava', 'identifier': 'Europe/Bratislava'},
  {'value': 'Brussels', 'text': '(GMT+01:00) Brussels', 'identifier': 'Europe/Brussels'},
  {'value': 'Budapest', 'text': '(GMT+01:00) Budapest', 'identifier': 'Europe/Budapest'},
  {'value': 'Copenhagen', 'text': '(GMT+01:00) Copenhagen', 'identifier': 'Europe/Copenhagen'},
  {'value': 'Ljubljana', 'text': '(GMT+01:00) Ljubljana', 'identifier': 'Europe/Ljubljana'},
  {'value': 'Madrid', 'text': '(GMT+01:00) Madrid', 'identifier': 'Europe/Madrid'},
  {'value': 'Paris', 'text': '(GMT+01:00) Paris', 'identifier': 'Europe/Paris'},
  {'value': 'Prague', 'text': '(GMT+01:00) Prague', 'identifier': 'Europe/Prague'},
  {'value': 'Rome', 'text': '(GMT+01:00) Rome', 'identifier': 'Europe/Rome'},
  {'value': 'Sarajevo', 'text': '(GMT+01:00) Sarajevo', 'identifier': 'Europe/Sarajevo'},
  {'value': 'Skopje', 'text': '(GMT+01:00) Skopje', 'identifier': 'Europe/Skopje'},
  {'value': 'Stockholm', 'text': '(GMT+01:00) Stockholm', 'identifier': 'Europe/Stockholm'},
  {'value': 'Vienna', 'text': '(GMT+01:00) Vienna', 'identifier': 'Europe/Vienna'},
  {'value': 'Warsaw', 'text': '(GMT+01:00) Warsaw', 'identifier': 'Europe/Warsaw'},
  {'value': 'West Central Africa', 'text': '(GMT+01:00) West Central Africa', 'identifier': 'Africa/Algiers'},
  {'value': 'Zagreb', 'text': '(GMT+01:00) Zagreb', 'identifier': 'Europe/Zagreb'},
  {'value': 'Zurich', 'text': '(GMT+01:00) Zurich', 'identifier': 'Europe/Zurich'},
  {'value': 'Athens', 'text': '(GMT+02:00) Athens', 'identifier': 'Europe/Athens'},
  {'value': 'Bucharest', 'text': '(GMT+02:00) Bucharest', 'identifier': 'Europe/Bucharest'},
  {'value': 'Cairo', 'text': '(GMT+02:00) Cairo', 'identifier': 'Africa/Cairo'},
  {'value': 'Harare', 'text': '(GMT+02:00) Harare', 'identifier': 'Africa/Harare'},
  {'value': 'Helsinki', 'text': '(GMT+02:00) Helsinki', 'identifier': 'Europe/Helsinki'},
  {'value': 'Jerusalem', 'text': '(GMT+02:00) Jerusalem', 'identifier': 'Asia/Jerusalem'},
  {'value': 'Kaliningrad', 'text': '(GMT+02:00) Kaliningrad', 'identifier': 'Europe/Kaliningrad'},
  {'value': 'Kyiv', 'text': '(GMT+02:00) Kyiv', 'identifier': 'Europe/Kiev'},
  {'value': 'Pretoria', 'text': '(GMT+02:00) Pretoria', 'identifier': 'Africa/Johannesburg'},
  {'value': 'Riga', 'text': '(GMT+02:00) Riga', 'identifier': 'Europe/Riga'},
  {'value': 'Sofia', 'text': '(GMT+02:00) Sofia', 'identifier': 'Europe/Sofia'},
  {'value': 'Tallinn', 'text': '(GMT+02:00) Tallinn', 'identifier': 'Europe/Tallinn'},
  {'value': 'Vilnius', 'text': '(GMT+02:00) Vilnius', 'identifier': 'Europe/Vilnius'},
  {'value': 'Baghdad', 'text': '(GMT+03:00) Baghdad', 'identifier': 'Asia/Baghdad'},
  {'value': 'Istanbul', 'text': '(GMT+03:00) Istanbul', 'identifier': 'Europe/Istanbul'},
  {'value': 'Kuwait', 'text': '(GMT+03:00) Kuwait', 'identifier': 'Asia/Kuwait'},
  {'value': 'Minsk', 'text': '(GMT+03:00) Minsk', 'identifier': 'Europe/Minsk'},
  {'value': 'Moscow', 'text': '(GMT+03:00) Moscow', 'identifier': 'Europe/Moscow'},
  {'value': 'Nairobi', 'text': '(GMT+03:00) Nairobi', 'identifier': 'Africa/Nairobi'},
  {'value': 'Riyadh', 'text': '(GMT+03:00) Riyadh', 'identifier': 'Asia/Riyadh'},
  {'value': 'St. Petersburg', 'text': '(GMT+03:00) St. Petersburg', 'identifier': 'Europe/Moscow'},
  {'value': 'Volgograd', 'text': '(GMT+03:00) Volgograd', 'identifier': 'Europe/Volgograd'},
  {'value': 'Tehran', 'text': '(GMT+03:30) Tehran', 'identifier': 'Asia/Tehran'},
  {'value': 'Abu Dhabi', 'text': '(GMT+04:00) Abu Dhabi', 'identifier': 'Asia/Muscat'},
  {'value': 'Baku', 'text': '(GMT+04:00) Baku', 'identifier': 'Asia/Baku'},
  {'value': 'Muscat', 'text': '(GMT+04:00) Muscat', 'identifier': 'Asia/Muscat'},
  {'value': 'Samara', 'text': '(GMT+04:00) Samara', 'identifier': 'Europe/Samara'},
  {'value': 'Tbilisi', 'text': '(GMT+04:00) Tbilisi', 'identifier': 'Asia/Tbilisi'},
  {'value': 'Yerevan', 'text': '(GMT+04:00) Yerevan', 'identifier': 'Asia/Yerevan'},
  {'value': 'Kabul', 'text': '(GMT+04:30) Kabul', 'identifier': 'Asia/Kabul'},
  {'value': 'Ekaterinburg', 'text': '(GMT+05:00) Ekaterinburg', 'identifier': 'Asia/Yekaterinburg'},
  {'value': 'Islamabad', 'text': '(GMT+05:00) Islamabad', 'identifier': 'Asia/Karachi'},
  {'value': 'Karachi', 'text': '(GMT+05:00) Karachi', 'identifier': 'Asia/Karachi'},
  {'value': 'Tashkent', 'text': '(GMT+05:00) Tashkent', 'identifier': 'Asia/Tashkent'},
  {'value': 'Chennai', 'text': '(GMT+05:30) Chennai', 'identifier': 'Asia/Kolkata'},
  {'value': 'Kolkata', 'text': '(GMT+05:30) Kolkata', 'identifier': 'Asia/Kolkata'},
  {'value': 'Mumbai', 'text': '(GMT+05:30) Mumbai', 'identifier': 'Asia/Kolkata'},
  {'value': 'New Delhi', 'text': '(GMT+05:30) New Delhi', 'identifier': 'Asia/Kolkata'},
  {'value': 'Sri Jayawardenepura', 'text': '(GMT+05:30) Sri Jayawardenepura', 'identifier': 'Asia/Colombo'},
  {'value': 'Kathmandu', 'text': '(GMT+05:45) Kathmandu', 'identifier': 'Asia/Kathmandu'},
  {'value': 'Almaty', 'text': '(GMT+06:00) Almaty', 'identifier': 'Asia/Almaty'},
  {'value': 'Astana', 'text': '(GMT+06:00) Astana', 'identifier': 'Asia/Dhaka'},
  {'value': 'Dhaka', 'text': '(GMT+06:00) Dhaka', 'identifier': 'Asia/Dhaka'},
  {'value': 'Urumqi', 'text': '(GMT+06:00) Urumqi', 'identifier': 'Asia/Urumqi'},
  {'value': 'Rangoon', 'text': '(GMT+06:30) Rangoon', 'identifier': 'Asia/Rangoon'},
  {'value': 'Bangkok', 'text': '(GMT+07:00) Bangkok', 'identifier': 'Asia/Bangkok'},
  {'value': 'Hanoi', 'text': '(GMT+07:00) Hanoi', 'identifier': 'Asia/Bangkok'},
  {'value': 'Jakarta', 'text': '(GMT+07:00) Jakarta', 'identifier': 'Asia/Jakarta'},
  {'value': 'Krasnoyarsk', 'text': '(GMT+07:00) Krasnoyarsk', 'identifier': 'Asia/Krasnoyarsk'},
  {'value': 'Novosibirsk', 'text': '(GMT+07:00) Novosibirsk', 'identifier': 'Asia/Novosibirsk'},
  {'value': 'Beijing', 'text': '(GMT+08:00) Beijing', 'identifier': 'Asia/Shanghai'},
  {'value': 'Chongqing', 'text': '(GMT+08:00) Chongqing', 'identifier': 'Asia/Chongqing'},
  {'value': 'Hong Kong', 'text': '(GMT+08:00) Hong Kong', 'identifier': 'Asia/Hong_Kong'},
  {'value': 'Irkutsk', 'text': '(GMT+08:00) Irkutsk', 'identifier': 'Asia/Irkutsk'},
  {'value': 'Kuala Lumpur', 'text': '(GMT+08:00) Kuala Lumpur', 'identifier': 'Asia/Kuala_Lumpur'},
  {'value': 'Perth', 'text': '(GMT+08:00) Perth', 'identifier': 'Australia/Perth'},
  {'value': 'Singapore', 'text': '(GMT+08:00) Singapore', 'identifier': 'Asia/Singapore'},
  {'value': 'Taipei', 'text': '(GMT+08:00) Taipei', 'identifier': 'Asia/Taipei'},
  {'value': 'Ulaanbaatar', 'text': '(GMT+08:00) Ulaanbaatar', 'identifier': 'Asia/Ulaanbaatar'},
  {'value': 'Osaka', 'text': '(GMT+09:00) Osaka', 'identifier': 'Asia/Tokyo'},
  {'value': 'Sapporo', 'text': '(GMT+09:00) Sapporo', 'identifier': 'Asia/Tokyo'},
  {'value': 'Seoul', 'text': '(GMT+09:00) Seoul', 'identifier': 'Asia/Seoul'},
  {'value': 'Tokyo', 'text': '(GMT+09:00) Tokyo', 'identifier': 'Asia/Tokyo'},
  {'value': 'Yakutsk', 'text': '(GMT+09:00) Yakutsk', 'identifier': 'Asia/Yakutsk'},
  {'value': 'Adelaide', 'text': '(GMT+09:30) Adelaide', 'identifier': 'Australia/Adelaide'},
  {'value': 'Darwin', 'text': '(GMT+09:30) Darwin', 'identifier': 'Australia/Darwin'},
  {'value': 'Brisbane', 'text': '(GMT+10:00) Brisbane', 'identifier': 'Australia/Brisbane'},
  {'value': 'Canberra', 'text': '(GMT+10:00) Canberra', 'identifier': 'Australia/Melbourne'},
  {'value': 'Guam', 'text': '(GMT+10:00) Guam', 'identifier': 'Pacific/Guam'},
  {'value': 'Hobart', 'text': '(GMT+10:00) Hobart', 'identifier': 'Australia/Hobart'},
  {'value': 'Melbourne', 'text': '(GMT+10:00) Melbourne', 'identifier': 'Australia/Melbourne'},
  {'value': 'Port Moresby', 'text': '(GMT+10:00) Port Moresby', 'identifier': 'Pacific/Port_Moresby'},
  {'value': 'Sydney', 'text': '(GMT+10:00) Sydney', 'identifier': 'Australia/Sydney'},
  {'value': 'Vladivostok', 'text': '(GMT+10:00) Vladivostok', 'identifier': 'Asia/Vladivostok'},
  {'value': 'Magadan', 'text': '(GMT+11:00) Magadan', 'identifier': 'Asia/Magadan'},
  {'value': 'New Caledonia', 'text': '(GMT+11:00) New Caledonia', 'identifier': 'Pacific/Noumea'},
  {'value': 'Solomon Is.', 'text': '(GMT+11:00) Solomon Is.', 'identifier': 'Pacific/Guadalcanal'},
  {'value': 'Srednekolymsk', 'text': '(GMT+11:00) Srednekolymsk', 'identifier': 'Asia/Srednekolymsk'},
  {'value': 'Auckland', 'text': '(GMT+12:00) Auckland', 'identifier': 'Pacific/Auckland'},
  {'value': 'Fiji', 'text': '(GMT+12:00) Fiji', 'identifier': 'Pacific/Fiji'},
  {'value': 'Kamchatka', 'text': '(GMT+12:00) Kamchatka', 'identifier': 'Asia/Kamchatka'},
  {'value': 'Marshall Is.', 'text': '(GMT+12:00) Marshall Is.', 'identifier': 'Pacific/Majuro'},
  {'value': 'Wellington', 'text': '(GMT+12:00) Wellington', 'identifier': 'Pacific/Auckland'},
  {'value': 'Chatham Is.', 'text': '(GMT+12:45) Chatham Is.', 'identifier': 'Pacific/Chatham'},
  {'value': 'Nuku\'alofa', 'text': '(GMT+13:00) Nuku\'alofa', 'identifier': 'Pacific/Tongatapu'},
  {'value': 'Samoa', 'text': '(GMT+13:00) Samoa', 'identifier': 'Pacific/Apia'},
  {'value': 'Tokelau Is.', 'text': '(GMT+13:00) Tokelau Is.', 'identifier': 'Pacific/Fakaofo'}
];

export const browserTimeZone = timezones.find(tz => tz.identifier === Intl.DateTimeFormat().resolvedOptions().timeZone);
