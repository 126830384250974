const ToggleCollapse = {
  start() {
    $('[data-toggle-collapse]').click(this.toggle);
  },
  toggle(e) {
    e.preventDefault();
    const $target = $(this).prev('[data-collapse]');
    $target.toggleClass('is-collapsed');
  }
};

export default ToggleCollapse;
