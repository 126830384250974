<template>
  <div class="card card--big card--transparent card--overlap card--initial no-space-out">
    <div class="flash flash--action flash--info flash--spaced is-sticky is-permanent is-visible" role="alert" v-if="isDisabled">
      <div class="flash__data">
        <svg class="icon icon--small icon--white">
          <use :xlink:href="iconInfoUrl"></use>
        </svg>
        <span>{{t('disabled_flash_message')}}</span>
      </div>
    </div>
    <div :class="[showFlashMenu ? 'is-visible' : 'is-invisible']" class="flash flash--action flash--info flash--spaced is-permanent is-sticky">
      <div class="flash__data">
        <a @click.prevent.stop="unselectAllPeople" href="#" class="button button--circle button--transparent button--outline no-space-out flash__close">
          <svg class="icon icon--tiny icon--white button__icon">
            <use :xlink:href="iconCloseUrl"></use>
          </svg>
        </a>
        <span>{{countSelectedLabel}}</span>
      </div>
      <ul class="flash__actions">
        <li class="flash__item dropdown__item">
          <button @click.prevent.stop :disabled="allSelectedPeopleIds.length == 0" class="button button--white button--light button--square no-space-out">
            <span>{{t('move')}}</span>
            <select @change="addPeopleToPlace(allSelectedPeopleIds)"
                    v-model="selectedPlace"
                    class="select select--invisible dropdown__select">
              <option disabled selected value="">{{t('choose_on_place')}}</option>
              <option :key="place.attributes.id" :disabled="!hasAvailableSpots(place)" v-for="place in orderedItems" :value="place.attributes.id">
                {{placeNameWithSpots(place)}}
              </option>
            </select>
          </button>
        </li>

        <li class="flash__item">
          <button @click="unassignPeopleWithConfirmation(selectedPeopleIds)" :disabled="selectedPeopleIds.length == 0" class="button button--white button--light button--square no-space-out" type="button">
            <div class="button__holder">
              <span class="button__text">{{t('unassign')}}</span>
            </div>
          </button>
        </li>
      </ul>
    </div>
    <header class="card__header card__header--transparent">
      <div class="card__headline card__headline--auto">
        <h6 class="card__title card__title--transparent">
          <span class="card__supertext">
            {{t('title')}}
            <span v-if="spotsAreLimited" class="tooltip" :data-tooltip="t('limited_spots')">
              <svg class="icon icon--tiny icon--yellow icon--spaced button__icon">
                <use :xlink:href="iconWarningUrl"></use>
              </svg>
            </span>
          </span>
          <small class="card__subtext" v-if="assignableSpotsLabel">
            {{ assignmentsCounterLabel }}
          </small>
        </h6>
        <a :href="assignmentsSettingsUrl" data-sidepanel="true" :data-tooltip="t('settings')" class="button button--white button--light button--stroke button--circle button--headline tooltip">
          <div class="button__holder">
            <svg class="icon button__icon">
              <use :xlink:href="iconSettingsUrl"></use>
            </svg>
          </div>
        </a>
      </div>
      <ul class="card__actions actionbar actionbar--transparent is-print-hidden">
        <li class="card__action card__action--transparent">
          <div class="search">
            <input v-model="peopleSearch" type="search" :placeholder="t('search')" class="input search__input"/>
            <svg class="search__icon icon icon--tiny icon--slategray">
              <use :xlink:href="iconSearchUrl"></use>
            </svg>
          </div>
        </li>

        <li class="card__action card__action--transparent">
          <div class="dropdown dropdown--floating dropdown--action">
            <div class="dropdown__holder">
              <a @click.prevent.stop href="#" class="dropdown__button dropdown__button--action">
                <div class="dropdown__content">
                  <span class="dropdown__label">{{t('filters')}}</span>
                  <strong :class="[enabledAllFiltersCounter > 0 ? 'dropdown__counter' : 'is-hidden']">{{enabledAllFiltersCounter}}</strong>
                </div>
                <svg class="icon icon--tiny icon--slategray dropdown__icon">
                  <use :xlink:href="iconFiltersUrl"></use>
                </svg>
              </a>
              <ul class="dropdown__list dropdown__list--floating dropdown__list--medium dropdown__list--right">
                <li class="dropdown__item dropdown__item--bordered">
                  <a class="dropdown__link" data-sidepanel="true" :href="peopleEditFiltersUrl">
                    <span class="dropdown__legend dropdown__legend--center">{{t('people_filters')}}</span>
                    <strong :class="[enabledPeopleFiltersCounter > 0 ? 'dropdown__text' : 'is-invisible']">{{enabledPeopleFiltersCounter}}</strong>
                  </a>
                </li>
                <li class="dropdown__item dropdown__item--bordered">
                  <a class="dropdown__link" data-sidepanel="true" :href="placesEditFiltersUrl">
                    <span class="dropdown__legend dropdown__legend--center">{{t('places_filters')}}</span>
                    <strong :class="[enabledPlacesFiltersCounter > 0 ? 'dropdown__text' : 'is-invisible']">{{enabledPlacesFiltersCounter}}</strong>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>

        <li class="card__action card__action--transparent">
          <div class="dropdown dropdown--floating dropdown--action">
            <div class="dropdown__holder">
              <a href="#" @click.prevent.stop class="dropdown__button dropdown__button--action">
                <div class="dropdown__content">
                  <span class="dropdown__label">{{t('actions')}}</span>
                </div>
                <svg class="icon icon--tiny icon--slategray dropdown__icon">
                  <use :xlink:href="iconDotsVerticalUrl"></use>
                </svg>
              </a>
              <ul class="dropdown__list dropdown__list--floating dropdown__list--medium dropdown__list--right">
                <li class="dropdown__item">
                  <a :href="editSortUrl" data-sidepanel="true" class="dropdown__link">
                    <span class="dropdown__legend">{{t('sort_options')}}</span>
                  </a>
                </li>
                <li class="dropdown__item">
                  <a @click.prevent.stop="enableSelection" href="#" class="dropdown__link">
                    <span class="dropdown__legend">{{t('select')}}</span>
                  </a>
                </li>
                <li class="dropdown__item">
                  <a @click.prevent.stop="selectAllPeople" href="#" class="dropdown__link">
                    <span class="dropdown__legend">{{t('select_all')}}</span>
                  </a>
                </li>
                <li class="dropdown__item">
                  <a @click.prevent.stop="selectAssignedPeople" href="#" class="dropdown__link">
                    <span class="dropdown__legend">{{t('select_assigned')}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="card__action card__action--transparent">
          <button :class="[isTiles(columnsView) ? 'is-on' : 'is-off']"
                  @click="toggleView('tiles')"
                  :data-tooltip="t('tiles')"
                  class="button button--white button--light button--stroke button--square no-space-out actionbar__button actionbar__button--tall tooltip" type="button">
            <div class="button__holder">
              <svg class="icon button__icon">
                <use :xlink:href="iconDashboardUrl"></use>
              </svg>
            </div>
          </button>
          <button :class="[isColumns(columnsView) ? 'is-on' : 'is-off']"
                  @click="toggleView('columns')"
                  :data-tooltip="t('columns')"
                  class="button button--white button--light button--stroke button--square no-space-out actionbar__button actionbar__button--tall tooltip" type="button">
            <div class="button__holder">
              <svg class="icon button__icon">
                <use :xlink:href="iconColumnsUrl"></use>
              </svg>
            </div>
          </button>
        </li>
      </ul>
    </header>

    <div :class="[isColumns(columnsView) ? 'container__row--initial container__row--horizontal' : 'container__row--break']"
          class="container__row container__row--spaced container__row--middle" :key="placesListKey">
      <div v-for="item in orderedItems"
          :key="item.attributes.id"
          class="card card--linear card--initial card--relative"
          :class="{ 'card--horizontal card--overlap': isColumns(columnsView), 'card--short card--rearrange': !isColumns(columnsView), 'sortable-full': !hasAvailableSpots(item) && draggingFrom !== null && draggingFrom !== item.attributes.id }"
          :disabled="{ '' : isDisabled }">
        <header class="card__header card__header--dark card__header--absolute">
          <span class="card__title card__title--truncate">
            <strong>{{item.attributes["Place Name"]}}</strong>
            <br>
            <small class="card__subtext" v-html="placesLabelHelpers(item)"></small>
          </span>
          <div @mouseleave="removeIsActiveOnDropdown" :class="{ 'is-invisible': isDisabled }" class="dropdown dropdown--floating dropdown--tiny">
            <div class="dropdown__holder">
              <a href="#" @click.prevent.stop="toggleIsActive" @mouseover="addIsActive" class="dropdown__button no-space-in">
                <svg class="icon icon--tiny icon--slategray dropdown__icon">
                  <use :xlink:href="iconDotsVerticalUrl"></use>
                </svg>
              </a>
              <ul class="dropdown__list dropdown__list--medium dropdown__list--floating dropdown__list--right">
                <li class="dropdown__item">
                  <a href="#" @click.prevent.stop="loadOnSidepanel(item, 'places')" class="dropdown__link dropdown__link--mask">
                    <span class="dropdown__legend">{{t('view_details')}}</span>
                  </a>
                </li>
                <li class="dropdown__item">
                  <a href="#" @click.prevent.stop="selectPeopleIds(item.assignments)" class="dropdown__link">
                    <span class="dropdown__legend">{{t('select_all')}}</span>
                  </a>
                </li>
                <li class="dropdown__item">
                  <a href="#" @click.prevent.stop="unselectPeopleIds(item.assignments)" class="dropdown__link">
                    <span class="dropdown__legend">{{t('unselect_items')}}</span>
                  </a>
                </li>
                <li :class="[anySelectedOn(item.assignments) ? '' : 'is-hidden']" @click.prevent.stop="unassignPeopleWithConfirmation(selectedOn(item.assignments), item)" class="dropdown__item">
                  <a href="#" class="dropdown__link">
                    <span class="dropdown__legend is-danger">{{t('unassign')}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>

        <draggable tag="ul" v-bind="draggableOptions()" @start="onDragStart" @end="onDragEnd" @add="handlePeopleDrop" :class="[isColumns(columnsView) ? 'list--crossed' : 'list--overlap']" class="list list--static list--droppable" :data-id="item.attributes.id">
          <li v-for="person in peopleFor(item)"
              @click="handleClickOnPerson($event, person)"
              v-touch:longtap="handleLongTap(person.attributes.id)"
              :key="person.attributes.id"
              :data-place-id="item.attributes.id"
              :data-id="person.attributes.id"
              :class="[onResultList(person.attributes.id) ? '' : 'is-hidden']"
              class="list__item list__item--regular list__item--merged list__item--tighter no-space-out">
            <div class="list__block list__block--merged">
              <article class="pane pane--signed no-space-out has-full-width"
                      :class="{ 'is-selected': isSelected(person.attributes.id), 'pane--draggable': !isDisabled }">
                <span class="pane__sign" style="background-color: rgb(106, 114, 161);"></span>
                <div class="pane__content pane__content--unbreak">
                  <div class="pane__info has-full-width">
                    <p class="no-space-out pane__text pane__text--truncate">
                      <span class="has-truncate-text">{{person.attributes.full_name}}</span>
                      <small class="has-truncate-text">{{peopleLabelHelpers(person)}}</small>
                    </p>
                  </div>
                </div>
                <div :class="{ 'is-invisible': isDisabled }" class="pane__actions pane__actions--small pane__actions--hidden">
                  <div @mouseleave="removeIsActiveOnDropdown" class="dropdown dropdown--floating dropdown--tiny">
                    <div class="dropdown__holder">
                      <a href="#" @click.prevent.stop="toggleIsActive" @mouseover="addIsActive" class="dropdown__button no-space-in">
                        <svg class="icon icon--tiny icon--slategray dropdown__icon">
                          <use :xlink:href="iconDotsVerticalUrl"></use>
                        </svg>
                      </a>
                      <ul class="dropdown__list dropdown__list--medium dropdown__list--floating dropdown__list--right">
                        <li class="dropdown__item" v-if="!isSelected(person.attributes.id)">
                          <a href="#" @click.prevent.stop="selectPeopleIds([person.attributes.id])" class="dropdown__link dropdown__link--mask">
                            <span class="dropdown__legend">{{t('select')}}</span>
                          </a>
                        </li>
                        <li class="dropdown__item" v-if="isSelected(person.attributes.id)">
                          <a href="#" @click.prevent.stop="unselectPeopleIds([person.attributes.id])" class="dropdown__link dropdown__link--mask">
                            <span class="dropdown__legend">{{t('unselect')}}</span>
                          </a>
                        </li>
                        <li class="dropdown__item">
                          <a href="#" @click.prevent.stop="loadOnSidepanel(person, 'people')" class="dropdown__link dropdown__link--mask">
                            <span class="dropdown__legend">{{t('view_details')}}</span>
                          </a>
                        </li>
                        <li class="dropdown__item">
                          <a href="#" @click.prevent.stop class="dropdown__link dropdown__link--mask">
                            <span class="dropdown__legend">{{t('move')}}</span>
                            <select @change="addPeopleToPlace([person.attributes.id])"
                                    v-model="selectedPlace"
                                    class="select select--invisible no-space-out no-space-in dropdown__select">
                              <option disabled selected value="">Choose one place</option>
                              <option :key="place.attributes.id" :disabled="!hasAvailableSpots(place)" v-for="place in orderedItems" :value="place.attributes.id">
                                {{placeNameWithSpots(place)}}
                              </option>
                            </select>
                          </a>
                        </li>
                        <li class="dropdown__item">
                          <a href="#" @click.prevent.stop="unassignPeopleWithConfirmation([person.attributes.id], item)" class="dropdown__link">
                            <span class="dropdown__legend is-danger">{{t('unassign')}}</span>
                          </a>
                        </li>
                        <li class="dropdown__item is-web-hidden">
                          <a href="#" @click.prevent.stop="removeIsActive" class="dropdown__link">
                            <span class="dropdown__legend is-danger">{{t('close')}}</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </li>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
  import Fuse from 'fuse.js';
  import draggable from 'vuedraggable'
  import orderItems from '../../js/order_items';
  import { spotsCounter, placeNameWithSpots, hasAvailableSpots } from '../../js/spots_counter';
  import { addIsActive, removeIsActiveOnDropdown, removeIsActive, toggleIsActive } from '../../js/dropdown_actions';
  import I18n from 'i18n-js/index.js.erb';
  const t = (key, opts) => I18n.t(key, {
    scope: 'assignments.assignments_component.places_component',
    ...opts
  });

  export default {
    props: [
      'alternativeSpotsName',
      'assignmentsSettingsUrl',
      'draggingFrom',
      'editSortUrl',
      'icon',
      'initialPeopleSearch',
      'isDisabled',
      'people',
      'peopleEditFiltersUrl',
      'peopleHash',
      'peopleSorting',
      'peopleLabels',
      'placesLabels',
      'initialPeopleFilters',
      'initialPlacesFilters',
      'orderedItems',
      'onDragStart',
      'onDragEnd',
      'disableSelection',
      'enableSelection',
      'isSelecting',
      'spotsAreLimited',
      'attendingPlacesCountLabel',
      'assignableSpotsLabel',
      'placesEditFiltersUrl',
      'placesListName',
      'removePersonFromAssigned',
      'requestForPeopleToPlace',
      'selectUnassignedPeople',
      'selectedUnassignedPeopleIds',
      'setPeopleSearchOnAssignments',
      'setSearchUrl',
      'showItemOnSidePanel',
      'unselectUnassignedPeople',
      'unassignPeople'
    ],
    components: {
      draggable
    },

    mounted() {
      const authenticityToken = $('meta[name="csrf-token"]')[0].content;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authenticityToken
        }
      };

      this.persistSearch = (searchValue) => {
        clearTimeout(this.debounceForPersist);
        this.debounceForPersist = setTimeout(() => {
          const params = { assignment_setting: { people_search: searchValue }, list_type: 'people' };
          this.$http.put(this.setSearchUrl, params, config);
        }, 2000);
      };
    },
    data() {
      return {
        placeNameWithSpots,
        hasAvailableSpots,
        spotsCounter,
        addIsActive,
        removeIsActiveOnDropdown,
        removeIsActive,
        toggleIsActive,
        peopleSearch: this.initialPeopleSearch,
        peopleFilters: JSON.parse(this.initialPeopleFilters),
        placesFilters: JSON.parse(this.initialPlacesFilters),
        selectedPeopleIds: [],
        selectedPlace: null,
        columnsView: 'tiles',
        placesListKey: 0,
        debounceForPersist: null,
        debounceForPeopleSearch: null
      }
    },
    methods: {
      t,
      peopleLabelHelpers(item) {
        if (!this.peopleLabels) { return '' };
        return this.peopleLabels.map(key => _.get(item, key)).filter(Boolean).join(' • ');
      },
      placesLabelHelpers(item) {
        const spots = `${spotsCounter(item, this.alternativeSpotsName)}`;
        if (!this.placesLabels) { return spots };
        return [spots].concat(this.placesLabels.map(key => _.get(item, key))).filter(Boolean).join(' • ');
      },
      anySelectedOn(peopleIds) {
        return this.selectedOn(peopleIds).length > 0;
      },
      selectedOn(peopleIds) {
        return this.allSelectedPeopleIds.filter((personId) => (peopleIds || []).includes(personId));
      },
      addPeopleToPlace(peopleIds) {
        const placeId = this.selectedPlace;
        this.selectedPlace = null;
        this.requestForPeopleToPlace(placeId, peopleIds).then(() => {
          this.unselectAllPeople();
        });
      },
      handlePeopleDrop(event) {
        const { item, to, from } = event;
        const peopleIds = Array(item.dataset.id);
        const placeId = Number(to.dataset.id);
        const fromPlaceId = Number(from.dataset.id);
        const place = this.orderedItems.find(i => i.attributes.id === placeId);
        if (place && hasAvailableSpots(place)) {
          this.requestForPeopleToPlace(placeId, peopleIds);
        } else {
          this.$emit("dropcancel", event);
          this._redrawPlaces();
        }
      },
      _redrawPlaces() {
        this.placesListKey += 1;
      },
      peopleFor(place) {
        if (place.assignments === null) { return [] };
        const people = place.assignments.map(personId => {
          return (this.peopleHash[personId] || null)
        }).filter(person => (person !== null));
        return orderItems(people, this.peopleSorting);
      },
      _uniqueArray(array) {
        return array.filter((elem, pos) => (elem !== null) && (array.indexOf(elem) === pos));
      },
      unselectAllPeople() {
        this.selectedPeopleIds = [];
        this.disableSelection();
        this.unselectUnassignedPeople();
      },
      selectAllPeople() {
        this.selectAssignedPeople()
        this.selectUnassignedPeople();
      },
      selectAssignedPeople() {
        this.enableSelection()
        this.selectPeopleIds(this.assignedPeopleIds);
      },
      selectPeopleIds(peopleIds) {
        this.enableSelection()
        this.selectedPeopleIds = this._uniqueArray(
          this.selectedPeopleIds.concat(peopleIds)
        );
      },
      unselectPeopleIds(peopleIds) {
        this.selectedPeopleIds = this.selectedPeopleIds
          .filter((personId) => !peopleIds.includes(personId));
      },
      loadOnSidepanel(item, listType) {
        this.showItemOnSidePanel(item.attributes.id, listType);
      },
      handleClickOnPerson(event, person) {
        if (event.ctrlKey || event.metaKey || this.isSelecting) {
          this._toggleSelectionForPersonId(person.attributes.id);
        } else {
          this.loadOnSidepanel(person, 'people');
        }
      },
      handleLongTap(personId) {
        return () => this._toggleSelectionForPersonId(personId);
      },
      _toggleSelectionForPersonId(personId) {
        if (this.isDisabled) {
          return;
        }
        this.enableSelection();
        const index = this.selectedPeopleIds.indexOf(personId);
        if (index > -1) {
          this.selectedPeopleIds.splice(index, 1);
        } else {
          this.selectedPeopleIds.push(personId);
        }
      },
      isSelected(personId) {
        const index = this.allSelectedPeopleIds.indexOf(personId);
        return index > -1;
      },
      _peopleToBeRemoved(peopleIds) {
        return peopleIds
          .map((personId) => this.peopleHash[personId].attributes.full_name)
          .join(', ');
      },
      unassignPeopleWithConfirmation(peopleIds, place = null) {
        const peopleToBeRemoved = this._peopleToBeRemoved(peopleIds);
        const placeName = place ? place.attributes["Place Name"] : null;
        const messageWithPlaceName = place ? ` from ${placeName}` : ''
        swal({
          title: 'Are you sure?',
          text: `You are removing ${peopleToBeRemoved}${messageWithPlaceName}.`,
          icon: 'warning',
          buttons: ['Cancel', true],
          dangerMode: true
        })
          .then((willDelete) => {
            if (willDelete) {
              this.unassignPeople(peopleIds)
                .then(() => this.unselectPeopleIds(peopleIds));
            }
          });
      },
      draggableOptions() {
        return { group: 'people', sort: false, disabled: this.isDisabled };
      },
      onResultList(personId) {
        if (this.peopleSearch === "" || this.peopleSearch === undefined) {
          return true;
        }
        return this.filteredPeopleIds.includes(personId.toString());
      },
      _countEnabled(accumulator, value) {
        return value.enabled ? (accumulator + 1) : accumulator;
      },
      isTiles(value) {
        return value === 'tiles';
      },
      isColumns(value) {
        return value === 'columns';
      },
      toggleView(value) {
        this.columnsView = value;
      }
    },
    watch: {
      peopleSearch(newValue) {
        clearTimeout(this.debounceForPeopleSearch);
        this.debounceForPeopleSearch = setTimeout(() => {
          this.peopleSearch = newValue;
          this.setPeopleSearchOnAssignments(newValue);
        }, 500);
        this.persistSearch(newValue);
      },
      draggingFrom(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.selectedPeopleIds = [];
        }
      }
    },
    computed: {
      assignmentsCounterLabel() {
        return [
          this.attendingPlacesCountLabel,
          `${this.assignedPeopleIds.length}/${this.assignableSpotsLabel}`
        ].join(' • ');
      },
      countSelectedLabel() {
        if (this.selectedPeopleCount === 1) {
          return '1 Person Selected';
        } else {
          return `${this.selectedPeopleCount} People Selected`;
        }
      },
      enabledPeopleFiltersCounter() {
        return this.peopleFilters.reduce(this._countEnabled, 0);
      },
      enabledPlacesFiltersCounter() {
        return this.placesFilters.reduce(this._countEnabled, 0);
      },
      enabledAllFiltersCounter() {
        return this.placesFilters.reduce(this._countEnabled, 0) + this.peopleFilters.reduce(this._countEnabled, 0);
      },
      assignedPeopleIds() {
        return this.orderedItems.reduce((ids, currentValue) => ids.concat(currentValue.assignments), []).filter(Boolean);
      },
      allSelectedPeopleIds() {
        return this.selectedPeopleIds.concat(this.selectedUnassignedPeopleIds);
      },
      selectedPeopleCount() {
        return this.allSelectedPeopleIds.length;
      },
      fuse() {
        const options = {
          threshold: 0.2,
          keys: ['attributes.full_name', 'attributes.email'],
          id: 'attributes.id'
        };
        return new Fuse(this.people, options);
      },
      filteredPeopleIds() {
        const search = this.peopleSearch || "";
        if (search.length === 0) {
          return this.people.map(person => person.attributes.id);
        }
        return this.fuse.search(this.peopleSearch);
      },
      showFlashMenu() {
        return this.isSelecting && !this.isDisabled;
      },
      iconFiltersUrl() {
        return this.icon('icon-filters');
      },
      iconDotsVerticalUrl() {
        return this.icon('icon-dots-vertical');
      },
      iconSearchUrl() {
        return this.icon('icon-search');
      },
      iconCloseUrl() {
        return this.icon('icon-close');
      },
      iconWarningUrl() {
        return this.icon('icon-warning');
      },
      iconInfoUrl() {
        return this.icon('icon-info');
      },
      iconSettingsUrl() {
        return this.icon('icon-settings');
      },
      iconDashboardUrl() {
        return this.icon('icon-dashboard');
      },
      iconColumnsUrl() {
        return this.icon('icon-columns');
      }
    }
  }
</script>
