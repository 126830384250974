import { Turbo } from '@hotwired/turbo-rails';
import Rails from '@rails/ujs';
import sweetAlert from 'sweetalert';
import SidePanel from './sidepanel';

/*
  SidePanelForm handles the internal sidepanel forms behavior.
  Data attributes description:

  1. `data-sidepanel-form=true`: Shows the sidepanel on form submit and loads the response in
      a sidepanel. Useful when submitting a list of selected items, as in combine lists.
  2. `data-remote=true`: required when using data-sidepanel-form out of a sidepanel.
      In a form inside a sidepanel, the submit response replaces the sidepanel content,
      or redirects to a new location if a header location is provided.
  3. `data-sidepanel-reload=true`: Changes the above behavior for a form submit, loading the
      header location inside the sidepanel.
      Useful when a form submit success loads a new content to the sidepanel.
*/

const SidePanelForm = {
  _dirtyForm: false,
  redirectOnSuccessCallback: null,
  start() {
    $('[data-sidepanel-form]')
      .on('submit', SidePanel.show)
      .on('ajax:success', this.handleSubmitSuccess.bind(this));
    SidePanel.onUpdate(($sidepanel) => {
      const $form = $sidepanel.find('form[data-remote]');
      $form
        .on('ajax:beforeSend', SidePanel.disableSubmit)
        .on('ajax:success', this.handleSubmitSuccess.bind(this))
        .on('ajax:complete', SidePanel.enableSubmit);
      if ($sidepanel.find('.sidepanel__nav').length) {
        this._hookDirty($form);
      }
    });
    SidePanel.onReset(this._reset);
    SidePanel.unload = (location, callback) => {
      if (this._dirtyForm) {
        const form = this._dirtyForm;
        this._askSaveConfirmation().then((confirmed) => {
          if (confirmed) {
            form.dataset.sidepanelReload = true;
            form.dataset.sidepanelReloadUrl = location;
            Rails.fire(form, 'submit');
          } else {
            this._reset();
            callback();
          }
        });
      } else {
        callback();
      }
    };
  },

  _redirectOnSuccess(url) {
    if (this.redirectOnSuccessCallback) {
      this.redirectOnSuccessCallback(url);
      this.redirectOnSuccessCallback = null;
    } else if (!SidePanel.pop({ from: url })) {
      try {
        Turbo.clearCache();
        Turbo.visit(url === 'back' ? window.location : url);
      } catch {
        window.location = url === 'back' ? window.location : url;
      }
    }
  },

  handleSubmitSuccess(e) {
    const [data, , xhr] = e.detail;
    const shouldReloadHeader = Boolean(xhr.getResponseHeader('X-Sidepanel-Reload'));
    const shouldReloadData = e.currentTarget && Boolean(e.currentTarget.dataset.sidepanelReload);
    const shouldReload = shouldReloadHeader || shouldReloadData;
    const reloadUrl = e.currentTarget && e.currentTarget.dataset.sidepanelReloadUrl;
    const url = xhr.getResponseHeader('X-Xhr-Redirect') || xhr.getResponseHeader('Location');
    if (url) {
      this._reset();
      if (shouldReload) {
        SidePanel.load(reloadUrl || url);
      } else {
        this._redirectOnSuccess(url);
      }
    } else {
      const content = $(data).find('body').html() || data;
      SidePanel.updateContent(content);
    }
    return false;
  },

  _hookDirty($form) {
    $form
      .dirrty({
        preventLeaving: false
      })
      .on('dirty', (e) => {
        this._dirtyForm = e.target;
      })
      .on('clean', this._reset);
  },

  _askSaveConfirmation() {
    return sweetAlert({
      title: 'You have unsaved changes',
      text: 'Would you like to save before continuing?',
      icon: 'warning',
      buttons: ['No', 'Yes']
    });
  },

  _reset() {
    SidePanelForm._dirtyForm = false;
  }
};

export default SidePanelForm;
