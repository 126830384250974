const ToggleActive = {
  start() {
    $('[data-toggle-active]').click(this.toggle);
  },
  toggle(e) {
    e.preventDefault();
    const $target = $(e.currentTarget.dataset.toggleActive);
    $target.toggleClass('is-active');
  }
};

export default ToggleActive;
