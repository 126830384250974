export default function displayDatatablesPagination(table) {
  const SHOW_ALL = -1;
  const rowsPerPage = table.fnSettings()._iDisplayLength;
  const rowsToShow = table.fnSettings().fnRecordsDisplay();
  if ((rowsPerPage >= rowsToShow) || (rowsPerPage === SHOW_ALL)) {
    $('.pagination__holder').addClass('is-invisible');
  } else {
    $('.pagination__holder').removeClass('is-invisible');
  }
}
