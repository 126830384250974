import FroalaEditor from 'froala-editor';
import Rails from '@rails/ujs';
import debounce from 'lodash.debounce';
import { generateConfig } from '../../froala_editor/config';
import SidePanel from './sidepanel';
import DarkMode from './dark_mode';
import I18n from '../../i18n-js/index.js.erb';
import FlashMessage from './flash_message';

const { lang } = document.documentElement;

const SELECTOR = '[data-component=richtext-field]';
const VIEW_SELECTOR = '[data-component=richtext-field-view]';

FroalaEditor.DefineIconTemplate(
  'cross',
  `<svg class="icon button__icon" viewBox="0 0 41.756 41.756" xmlns="http://www.w3.org/2000/svg"><path d="M27.948 20.878L40.29 8.536c1.954-1.953 1.954-5.12 0-7.07-1.95-1.953-5.118-1.953-7.07 0L20.88 13.808 8.535 1.464c-1.95-1.952-5.12-1.952-7.07 0-1.953 1.953-1.953 5.12 0 7.07L13.807 20.88 1.465 33.22c-1.953 1.953-1.953 5.12 0 7.07.975.978 2.256 1.465 3.535 1.465 1.278 0 2.56-.487 3.535-1.464l12.343-12.34L33.22 40.29c.977.977 2.257 1.464 3.536 1.464s2.56-.487 3.535-1.464c1.955-1.953 1.955-5.12 0-7.07L27.95 20.877z"></path>
</svg>`
);
FroalaEditor.DefineIconTemplate(
  'checkmark',
  `<svg class="icon button__icon" viewBox="0 0 45.701 45.7" xmlns="http://www.w3.org/2000/svg"><path d="M20.687 38.332c-2.072 2.072-5.434 2.072-7.505 0L1.554 26.704c-2.072-2.07-2.072-5.433 0-7.504 2.07-2.072 5.433-2.072 7.505 0l6.927 6.927c.523.522 1.372.522 1.896 0l18.76-18.76c2.07-2.07 5.432-2.07 7.504 0 .995.996 1.554 2.346 1.554 3.753s-.558 2.757-1.553 3.752l-23.46 23.46z"></path></svg>`
);
FroalaEditor.DefineIcon('cancel', { NAME: 'cross', template: 'cross' });
FroalaEditor.DefineIcon('save', { NAME: 'checkmark', template: 'checkmark' });
FroalaEditor.RegisterCommand('cancel', {
  title: I18n.t('shared.buttons.cancel', { locale: lang }),
  focus: false,
  undo: false,
  refreshAfterCallback: false,
  callback() {
    const $input = this.$oel;
    const $form = $input.parents('form').first();
    $form.find('.toggle-editing').first().trigger('click');
    $(document.body).trigger('focus');
  }
});

const saveMessageBody = (editor, toggleEditOnSave) => {
  if (editor.codeView.isActive()) {
    return;
  }
  const $input = editor.$oel;
  const validAttributeNames = ['event_mailing[message_body]', 'event_description[description]'];
  const inputName = $input.attr('name');

  // Check if the input's name attribute matches the desired string
  if (validAttributeNames.indexOf($input.attr('name')) === -1) {
    return;
  }

  const value = editor.html.get();
  const url = $input.parents('form').first().attr('action');

  const formData = new FormData();
  formData.append(inputName, value);
  $($input.parents('form')).serializeArray().forEach((item) => {
    // Include the email message type to save the message in the correct place
    // and also keep message type selected after page reload.
    if (item.name === 'event_mailing[message_type]') {
      formData.append(item.name, item.value);
    }
  });

  fetch(url, {
    method: 'PATCH',
    headers: {
      'X-CSRF-Token': Rails.csrfToken()

    },
    body: formData
  })
    .then(() => {
      if (toggleEditOnSave) {
        const $form = $input.parents('form').first();
        $form.find('.toggle-editing').first().trigger('click');
      }
    })
    .catch((error) => {
      FlashMessage.showMessageWithTimer('danger', 'Something went wrong and your changes were not saved');
      Rollbar.error(`Error while saving message on ${url}`, error);
    });
};

FroalaEditor.RegisterCommand('save', {
  title: I18n.t('shared.buttons.save', { locale: lang }),
  focus: false,
  undo: false,
  refreshAfterCallback: false,
  callback() {
    saveMessageBody(this, true);
  }
});

const saveGroup = ['save'];
const debouncedSave = debounce(saveMessageBody, 2000);

const transformationUrlParameters = (width, height) => {
  const queryStringArray = [];
  const widthInt = parseInt(width, 10);
  const heightInt = parseInt(height, 10);
  if (widthInt) {
    queryStringArray.push(`w-${widthInt}`);
  }
  if (heightInt) {
    queryStringArray.push(`h-${heightInt}`);
  }
  if (queryStringArray.length) {
    return `tr:${queryStringArray.join(',')}`;
  }
  return null;
};

export const imageResizeEnd = ($img) => {
  const {
    src,
    style: { width, height }
  } = $img[0];
  const srcPieces = src.replace(/%2F/gi, '/').split('/');
  const transformation = transformationUrlParameters(width, height);
  const transformationPiece = srcPieces[srcPieces.length - 2];
  if (transformation && transformationPiece.startsWith('tr:')) {
    srcPieces.splice(srcPieces.length - 2, 1, transformation);
  }
  const fullUrl = srcPieces.join('/');
  $img.attr('src', fullUrl);
};

export const updateUploadedFileUrlToCloudfront = (cloudfrontDomain, s3Bucket) => ($file) => {
  const s3Url = `s3.amazonaws.com/${s3Bucket}`;
  if ((cloudfrontDomain || '') !== '') {
    const originalImageUrl = $file.attr('href');
    const updatedImageURL = originalImageUrl.replace(/%2F/gi, '/').replace(s3Url, cloudfrontDomain);
    $file.attr('href', updatedImageURL);
  }
};

export const updateUploadedImageUrlToCloudfront = (cloudfrontDomain, s3Bucket) => ($file) => {
  const s3Url = `s3.amazonaws.com/${s3Bucket}`;
  if ((cloudfrontDomain || '') !== '' && $file.attr('src').startsWith(`https://${s3Url}`)) {
    const originalImageUrl = $file.attr('src');
    const updatedImageURL = originalImageUrl.replace(/%2F/gi, '/').replace(s3Url, cloudfrontDomain);
    const {
      style: { width, height }
    } = $file[0];
    const transformation = transformationUrlParameters(width, height) || 'tr:w-540';
    const transformedImageArray = updatedImageURL.split('/');
    transformedImageArray.splice(transformedImageArray.length - 1, 0, transformation);
    $file.attr('src', transformedImageArray.join('/'));
  }
};

export const updateUploadedVideoUrlToCloudfront = (cloudfrontDomain, s3Bucket) => ($file) => {
  const s3Url = `s3.amazonaws.com/${s3Bucket}`;
  if ((cloudfrontDomain || '') !== '') {
    const originalImageUrl = $file.find('video').attr('src');
    const updatedImageURL = originalImageUrl.replace(/%2F/gi, '/').replace(s3Url, cloudfrontDomain);
    $file.find('video').attr('src', updatedImageURL);
  }
};

const RichTextField = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find(SELECTOR).each(this._initFroala.bind(this));
    });

    $(SELECTOR).each(this._initFroala.bind(this));
    $(VIEW_SELECTOR).each(this._initViewFroala.bind(this));
  },

  _initFroala(_, input) {
    // Event mailing
    const config = {
      ...generateConfig(input),
      imageEditButtons: [
        'imageReplace',
        'imageAlign',
        'imageCaption',
        'imageRemove',
        '|',
        'imageLink',
        'linkOpen',
        'linkEdit',
        'linkRemove',
        '-',
        'imageDisplay',
        'imageStyle',
        'imageAlt'
      ],
      toolbarButtons: {
        ...generateConfig(input).toolbarButtons,
        saveButtons: {
          buttons: saveGroup,
          align: 'right',
          buttonsVisible: 2
        }
      },
      enter: FroalaEditor.ENTER_BR
    };
    const froalaEditor = new FroalaEditor(input, config, function () {
      $(document).on('syncFroalaIframe', () => setTimeout(froalaEditor.size.syncIframe, 50));
      const { cloudfrontDomain, s3Bucket } = $(froalaEditor.$oel).data();
      const { events } = froalaEditor;
      events.on('file.inserted', updateUploadedFileUrlToCloudfront(cloudfrontDomain, s3Bucket));
      events.on('image.resizeEnd', imageResizeEnd);
      events.on('image.replaced', updateUploadedImageUrlToCloudfront(cloudfrontDomain, s3Bucket));
      events.on('image.inserted', updateUploadedImageUrlToCloudfront(cloudfrontDomain, s3Bucket));
      events.on('video.inserted', updateUploadedVideoUrlToCloudfront(cloudfrontDomain, s3Bucket));
      events.on('contentChanged', () => debouncedSave(this));
      DarkMode.updateIframeDarkmode();
    });
  },

  _initViewFroala(_, input) {
    // Event Description editor
    const $input = $(input);
    const $form = $input.parents('form').first();
    let isEditing = false;
    const baseConfig = generateConfig(input);
    const config = {
      ...baseConfig,
      toolbarButtons: {
        ...baseConfig.toolbarButtons,
        saveButtons: {
          buttons: saveGroup,
          align: 'right',
          buttonsVisible: 1
        }
      }
    };
    const $editButton = $form.find('.toggle-editing').first();
    const froalaEditor = new FroalaEditor(input, config, function () {
      $(document).on('syncFroalaIframe', () => setTimeout(froalaEditor.size.syncIframe, 50));
      froalaEditor.toolbar.hide();
      const { cloudfrontDomain, s3Bucket } = $(froalaEditor.$oel).data();
      const { events } = froalaEditor;
      events.on('click', function () {
        if (!isEditing) {
          $editButton.click();
        }
      });
      events.on('file.inserted', updateUploadedFileUrlToCloudfront(cloudfrontDomain, s3Bucket));
      events.on('image.resizeEnd', imageResizeEnd);
      events.on('image.replaced', updateUploadedImageUrlToCloudfront(cloudfrontDomain, s3Bucket));
      events.on('image.inserted', updateUploadedImageUrlToCloudfront(cloudfrontDomain, s3Bucket));
      events.on('video.inserted', updateUploadedVideoUrlToCloudfront(cloudfrontDomain, s3Bucket));
      events.on('contentChanged', () => debouncedSave(this));
      DarkMode.updateIframeDarkmode();
    });
    const afterToggle = () => {
      if (froalaEditor.codeView.isActive()) {
        froalaEditor.codeView.toggle();
      }
      froalaEditor.size.syncIframe();
    };

    $form.on('toggle:editing', (_e, value) => {
      isEditing = value;
      if (isEditing) {
        $editButton.addClass('is-hidden');
        froalaEditor.toolbar.show();
      } else {
        $editButton.removeClass('is-hidden');
        froalaEditor.toolbar.hide();
      }
      afterToggle();
    });
  }
};

export default RichTextField;
