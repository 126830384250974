import { Turbo } from '@hotwired/turbo-rails';
import SidePanel from './sidepanel';

const CsvImportPolling = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      const $loader = $sidepanel.find('.js-import-loader');
      if ($loader.length) {
        this.initializePolling($loader.data('url'));
      }
    });
  },

  initializePolling(url) {
    const self = this;
    const REFRESH_INTERVAL_IN_MILLIS = 2000;
    $.ajax({
      url,
      dataType: 'json',
      statusCode: {
        200: (data) => {
          Turbo.visit(data.render_url);
        },
        206: () => {
          setTimeout(function() {
            self.initializePolling(url);
          }, REFRESH_INTERVAL_IN_MILLIS);
        },
        308: (jqXHR) => {
          const data = jqXHR.responseJSON;
          if (data && data.render_url) {
            SidePanel.load(data.render_url);
          }
        }
      }
    });
  }
};

export default CsvImportPolling;
