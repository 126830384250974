'esversion: 6';

import 'datatables.net';

// Plugins
import 'datatables.net-buttons';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-select';
import 'datatables.net-rowgroup';
import 'datatables.net-keytable';

import './customization-v2';
