import URL from 'url';
import _ from '../vendor/lodash.custom';
import ToggleVisible from './toggle_visible';
import displayDatatablesPagination from '../shared/datatables_pagination';
import { withSaveState } from '../shared/datatables_state';
import {
  overridePaginationClasses,
  customizeDOMPaginationClasses
} from '../../datatables/customization-v2';
import {
  setup as tableSetup,
  setupPaginationOnDOM,
  customizePageLength,
  evaluateSelectedRows,
  defaultTableOptions,
  updateCheckAllCheckbox
} from '../../datatables/table-setup';
import SidePanel from './sidepanel';
import RecipientListEdit from './recipient_list_edit';

const RECIPIENTS_TABLE_SELECTOR = '.recipients-table:not(.dataTable)';
const COMPOSE_BUTTON_SELECTOR = '.compose-button';
const CHECK_ALL_SELECTOR = '#checkall';

const RecipientsTable = {
  _recipientsByRow: null,

  start() {
    const $table = $(RECIPIENTS_TABLE_SELECTOR);
    if (!$table.length) {
      return;
    }
    this.$composeButton = $(COMPOSE_BUTTON_SELECTOR);
    this._recipientsByRow = {};
    this.setupDataTable($table);
  },

  getRecipientsByRow(rowIndex) {
    return this._recipientsByRow[rowIndex] || [];
  },

  checkAllCheckbox() {
    return $(CHECK_ALL_SELECTOR);
  },

  setupDataTable($table) {
    overridePaginationClasses();
    const $checkAllCheckbox = this.checkAllCheckbox();
    let table;
    const tableOptions = {
      ...defaultTableOptions,
      order: [[1, 'asc']],
      dom: 'rtp',
      select: {
        style: 'multi',
        selector: 'label.js-checkbox-for-row',
        className: 'is-selected'
      },
      columnDefs: [
        { targets: [0], orderable: false, width: '7%' },
        { targets: [2], searchable: true, width: '20%' },
        { targets: [-1], orderable: false, width: '9%' },
        { targets: '_all', searchable: false }
      ],
      drawCallback() {
        customizePageLength(this);
        customizeDOMPaginationClasses();
        evaluateSelectedRows(table, $checkAllCheckbox, false);
        displayDatatablesPagination(this);
      },
      initComplete: () => {
        setupPaginationOnDOM();
        this._loadSavedRecipients(table, $table.data('source-url'));
      },
      language: {
        ...defaultTableOptions.language,
        zeroRecords: '<span class="alert alert--info">Sorry, no matching Lists were found</span>',
        emptyTable: '<span class="alert alert--info">No Lists available</span>'
      }
    };
    const tableOptionsWithSaveState = withSaveState($table, tableOptions);
    table = $table.DataTable(tableOptionsWithSaveState);
    tableSetup(table, { selectHooks: false });
    table.on('deselect', this._handleRowDeselect.bind(this));
    table.on('select', this._handleRowSelect.bind(this));
    this.updateRecipients = this._createUpdateRecipientsFunc(table, $checkAllCheckbox);
    this.selectRowByIndex = this._createSelectRowFunc(table);
  },

  _recipients() {
    return _.flatten(Object.values(this._recipientsByRow));
  },

  _createUpdateRecipientsFunc(table, $checkAllCheckbox) {
    return (values, rowIndex) => {
      const _values = Array.isArray(values) ? values : Array(values);
      if (rowIndex) {
        this._recipientsByRow[rowIndex] = _values;
      } else {
        this._recipientsByRow = this._getSelectedRecipients(table, _values);
      }
      this._redraw(table, $checkAllCheckbox);
    };
  },

  _createSelectRowFunc(table) {
    return (rowIndex) => {
      const row = table.row(rowIndex);
      if (row) {
        row.select();
      }
    };
  },

  _selectedCheckboxes(table) {
    const selectedRows = table.rows({ selected: true }).data();
    return selectedRows.toArray().map(row => $(row[0]));
  },

  _renderHiddenRowIds(table, $checkAllCheckbox) {
    const $wrapper = $checkAllCheckbox
      .closest('.js-action-bar-flash-wrapper')
      .find('.js-selected-rows-ids');
    const idsFieldName = $wrapper.data('elementName');
    const selectedIds = this._selectedCheckboxes(table).map($input => $input.data('id'));
    const hiddenInputs = selectedIds.map(id => `<input type='hidden' name='${idsFieldName}[]' value='${id}'>`);
    _.uniq(this._recipients()).forEach(id =>
      hiddenInputs.push(`<input type='hidden' name='event_mailing[list_item_ids][]' value='${id}'>`));
    $wrapper.html(hiddenInputs.join(''));
  },

  _showTableFlash(table, $checkAllCheckbox) {
    const $flash = $checkAllCheckbox.closest('.js-action-bar-flash-wrapper').find('.flash');
    const selectedCount = _.uniq(this._recipients()).length;
    if (selectedCount > 0) {
      const pluralizedCounter = selectedCount === 1 ? '1 Recipient' : `${selectedCount} Recipients`;
      $flash.find('.js-items-selected-counter').text(`${pluralizedCounter} Selected`);
      ToggleVisible.show($flash);
    } else {
      $flash.find('.js-items-selected-counter').text('');
      ToggleVisible.hide($flash);
    }
  },

  _handleRowDeselect(e, table, type, indexes) {
    if (type === 'row') {
      indexes.forEach((i) => {
        this._recipientsByRow[i] = [];
      });
      this._redraw(table, this.checkAllCheckbox());
    }
  },

  _handleRowSelect(e, table, type, indexes) {
    if (type === 'row') {
      indexes.forEach((i) => {
        const row = table.row(i);
        this._recipientsByRow[i] = this._getRowRecipients(row);
      });
      this._redraw(table, this.checkAllCheckbox());
    }
  },

  _getRowRecipients(row) {
    return (
      $(row.data()[0])
        .data('list-items')
        .map(String) || []
    );
  },

  _getSelectedRecipients(table, filterItems) {
    const byRowIndex = {};
    table.rows({ selected: true }).every((index) => {
      const row = table.row(index);
      byRowIndex[index] = this._getRowRecipients(row).filter(i => filterItems.includes(i));
      return index;
    });
    return byRowIndex;
  },

  _redraw(table, $checkAllCheckbox) {
    updateCheckAllCheckbox(table, $checkAllCheckbox);
    this._renderHiddenRowIds(table, $checkAllCheckbox);
    this._showTableFlash(table, $checkAllCheckbox);
    this.$composeButton.attr('disabled', this._recipients().length <= 0);
    SidePanel.reset();
  },

  _loadCheckState(table, selectedIds) {
    table.rows().every((index) => {
      const row = table.row(index);
      const id = $(row.data()[0]).data('id');
      if (selectedIds.includes(String(id))) {
        row.select();
      }
      return index;
    });
  },

  _openEditRecipients() {
    const url = URL.parse(document.location.href);
    if (url.query && url.query.match(/edit_recipients=true/)) {
      RecipientListEdit.open();
    }
  },

  _loadSavedRecipients(table, sourceUrl) {
    $.ajax({
      url: sourceUrl,
      dataType: 'json'
    }).done((data) => {
      this._loadCheckState(table, data.segmented_list_ids);
      this.updateRecipients(data.list_item_ids);
      this._openEditRecipients();
    });
  }
};

export default RecipientsTable;
