import Rails from '@rails/ujs';
import SidePanel from './sidepanel';

const dataAttribute = '[data-submit-form]';

const SubmitForm = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.on('click', dataAttribute, this._submitForm);
    });
    $(document).on('click', dataAttribute, this._submitForm);
  },

  _submitForm(e) {
    e.preventDefault();
    const $form = $(e.currentTarget).closest('form');
    if ($form.data('remote')) {
      Rails.fire($form.get(0), 'submit');
    } else {
      $form.submit();
    }
  }
};

export default SubmitForm;
