import Rails from '@rails/ujs';
import sweetAlert from 'sweetalert';
import SidePanel from './sidepanel';

const ConfirmAttendanceChanges = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel
        .find('.js-confirm-attendance-changes')
        .click(this.handleSubmit);
    });
  },

  handleSubmit(e) {
    e.stopImmediatePropagation();
    const messages = [];
    const $content = $(e.currentTarget).closest('.sidepanel--replaceable');
    const $form = $content.find('form');
    const waitlistedCount = $form
      .find('.js-ask-if-attending-toggle')
      .data('waitlistedCount');
    const hasWaitlist = $form.find('.js-ask-if-attending-toggle').data('hasWaitlist');
    const isLinkAttendance = $form.find('.js-ask-if-attending-toggle input[type="radio"]:checked').val() === 'false';
    const hasPeopleOnWaitlist = hasWaitlist && waitlistedCount > 0;
    if (hasPeopleOnWaitlist && isLinkAttendance) {
      // Case 3
      messages.push(`Pressing Confirm will remove the Waitlist and ${waitlistedCount} people will be set to Not Attending.`);
    }

    if (messages.length > 0) {
      sweetAlert({
        title: 'Are you sure?',
        content: {
          element: 'span',
          attributes: {
            innerHTML: messages.join('<br>'),
          }
        },
        icon: 'warning',
        buttons: ['Cancel', 'Confirm'],
        dangerMode: false
      })
        .then((willSubmit) => {
          if (willSubmit) {
            Rails.fire($form.get(0), 'submit');
          }
        });
    } else {
      Rails.fire($form.get(0), 'submit');
    }
  }
};

export default ConfirmAttendanceChanges;
