<template>
   <component :is="vueComponent" v-bind="transformAttrs"></component>
</template>

<script>
  export default {
    props: ['vue-component'],

    computed: {
      transformAttrs() {
        let transformed = {};
        for (let key in this.$attrs) {
          let value = this.$attrs[key];
          transformed[key] = JSON.parse(value);
        }
        return transformed;
      }
    }
  }
</script>
