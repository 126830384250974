const ListImportReview = {
  start() {
    const $listImportSampleTable = $('.js-table-with-import-sample');
    if ($listImportSampleTable.length) {
      this.startSampleTable($listImportSampleTable);
      $('.js-preview-table select').change(this.watchFieldSelectChange);
      $('.js-preview-table input[type=radio]').change(this.watchFieldRadioChange);
      $('.js-submit-imported-list').click(this.watchAtLeastOneRequiredFieldSelected);
    }
  },

  startSampleTable($table) {
    $table.DataTable({
      dom: 't'
    });
  },

  watchFieldSelectChange(event) {
    const target = $(event.currentTarget);
    const value = target.closest('tr').find('input[type=radio]').val();
    // Clear the old input
    $(`input[type=hidden][value='${value}']`).val('');
    $(`#list_${target.val()}`).val(value);
    $('.js-preview-table select').not($(event.currentTarget)).filter(function() {
      return this.value === target.val();
    }).val('');
  },

  watchFieldRadioChange(event) {
    const value = $(event.currentTarget).closest('td').find('input[type=hidden]').val();
    const data = $('.js-table-with-import-sample').DataTable().column(`:contains(${value})`).data();
    $('.data-preview-content').html('');

    data.each(function(dataValue) {
      $('.data-preview-content').append(`<p>${dataValue}</p>`);
    });
  },

  watchAtLeastOneRequiredFieldSelected(event) {
    const allSelectCount = $('.js-preview-table select').length;
    const emptySelectCount = $('.js-preview-table select').filter(function() {
      return this.value === '';
    }).length;
    if (emptySelectCount === allSelectCount) {
      // All selects are empty
      swal('Select a required field', 'You must select at least one option in the default data fields', 'error');
      event.preventDefault();
    }
  }
};

export default ListImportReview;
