<template>
  <div class="input" :class="{ field_with_errors: errorsFor('event_part_ids') }">
    <multiselect
      :value="selectedActivities"
      @input="changedSelectedActivities"
      :multiple="true"
      :clearOnSelect="false"
      label="name"
      track-by="id"
      group-values="options"
      group-label="label"
      :group-select="true"
      :close-on-select="false"
      :options="eventOrActivities"
      :placeholder="t('choose_the_options')"
      :limit="5"
      :limit-text="limitText"
    >
    </multiselect>
    <input value="" :name="fieldName" type="hidden" />
    <input
      v-for="item in selectedActivities"
      :key="item.id"
      :value="item.id"
      :name="fieldName"
      type="hidden"
    />
    <br>
    <div :key="error" v-for="error in errorsFor('event_part_ids')" class="error">{{ error }}</div>
  </div>
</template>


<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) =>
  I18n.t(key, {
    scope: 'my_events.payments.form_copy',
    ...opts,
  });

export default {
  props: ['initialProductErrors', 'initialActivities', 'initialSelectedActivitiesIds', 'fieldName'],
  components: {
    Multiselect,
  },
  data() {
    const productErrors =
      (this.initialProductErrors && JSON.parse(this.initialProductErrors)) || {};
    const activitiesOptions = JSON.parse(this.initialActivities).map((activity) => ({
      id: activity[1],
      name: activity[0],
    }));
    const selectedActivitiesIds =
      this.initialSelectedActivitiesIds && JSON.parse(this.initialSelectedActivitiesIds);
    const selectedActivities = activitiesOptions.filter((option) =>
      selectedActivitiesIds.includes(option.id)
    );
    return {
      selectedActivities,
      productErrors: productErrors,
    };
  },

  methods: {
    t,

    limitText(count) {
      return t('and_n_more', { count });
    },

    errorsFor(fieldName) {
      return this.productErrors[fieldName];
    },

    changedSelectedActivities(values) {
      const eventPartIds = values.map((value) => value.id);
      this.selectedActivities = this.activitiesOptions.filter((option) =>
        eventPartIds.includes(option.id)
      );
    },
  },

  computed: {
    activities() {
      return JSON.parse(this.initialActivities);
    },

    activitiesOptions() {
      return this.activities.map((activity) => ({ id: activity[1], name: activity[0] }));
    },

    eventOrActivities() {
      return [{ label: I18n.t('shared.buttons.select_all'), options: this.activitiesOptions }];
    },
  },
};
</script>
