import SidePanel from './sidepanel';

const BulkAssignmentSidepanel = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('#new_bulk_assignments')
        .on('ajax:success', this.handleSuccessBulkAssignment)
        .on('ajax:error', this.handleErrorBulkAssignment);
      $sidepanel.find('#bulk_assignments_place_id')
        .on('change', this.handleChangePlace);
    });
  },

  handleChangePlace(event) {
    const placeId = $(event.currentTarget).find('option:selected').val();
    const $assignmentsList = $(event.currentTarget).closest('.sidepanel__block')
      .find('.js-assignments');
    const $assignmentsListItems = $assignmentsList.find('li');
    $assignmentsListItems.addClass('is-hidden');
    if (placeId !== '' && ($assignmentsListItems.filter(`[data-place-id=${placeId}]`).length > 0)) {
      $assignmentsList.removeClass('is-hidden');
      $assignmentsListItems.filter(`[data-place-id=${placeId}]`).removeClass('is-hidden');
    } else {
      $assignmentsList.addClass('is-hidden');
    }
  },

  handleErrorBulkAssignment(error) {
    const { originalEvent: { detail } } = error;
    const errorMessage = detail[0].errors[0].message;
    $('.alert--danger').removeClass('is-hidden').text(errorMessage);
  },

  handleSuccessBulkAssignment() {
    const $table = $('.table');
    $table.trigger('datatable:deselect');
    $table.trigger('datatable:reload');
    SidePanel.reset();
  }
};

export default BulkAssignmentSidepanel;
