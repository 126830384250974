const ToggleRadioButton = {
  start() {
    $(document).on('change', '[data-radio-toggle-button]', this.toggleRadio);
    $('[data-toggle-shown-trigger]').each((_, element) => {
      if ($(element).data('toggle-shown-trigger') === true) {
        $(element).change();
      }
    });
  },

  toggleRadio(e) {
    const selector = `[name="${e.currentTarget.name}"]`;
    const selectedIndex = $(selector).index(e.currentTarget);
    const $targets = $(e.currentTarget.dataset.radioToggleButton);
    $targets.each((index, target) => {
      const $target = $(target);
      if (index === selectedIndex) {
        $target
          .removeClass('is-hidden')
          .addClass('is-shown');
      } else {
        $target
          .addClass('is-hidden')
          .removeClass('is-shown');
      }
      $(e.currentTarget).trigger('toggle:changed');
    });
  }
};

export default ToggleRadioButton;
