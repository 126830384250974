<template>
  <li class="list__item">
    <div class="list__block list__block--tighter">
      <select v-model="selectedField" class="select has-three-width no-space-out">
        <option :value="null" disabled="disabled">Please select</option>
        <option v-for="column in columns" v-bind:value="column.id" :key="column.id">
          {{ column.name }}
        </option>
      </select>
      <select v-model="selectedCondition" class="select has-three-width no-space-out">
        <option :key="option" v-for="option in conditionOptions">{{option}}</option>
      </select>
      <input
        type="text"
        ref="selectedValue"
        v-bind:style="[isInputColumnType ? {'display': 'block'} : {'display': 'none'}]"
        v-model.lazy="selectedValue"
        class="input has-three-width no-space-out"
        placeholder="Value"
        :disabled="this.isBlankNotBlank"
      />
      <select v-if="!isInputColumnType" v-model="selectedValue" class="select has-three-width no-space-out">
        <option :value="option.value" :key="option.value" v-for="option in selectOptions">{{option.text}}</option>
      </select>
      <button v-bind:class="showOnEdit" @click.prevent="removeRule" class="button button--circle button--transparent list__button" type="button">
        <svg class="icon icon--red button__icon">
          <use :xlink:href="iconTrash"></use>
        </svg>
      </button>
    </div>
  </li>
</template>

<script>
import I18n from 'i18n-js/index.js.erb';
import 'devbridge-autocomplete';
const t = (key, opts) => I18n.t(key, {
  scope: 'assignments.assignments_component.people_component',
  ...opts
});
export default {
  props: [
    'initial-filter',
    'columns',
    'filter-position',
    'remove-rule-from-parent',
    'update-rule-from-parent',
    'isEditing',
    'icon-trash'
  ],

  data() {
    return {
      selectedField: this.initialFilter.field_id || null,
      selectedCondition: this.initialFilter.condition || this.optionsForColumnType(this.selectedField)[0],
      selectedValue: this.initialFilter.value || '',
      row_id: this.initialFilter.row_id || null,
    }
  },

  watch: {
    filter() {
      this.updateRule();
    },

    selectedField() {
      if (!this.conditionOptions.includes(this.selectedCondition)) {
        this.selectedCondition = this.conditionOptions[0];
      }

      this.bindAutocomplete();
    }
  },

  computed: {
    showOnEdit() {
      return { 'is-shown': this.isEditing, 'is-hidden': !this.isEditing };
    },

    filter() {
      return {
        condition: this.selectedCondition,
        field_id: this.selectedField,
        source: this._selectedFieldSource,
        value: this.selectedValue,
        row_id: this.row_id
      }
    },

    alternatives() {
      const column = this.columns.find(column => column.id === this.selectedField);
      return (column && column.alternatives || []).map(a => ({ value: a, data: a }));
    },

    conditionOptions() {
      return this.optionsForColumnType(this.selectedColumnType);
    },

    selectOptions() {
      switch (this.selectedColumnType) {
        case 'attendance':
          return this._attendanceOptions;
        case 'yes_or_no':
        case 'boolean':
          return this._yesOrNoOptions;
        case 'true_or_false':
          return this._booleanOptions;
      }
    },

    _selectedFieldSource() {
      const column = this.columns.find(c => c.id === this.selectedField);
      return column && column.source;
    },

    _attendanceOptions() {
      return [
        { text: t('attending'), value: 'attending' },
        { text: t('waitlisted'), value: 'waitlisted' },
        { text: t('not_attending'), value: 'not_attending' },
        { text: t('undecided'), value: 'undecided' },
      ]
    },

    _booleanOptions() {
      return [
        { text: '', value: '' },
        { text: 'True', value: 'true' },
        { text: 'False', value: 'false' }
      ];
    },

    _yesOrNoOptions() {
      return [
        { text: '', value: '' },
        { text: 'Yes', value: 'true' },
        { text: 'No', value: 'false' }
      ];
    },

    selectedColumnType() {
      const column = this.columns.find(column => column.id === this.selectedField);
      return (column && column.type) || "";
    },

    isInputColumnType() {
      return ![
        'true_or_false',
        'yes_or_no',
        'attendance',
        'boolean'
      ].includes(this.selectedColumnType) || this.isBlankNotBlank;
    },

    isBlankNotBlank() {
      return ['Is blank', 'Is not blank'].includes(this.selectedCondition);
    }
  },

  methods: {
    t,

    updateRule() {
      this.updateRuleFromParent(this.filterPosition, this.filter);
    },

    removeRule() {
      this.removeRuleFromParent(this.filterPosition);
    },

    optionsForColumnType(columnType) {
      switch (columnType) {
        case 'date_and_time':
        case 'number':
          return [
            'Equals',
            'Does not equal',
            'Greater than',
            'Greater than or equal to',
            'Less than',
            'Less than or equal to',
            'Is blank',
            'Is not blank',
          ];
        case 'attendance':
          return ['Equals', 'Does not equal'];
        case 'true_or_false':
        case 'yes_or_no':
        case 'boolean':
          return ['Equals', 'Does not equal', 'Is blank', 'Is not blank'];
        default:
          return [
            'Contains',
            'Does not contain',
            'Equals',
            'Does not equal',
            'Begins with',
            'Ends with',
            'Is blank',
            'Is not blank'
          ];
      }
    },

    bindAutocomplete() {
      const self = this;
      const $valueField = $(this.$refs.selectedValue);
      const options = {
        lookup: this.alternatives,
        minChars: 0,
        onSelect: option => {
          self.selectedValue = option.value;
        }
      };

      if ($valueField.autocomplete()) {
        $valueField.autocomplete('setOptions', options);
      } else {
        $valueField.autocomplete(options);
      }
    }
  },

  mounted() {
    this.bindAutocomplete();
  }
}
</script>
