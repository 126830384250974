import 'jquery-text-counter/textcounter';
import SidePanel from './sidepanel';

const InputMaxLength = {
  start() {
    $('.input-max-length').each(this.initTextCounter);
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('.input-max-length').each(this.initTextCounter);
    });
  },

  initTextCounter(_, input) {
    const $input = $(input);
    const max = $input.attr('maxlength');
    const counterText = `%d/${max}`;
    $input.textcounter({
      max: 'auto',
      countSpaces: true,
      counterText
    });
  }
};

export default InputMaxLength;
