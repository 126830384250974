<template>
  <div>
    <form :action="updateUrl" data-remote="true" method="post" accept-charset="UTF-8">
      <div class="sidepanel__block" v-if="columnSets">
        <h6 class="subtitle subtitle--center">{{t('subtitle')}}</h6>
        <hr>
        <fieldset>
          <label for="columnSet">{{t('column_set_select')}}</label>
          <select :name="columnSetIdField" id="columnSet" class="select select--full" v-model="columnSetId">
            <option value="">{{t('column_set_default')}}</option>
            <option :key="columnSet.id" :value="columnSet.id" v-for="(columnSet) in columnSets">
              {{columnSet.name}}
            </option>
            <option value="new">{{t('column_set_new')}}</option>
          </select>
        </fieldset>
        <fieldset v-if="columnSetId">
          <label for="SetName">{{t('column_set_name')}}</label>
          <input id="SetName" :name="columnSetNameField" type="text" v-model="columnSetName" class="input input--full input-max-length" required="required" maxlength="30">
        </fieldset>
      </div>
      <input name="deleted_set_ids[]" :value="deletedId" :key="deletedId" v-for="deletedId in deletedColumnSets" type="hidden">
      <input :name="fieldName" :value="selectedId" :key="selectedId" v-for="selectedId in selectedFieldsIds" type="hidden">
    </form>
    <div class="sidepanel__block">
      <h6 class="subtitle subtitle--center">{{fieldsTitle}}</h6>
      <hr>
      <span class="alert alert--info">
        {{t('fields_count', { count: value.length })}}
      </span>
      <ul class="list list--bordered">
        <li>
          <multiselect
            class="multiselect--search"
            :multiple="true"
            group-values="fields"
            group-label="group"
            v-model="value"
            :options="options"
            :searchable="true"
            :close-on-select="false"
            :show-labels="false"
            :block-keys="['Delete']"
            @input="refreshSelectedFields"
            trackBy="id"
            label="field_name"
            :placeholder="t('fields_add')">
            <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span class="multiselect__label" v-if="!isOpen && values.length">{{t('fields_add')}}</span>
              <span v-if="isOpen"></span>
            </template>
          </multiselect>
        </li>
        <li class="list__item list__item--tighter" v-for="(field, index) in value" :key="field.id" >
          <div class="list__block list__block--tighter">
            <div class="list__content">
              <div class="switch switch--start no-space-out">
                <span class="list__text switch__text switch__text--treeview">{{field.field_name}}</span>
                <svg class="icon icon--tiny switch__icon" @click="removeFromSelected(field.id)">
                  <use :xlink:href="iconCheck"></use>
                </svg>
              </div>
            </div>
            <div class="dropdown dropdown--floating dropdown--table">
              <div class="dropdown__holder">
                <a class="button button--circle button--transparent no-space-out noclick" href="#">
                  <svg class="icon icon--tiny">
                    <use :xlink:href="iconDotsVertical"></use>
                  </svg>
                </a>
                <ul class="dropdown__list dropdown__list--floating dropdown__list--medium dropdown__list--last">
                  <li class="dropdown__item">
                    <form class="dropdown__link dropdown__link--mask">
                      <span class="dropdown__legend dropdown__legend--mask">{{t("fields_move")}}</span>
                      <select class="select select--invisible no-space-out no-space-in dropdown__select" :value="index" @change="onMove" :data-current-index="index">
                        <option :key="listField.id" :value="i" v-for="(listField, i) in value">
                          {{i+1}}{{i === index ? " (current)" : ` - ${listField.field_name}`}}
                        </option>
                      </select>
                    </form>
                  </li>

                  <li class="dropdown__item" @click="moveToTop(field.id)">
                    <a class="dropdown__link">
                      <span class="dropdown__legend">{{t("fields_move_to_top")}}</span>
                    </a>
                  </li>
                  <li class="dropdown__item" @click="removeFromSelected(field.id)">
                    <a class="dropdown__link">
                      <span class="dropdown__legend">{{t("fields_hide")}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <button v-if="columnSetId" @click.prevent="removeColumnSet" class="button button--square button--stroke button--transparent button--full">
        <div class="button__holder">
          <svg class="icon icon--red button__icon">
            <use :xlink:href="iconTrash"></use>
          </svg>
          <span class="button__text is-danger">{{t('column_set_delete')}}</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import MobileDropdown from '../../application/app/mobile_dropdown';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import _ from '../../application/vendor/lodash.custom';
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) => I18n.t(key, {
  scope: 'my_events.track_responses_columns.columns_editor',
  ...opts
});

export default {
  props: [
    'resourceName',
    'updateUrl',
    'initialSelectedFieldsIds',
    'initialListFields',
    'initialColumnSets',
    'initialColumnSetId',
    'initialColumnSetName',
    'iconDotsVertical',
    'iconCheck',
    'iconSearch',
    'iconTrash',
    'fieldsTitle'
  ],

  data() {
    return {
      selectedFieldsIds: JSON.parse(this.initialSelectedFieldsIds),
      value: this.initialValue(),
      options: JSON.parse(this.initialListFields),
      columnSets: this.initialColumnSets ? JSON.parse(this.initialColumnSets) : null,
      columnSetId: this.initialColumnSetId || '',
      columnSetName: this.initialColumnSetName || '',
      deletedColumnSets: []
    }
  },
  components: {
    Multiselect
  },

  watch: {
    selectedFieldsIds(fieldsIds) {
      const initialListFields = JSON.parse(this.initialListFields);
      const options = _.flattenDeep(initialListFields.reduce(function(acc, value) {
        acc.push(value["fields"]);
        return acc;
      }, []));
      this.value = fieldsIds.map((currentValue) => {
        return options.find((option) => option.id == currentValue);
      }).filter(Boolean);

      setTimeout(() => MobileDropdown.hookEvents($(this.$el)), 100);
    },
    columnSetId(id) {
      if (id == "new") {
        this.selectedFieldsIds = [];
        this.columnSetName = `Column Set #${this.columnSets.length + 1}`;
      } else if (id) {
        const columnSet = this.columnSets.find(cs => cs.id === id);
        this.columnSetName = columnSet.name;
        this.selectedFieldsIds = columnSet.columns;
      } else {
        this.columnSetName = 'Default';
        this.selectedFieldsIds = JSON.parse(this.initialSelectedFieldsIds);
      }
    }
  },

  computed: {
    fieldName() {
      return `${this.resourceName}[field_ids][]`;
    },
    columnSetIdField() {
      return `${this.resourceName}[set_id]`;
    },
    columnSetNameField() {
      return `${this.resourceName}[set_name]`;
    },
  },

  methods: {
    t,
    removeColumnSet() {
      const index = this.columnSets.findIndex(cs => cs.id === this.columnSetId);
      if (index >= 0) {
        this.deletedColumnSets.push(this.columnSetId);
        this.columnSets.splice(index, 1);
        this.columnSetId = "";
      }
    },
    refreshSelectedFields(value) {
      this.selectedFieldsIds = value.map(val => val.id);
    },
    moveToTop(fieldId) {
      const currentIndex = this.selectedFieldsIds.indexOf(fieldId);
      const newIndex = 0;
      const movingField = this.selectedFieldsIds[currentIndex];
      this.selectedFieldsIds.splice(currentIndex, 1);
      this.selectedFieldsIds.splice(newIndex, 0, movingField);
      $(this.$el).find('.hooked-mobile-dropdown').removeClass('is-active');
    },
    onMove(e) {
      const currentIndex = parseInt(e.target.dataset.currentIndex, 10);
      const newIndex = parseInt(e.target.value, 10);
      const movingField = this.selectedFieldsIds[currentIndex];
      this.selectedFieldsIds.splice(currentIndex, 1);
      this.selectedFieldsIds.splice(newIndex, 0, movingField);
    },
    removeFromSelected(fieldId) {
      this.selectedFieldsIds = this.selectedFieldsIds.filter(el => el !== fieldId);
    },
    initialValue() {
      const initialSelectedIds = JSON.parse(this.initialSelectedFieldsIds);
      const initialListFields = JSON.parse(this.initialListFields);
      const options = _.flattenDeep(initialListFields.reduce(function(acc, value) {
        acc.push(value["fields"]);
        return acc;
      }, []));
      return initialSelectedIds.map((currentValue) => {
        return options.find((option) => option.id == currentValue);
      }).filter(Boolean);
    }
  }
}
</script>
