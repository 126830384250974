<template>
  <chartist
    type="Pie"
    :data="chartData"
    :options="chartOptions"
  />
</template>

<script>
const sum = (a, b) => { return a + b };

export default {
  props: ['series'],
  data() {
    return {
      chartData: {
        series: this.series
      },
      totalValue: this.series.map(s => s.value).reduce(sum),
      chartOptions: {
        width: '190px',
        height: '190px',
        labelInterpolationFnc: (value) => {
          if (this.chartData.series.length > 1 && value > 0) {
            return Math.round(value / this.totalValue * 100) + '%';
          }
          return '';
        }
      }
    }
  }
}
</script>
