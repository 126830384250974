import { Calendar } from '@fullcalendar/core';
import dayGridMonth from '@fullcalendar/daygrid';
import timeGrid from '@fullcalendar/timegrid';
import interaction from '@fullcalendar/interaction';
import moment from 'moment-timezone';
import { Turbo } from '@hotwired/turbo-rails';
import FlashMessage from './flash_message';
import SidePanel from './sidepanel';
import { locale } from '../../calendar/locales';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

const EventPartsCalendar = {
  start() {
    const $calendar = $('.js-event-parts-calendar');
    if (!$calendar.length) {
      return;
    }
    this.setupCalendar($calendar);
    this.setVisibilityForActionbar($calendar);
    this.watchActionbarClicks($calendar);
  },

  setVisibilityForActionbar($calendar) {
    const { eventId } = $calendar.data();
    const selectedView = window.localStorage.getItem(`fcActivitiesView-event-${eventId}`);
    if (selectedView === 'calendar') {
      $('.js-show-calendar').addClass('is-on').removeClass('is-off');
      $($('.js-show-calendar').data('hideOnClick')).addClass('is-hidden');
      $('.js-show-event-parts-list').addClass('is-off').removeClass('is-on');
      $('.js-calendar-container').removeClass('is-hidden');
      $('.js-list').addClass('is-hidden');
      $calendar.trigger('resize');
    } else {
      $('.js-show-calendar').addClass('is-off').removeClass('is-on');
      $('.js-show-event-parts-list').addClass('is-on').removeClass('is-off');
      $($('.js-show-event-parts-list').data('showOnClick')).removeClass('is-hidden');
      $('.js-calendar-container').addClass('is-hidden');
      $('.js-list').removeClass('is-hidden');
    }
  },

  watchActionbarClicks($calendar) {
    const { eventId } = $calendar.data();
    $('.js-show-calendar').on('click', () => {
      $calendar.trigger('resize');
      window.localStorage.setItem(`fcActivitiesView-event-${eventId}`, 'calendar');
    });
    $('.js-show-event-parts-list').on('click', () => {
      window.localStorage.setItem(`fcActivitiesView-event-${eventId}`, 'list');
      if ($calendar.data('reloadListView')) {
        Turbo.visit(window.location, { action: 'replace' });
      }
    });
  },

  setupCalendar($calendar) {
    let search = '';
    let debounce = null;
    let duplicatedEvent = null;
    const {
      defaultDate, url, newEventPartUrl, offsetChangeUrl, duplicateUrl, updateUrl, eventId
    } = $calendar.data();
    const $modalTemplate = $('.js-modal-template');
    const $visibleModal = $('.js-visible-modal');
    const { lang } = document.documentElement;
    const calendar = new Calendar($calendar.get(0), {
      locale: locale(lang),
      plugins: [interaction, dayGridMonth, timeGrid],
      header: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      defaultDate: (window.localStorage.getItem(`fcDefaultDate-event-${eventId}`) || defaultDate),
      defaultView: (window.localStorage.getItem(`fcDefaultView-event-${eventId}`) || 'dayGridMonth'),
      allDaySlot: false,
      selectable: true,
      editable: false,
      contentHeight: 700,
      height: 'parent',
      width: 'parent',
      events: { url },
      unselectCancel: '.sidepanel',
      views: {
        week: {
          slotDuration: '01:00'
        },
        day: {
          slotDuration: '00:30'
        }
      },
      datesRender(info) {
        const { view: { type } } = info;
        const { currentDate } = calendar.state;
        window.localStorage.setItem(`fcDefaultView-event-${eventId}`, type);
        window.localStorage.setItem(`fcDefaultDate-event-${eventId}`, currentDate.toISOString());
      },
      eventResize(info) {
        const { days, milliseconds } = info.endDelta;
        const seconds = milliseconds / 1000;
        const resizeUrl = updateUrl.replace('EVENT_ID', eventId).replace('EVENT_PART_ID', info.event.id);
        $.ajax({
          url: resizeUrl,
          data: { event_part: { end_date_time_offset: { seconds, days } } },
          dataType: 'json',
          type: 'PATCH',
          error(jqXHR) {
            const { responseText, statusText } = jqXHR;
            const errorMessage = (jqXHR.responseJSON && jqXHR.responseJSON.error)
              ? jqXHR.responseJSON.error : responseText;
            sweetAlert({ title: statusText, text: errorMessage });
            info.revert();
          }
        }).done(() => {
          $calendar.data({ reloadListView: true });
          FlashMessage.showMessageWithTimer('info', 'End time extended successfully.');
        });
      },
      eventDragStart(info) {
        if (info.jsEvent.altKey || info.jsEvent.ctrlKey) {
          duplicatedEvent = info.event.id;
          const eClone = {
            id: info.event.id,
            title: info.event.title,
            start: info.event.start,
            end: info.event.end
          };
          calendar.addEvent(eClone);
        }
      },
      eventDrop(info) {
        const { days, milliseconds } = info.delta;
        const seconds = milliseconds / 1000;
        if (duplicatedEvent) {
          $.ajax({
            url: duplicateUrl.replace('EVENT_ID', eventId).replace('EVENT_PART_ID', info.event.id),
            data: { event_part: { offset: { seconds, days } } },
            dataType: 'json',
            type: 'POST',
            error(jqXHR) {
              const { responseText, statusText } = jqXHR;
              const errorMessage = (jqXHR.responseJSON && jqXHR.responseJSON.error)
                ? jqXHR.responseJSON.error : responseText;
              sweetAlert({ title: statusText, text: errorMessage });
              info.revert();
            }
          }).done(() => {
            $calendar.data({ reloadListView: true });
            calendar.refetchEvents();
            calendar.removeAllEvents();
          });
          duplicatedEvent = null;
        } else {
          sweetAlert({
            title: 'Confirmation',
            text: `You are moving "${info.event.title}" to a new start date/time. Please confirm.`,
            icon: 'warning',
            buttons: ['Cancel', 'Confirm']
          }).then((willSubmit) => {
            if (willSubmit) {
              const offsetUrl = offsetChangeUrl.replace('EVENT_ID', eventId).replace('EVENT_PART_ID', info.event.id);
              $calendar.data({ reloadListView: true });
              $.ajax({
                url: offsetUrl,
                data: { event_part: { offset: { seconds, days } } },
                dataType: 'json',
                type: 'PATCH',
                error(jqXHR) {
                  const { responseText, statusText } = jqXHR;
                  const errorMessage = (jqXHR.responseJSON && jqXHR.responseJSON.error)
                    ? jqXHR.responseJSON.error : responseText;
                  sweetAlert({ title: statusText, text: errorMessage });
                  info.revert();
                }
              });
            } else {
              info.revert();
            }
          });
        }
      },
      eventClick(info) {
        $(info.el).addClass('is-active');
        $visibleModal.empty();
        $modalTemplate.clone(true, true).appendTo($visibleModal);
        $visibleModal.find('a').each((_, el) => {
          el.setAttribute('href', el.href.replace('EVENT_PART_ID', info.event.id));
        });
        const $modalWithConfirmation = $visibleModal.find('.js-replace-event-part-data-name');
        $modalWithConfirmation.attr(
          'data-text', $modalWithConfirmation.data('text').replace('EVENT_PART_NAME', info.event.title)
        );
        $visibleModal.find('.js-replace-event-part-name').text(info.event.title);
        const $modal = $visibleModal.find('.modal');
        $modal.toggleClass('is-invisible is-visible');
        $modal.find('.js-modal-close').on('click', () => {
          $(info.view.el).find('.fc-event').removeClass('is-active');
          $modal.toggleClass('is-invisible is-visible');
        });
      },
      eventRender(info) {
        return info.event.title.toLowerCase().indexOf(search) > -1;
      },
      select(info) {
        const { allDay, startStr, endStr } = info;
        let start = null;
        let end = null;
        if (allDay) {
          start = moment(startStr).hours(9);
          end = moment(endStr).subtract(1, 'second').hours(10).minutes(0)
            .seconds(0);
        } else {
          start = moment(startStr);
          end = moment(endStr).seconds(0);
        }

        const paramsData = {
          start: start.format('YYYY-MM-DD[T]HH:mm:ss'),
          end: end.format('YYYY-MM-DD[T]HH:mm:ss')
        };

        SidePanel.loadFromAjax({ url: newEventPartUrl, data: paramsData });
      }
    });

    $('.js-search-input').on('keyup change', (event) => {
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        search = $(event.target).val().toLowerCase();
        calendar.rerenderEvents();
      }, 400);
    });

    calendar.render();

    $calendar.on('resize', () => {
      setTimeout(() => {
        calendar.updateSize();
        calendar.scrollToTime('06:00');
      }, 200);
    });
  }
};

export default EventPartsCalendar;
