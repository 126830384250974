import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'form', 'updatable'];

  connect() {
    this.handleSelectChange();
  }

  handleSelectChange() {
    const option = this.selectTarget.options[this.selectTarget.selectedIndex];
    if (option.dataset.customProperties) {
      const properties = JSON.parse(option.dataset.customProperties);
      this.updatableTargets.forEach((updatableTarget) => {
        const { propertyName } = updatableTarget.dataset;
        updatableTarget.value = properties[propertyName];
      });
    } else {
      this.resetUpdatableTargets();
    }
  }

  resetUpdatableTargets() {
    this.updatableTargets.forEach((updatableTarget) => {
      const { defaultValue } = updatableTarget.dataset;
      if (defaultValue) {
        updatableTarget.value = defaultValue;
      }
    });
  }
}
