export const withSaveState = ($table, options) => {
  const dataState = $table.data('state');
  let state;
  if (dataState) {
    state = dataState.datatable_state.state;
  }
  const stateUrl = $table.data('state-url');
  return stateUrl
    ? {
      ...options,
      stateSave: true,
      stateSaveCallback(settings, data) {
        $.ajax({
          type: 'PATCH',
          dataType: 'json',
          url: stateUrl,
          data: {
            datatable_state: {
              state: JSON.stringify(data)
            }
          }
        });
      },
      stateLoadCallback(settings, callback) {
        if (state) {
          setTimeout(() => {
            callback(JSON.parse(state));
          }, 0);
        } else {
          $.ajax({
            url: stateUrl,
            dataType: 'json',
            success(json) {
              callback(JSON.parse(json.datatable_state.state));
            }
          });
        }
      },
      stateLoadParams(settings, data) {
        const $searchInput = $('.js-search-input');
        if ($searchInput.data('keep-search')) {
          $searchInput.val(data.search.search);
        } else {
          data.search.search = ''; // eslint-disable-line no-param-reassign
        }
      }
    }
    : options;
};
