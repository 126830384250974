<template>
  <div class="card card--overlap card--linear card--initial card--rearrange card--relative card--link js-event-card">
    <header class="card__cover no-scroll" v-lazy-container="{ selector: 'img' }">
      <span class="tag card__tag" :class="attendanceClassTag">{{ attendanceLabel }}</span>
      <div class="card__overlay"></div>
      <img v-if="event.image_url" :data-src="event.image_url" class="card__image">
      <div v-if="!event.image_url" class="card__image">
        <svg class="icon icon--large icon--white icon--center">
          <use :xlink:href="iconUrl('icon-events')"></use>
        </svg>
      </div>
    </header>
    <div class="card__content">
      <div class="card__row card__row--fulfilled card__row--initial card__row--unbreak">
        <span class="card__title">
          <strong>{{ event.name }}</strong>
        <small class="card__subtext" :class="{ 'is-hidden': attendanceCounter !== '' }">{{ event.start_at.display }}</small>
        <small class="card__subtext" :class="{ 'is-hidden': attendanceCounter === '' }">{{ event.start_at.display }} • {{ attendanceCounter }}</small>
        </span>
      </div>
    </div>
    <a :href="eventUrl" target="_blank" class="card__trigger"></a>
  </div>
</template>

<script>
import I18n from 'i18n-js/index.js.erb';
const { lang } = document.documentElement;
const t = (key, opts) => I18n.t(key, {
  scope: 'participants.events.participant_event_tile',
  ...opts
});
export default {
  props: [
    'event',
    'iconUrl',
    'personasAttendances'
  ],

  methods: {
    _toSentence(array) {
      return array.length === 1
        ? array[0]
        : t('to_sentence', {
            items: array.slice(0, array.length - 1).join(', '),
            last: array.slice(-1)
          });
    }
  },

  computed: {
    eventUrl() {
      return this.event.short_url;
    },

    attendanceLabel() {
      const attendanceValue = this.personasAttendances[0].is_attending;
      return { null: t('undecided'), false: t('not_attending'), true: t('attending') }[attendanceValue];
    },

    attendanceClassTag() {
      const attendanceValue = this.personasAttendances[0].is_attending;
      return { null: 'tag--dark-gray', false: 'tag--not-attending', true: 'tag--attending'}[attendanceValue];
    },

    _otherAttendingNames() {
      return this.personasAttendances
                 .slice(1)
                 .filter((persona) => persona.is_attending)
                 .map((persona) => persona.first_name);
    },

    attendanceCounter() {
      let attending = [];
      if (this.personasAttendances[0].is_attending) {
        attending.push(t('you'));
      }
      const attendingNames = attending.concat(this._otherAttendingNames);
      if (this.event.attending_count === 0) {
        return t('no_one_attending_message');
      }
      const onlyMeAttending = this.event.attending_count === attendingNames.length && this.event.attending_count > 0;
      if (onlyMeAttending) {
        const namesInSentence = this._toSentence(attendingNames);
        const joinVerb = (attending[0] === t('you') || attendingNames.length >= 2) ? t('are') : t('is');
        return `${namesInSentence} ${joinVerb} ${t('attending')}`;
      }
      if (attendingNames.length) {
        if (this.event.attending_count === undefined) {
          return attendingNames.join(', ');
        } else {
          return `${(attendingNames).join(', ')} ${t('and')} ${this.event.attending_count - attendingNames.length} ${t('attending')}`;
        }
      } else {
        if (this.event.attending_count === undefined) {
          return '';
        } else {
          return `${this.event.attending_count} ${t('attending')}`;
        }
      }
    }
  }
}
</script>
