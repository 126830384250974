import SidePanel from './sidepanel';

const ToggleEditing = {
  start() {
    $('.toggle-editing').click(this.toggle);
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('.toggle-editing').click(this.toggle);
    });
  },
  toggle(e) {
    e.preventDefault();
    const $button = $(e.currentTarget);
    const $form = $button.closest('.form');
    if ($form.hasClass('is-editing')) {
      $form.find('input, textarea, select').attr('disabled', true);
      $form.removeClass('is-editing');
      $form.trigger('toggle:editing', [false]);
    } else {
      $form.find('input, textarea, select').attr('disabled', false);
      $form.addClass('is-editing');
      $form.trigger('toggle:editing', [true]);
    }
  }
};

export default ToggleEditing;
