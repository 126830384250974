import Rails from '@rails/ujs';
import SidePanel from './sidepanel';

const TableFlashActions = {
  start() {
    $('.js-bulk-sidepanel').on('click', this.handleBulkSidepanel);
    $('.js-action-submit').on('click', this.handleActionSubmit);
    $('[data-min-selected-items]')
      .parents('.flash')
      .on('selected-items:changed', this.handleSelectedItemsChanged);
  },
  handleSelectedItemsChanged(event, selectedItemsCount) {
    const $container = $(event.target);
    $container.find('[data-min-selected-items]').each((_, element) => {
      const min = element.dataset.minSelectedItems;
      $(element).attr('disabled', selectedItemsCount < min);
    });
  },
  handleBulkSidepanel(event) {
    event.preventDefault();
    const url = event.currentTarget.dataset.sidepanelUrl;
    const peopleIds = $('input:hidden[name="event_mailing[list_item_ids][]"]')
      .map((_i, input) => input.value)
      .toArray();
    const data = { people_ids: peopleIds };
    SidePanel.loadFromAjax({ url, data });
  },
  handleActionSubmit(event) {
    event.preventDefault();
    const form = $(event.currentTarget)
      .closest('.js-action-bar-flash-wrapper')
      .find('form:not(header form)')
      .get(0);
    Rails.fire(form, 'submit');
  }
};

export default TableFlashActions;
