<template>
  <div class="card card--half card--initial card--relative card--link">
    <header class="card__cover no-scroll" v-lazy-container="{ selector: 'img' }">
      <a
        :href="linkWithReferral"
        target="_blank"
        class="card__overlay card__overlay--legend"
      >{{image.user.name}}</a>
      <img class="card__image" :data-src="image.urls.thumb" />
    </header>
    <a class="card__trigger" href="#" @click.prevent="handleSelect(image)"></a>
  </div>
</template>


<script>
export default {
  props: ["image", "handleSelect", "selectedImage"],
  computed: {
    linkWithReferral() {
      return `${this.image.user.links.html}?utm_source=eventene&utm_medium=referral`;
    }
  }
};
</script>
