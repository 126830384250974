$(document).on('click', '.js-event-wizard-next-btn', e => {
  const $this = $(e.target);
  const  $form = $this.closest('.js-event-wizard').find('form');
  if ($form.length > 0) {
    e.preventDefault();
    $form.submit();
  }
});

export default {};
