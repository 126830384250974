import SidePanel from './sidepanel';

const LinkToRemove = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('[data-remove]').on('ajax:success', this.removeTarget);
    });
  },

  removeTarget(e) {
    const $target = $(e.currentTarget.dataset.remove);
    $target.remove();
  }
};

export default LinkToRemove;
