const InvitesTable = {
  start() {
    this.startConfirmedInvitesTable();
    this.startPendingInvitesTable();
  },

  startConfirmedInvitesTable() {
    $('.invites-confirmed').DataTable({
      info: false,
      order: [[3, 'asc'], [0, 'asc']],
      paging: false,
      searching: false
    });
  },

  startPendingInvitesTable() {
    $('.invites-pending').DataTable({
      info: false,
      order: [[2, 'asc'], [0, 'asc']],
      paging: false,
      searching: false
    });
  }
};

export default InvitesTable;
