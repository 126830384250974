<template>
  <div class="container__row js-assignments-container">
    <div class="card card--bonded card--overlap is-sticky" data-collapse>
      <nav class="timeline" data-controller="autoscroll" id="activities-nav">
        <a class="timeline__item is-hidden"></a>
        <a v-for="(eventPart, index) in eventParts"
          :key="eventPart.url"
          :href="eventPart.url"
          :data-tab="index+1"
          :class="[eventPart.isActive ? 'is-active' : '' ]"
          class="timeline__item">
          <strong>{{eventPart.name}}</strong>
          <small>{{eventPart.startDateTime}}</small>
        </a>
      </nav>
    </div>
    <button class="button button--white button--light button--circle card__button--collapse-left" type="button" data-toggle-collapse>
      <svg class="icon icon--tiny icon--slategray">
        <use :xlink:href="iconMenuLeft"></use>
      </svg>
    </button>
    <div :class="{'is-hidden': !isLoading}" class="loader loader--spinner"></div>
    <Places :placesListName="placesListName"
            :setPeopleSearchOnAssignments="setPeopleSearch"
            :orderedItems="orderedPlaces"
            :isDisabled="isDisabled"
            :alternativeSpotsName="alternativeSpotsName"
            :spotsAreLimited='spotsAreLimited == "true"'
            :attendingPlacesCountLabel='attendingPlacesCountLabel'
            :assignableSpotsLabel='assignableSpotsLabel'
            :peopleSorting="peopleSorting"
            :people="people"
            :peopleLabels="peopleLabels"
            :placesLabels="placesLabels"
            :initialPeopleSearch="initialPeopleSearch"
            :initialPeopleFilters="initialPeopleFilters"
            :initialPlacesFilters="initialPlacesFilters"
            :editSortUrl="editSortUrl"
            :setSearchUrl="setSearchUrl"
            :unassignPeople="unassignPeople"
            :onDragStart="dragStart"
            :onDragEnd="dragEnd"
            :draggingFrom="draggingFrom"
            :enableSelection="enableSelection"
            :disableSelection="disableSelection"
            :isSelecting="isSelecting"
            :removePersonFromAssigned="removePersonFromAssigned"
            :selectedUnassignedPeopleIds="selectedUnassignedPeopleIds"
            :unselectUnassignedPeople="unselectAllIdsUnassigned"
            :selectUnassignedPeople="selectAllIdsUnassigned"
            :peopleHash="peopleHash"
            :assignmentsSettingsUrl="assignmentsSettingsUrl"
            :peopleEditFiltersUrl="peopleEditFiltersUrl"
            :placesEditFiltersUrl="placesEditFiltersUrl"
            :requestForPeopleToPlace="assignPeopleToPlace"
            :showItemOnSidePanel="showItemOnSidePanel"
            @dropcancel="handleDropCancel"
            :icon="icon"/>
    <People :peopleListName="peopleListName"
            :peopleEntityName="peopleEntityName"
            :peopleSearch="peopleSearch"
            :selectedPeopleIds="selectedUnassignedPeopleIds"
            :selectPeopleIds="selectPeopleIds"
            :toggleSelectionForPersonId="toggleSelectionForPersonId"
            :unassignPeople="unassignPeople"
            :onDragStart="dragStart"
            :onDragEnd="dragEnd"
            :enableSelection="enableSelection"
            :isSelecting="isSelecting"
            :unselectPeopleIds="unselectPeopleIds"
            :unselectAll="unselectAllIdsUnassigned"
            :items="unassignedPeople"
            :attendanceLimitReached="attendanceLimitReached"
            :isDisabled="isDisabled"
            :places="orderedPlaces"
            :peopleLabels="peopleLabels"
            :requestForPeopleToPlace="assignPeopleToPlace"
            :showItemOnSidePanel="showItemOnSidePanel"
            :peopleListKey="peopleListKey"
            :icon="icon" />
    <button class="button button--white button--light button--circle card__button--collapse-right" type="button" data-toggle-collapse>
      <svg class="icon icon--tiny icon--slategray">
        <use :xlink:href="iconMenuRight"></use>
      </svg>
    </button>
  </div>
</template>

<script>
  import orderItems from '../js/order_items';
  import SidePanel from '../../application/app/sidepanel';
  import People from './assignments/people.vue';
  import Places from './assignments/places.vue';
  import Fuse from 'fuse.js';

  export default {
    props: [
      'alternativeSpotsName',
      'assignmentsSettingsUrl',
      'bulkAssignmentUrl',
      'bulkDeleteAssignmentUrl',
      'disabled',
      'editSortUrl',
      'initialEventParts',
      'initialPeopleSearch',
      'initialPeopleSorting',
      'initialPeopleFilters',
      'initialPlacesFilters',
      'initialPlacesSorting',
      'initialPeopleLabels',
      'initialPlacesLabels',
      'initialUnassignedPeopleIds',
      'initialHasWaitingList',
      'initialMaxAttendanceLimit',
      'itemDetailsUrl',
      'itemsUrl',
      'peopleEditFiltersUrl',
      'peopleListName',
      'spotsAreLimited',
      'attendingPlacesCountLabel',
      'assignableSpotsLabel',
      'placesListName',
      'placesEditFiltersUrl',
      'peopleEntityName',
      'searchablePeopleKeys',
      'setSearchUrl',
      'svgIconsUrl'
    ],
    components: {
      People,
      Places
    },
    data() {
      return {
        isSelecting: false,
        isLoading: true,
        isDisabled: (this.disabled === 'true'),
        unassignedPeopleIds: [],
        selectedUnassignedPeopleIds: [],
        people: [],
        peopleSearch: this.initialPeopleSearch,
        peopleSorting: JSON.parse(this.initialPeopleSorting),
        placesSorting: JSON.parse(this.initialPlacesSorting),
        peopleLabels: JSON.parse(this.initialPeopleLabels),
        placesLabels: JSON.parse(this.initialPlacesLabels),
        hasWaitingList: JSON.parse(this.initialHasWaitingList),
        maxAttendanceLimit: JSON.parse(this.initialMaxAttendanceLimit),
        places: [],
        draggingFrom: null,
        peopleListKey: 0
      }
    },
    methods: {
      fetchSearchPeopleAndPlaces() {
        this.fetchSearchPeople();
        this.fetchSearchPlaces();
      },
      setPeopleSearch(searchValue) {
        this.peopleSearch = searchValue;
      },
      icon(name) {
        return `${this.svgIconsUrl}#${name}`;
      },
      _successSearchPeople(response) {
        this.people = response.data.data || [];
      },
      _successSearchPlaces(response) {
        this.places = response.data.data || [];
      },
      _removeFromPeopleUnassignedIds(ids) {
        this.unassignedPeopleIds = this.unassignedPeopleIds.filter(id => !ids.includes(id));
      },
      removePersonFromAssigned(personId, originPlaceId) {
        this.places = this.places.map((place) => {
          const currentPlaceId = place.attributes.id;
          if (currentPlaceId === originPlaceId) {
            if (place.assignments !== null) {
              place.assignments = place.assignments.filter(e => e !== personId)
            }
          }
          return place;
        });
      },
      _successBulkAssignment(response) {
        this.places = this.places.map((place) => {
          place.assignments = response.body[place.attributes.id] || [];
          this._removeFromPeopleUnassignedIds(place.assignments);
          return place;
        });
        this.fetchSearchPeopleAndPlaces();
      },
      _successBulkUnassignment(response, peopleIds) {
        this._successBulkAssignment(response);
        this.unassignedPeopleIds = this.unassignedPeopleIds.concat(peopleIds);
      },
      _failBulkAssignment(response) {
        this.fetchSearchPeopleAndPlaces()
        const errorMessage = response
          .body
          .errors
          .map((error) => error.message)
          .join('. ');
        swal('Something went wrong.', errorMessage, 'error');
      },
      unassignPeople(peopleIds) {
        if (peopleIds === null) {
          return;
        }
        const params = { params: { people_ids: peopleIds } };
        this.isLoading = true;
        return this.$http.delete(this.bulkDeleteAssignmentUrl, params)
          .then((response) => {
            this._successBulkUnassignment(response, peopleIds);
          })
          .finally(() => this.isLoading = false);
      },
      assignPeopleToPlace(placeId, peopleIds) {
        const params = { bulk_assignments: { people_ids: peopleIds, place_id: placeId } }
        this.isLoading = true;
        return this.$http.put(this.bulkAssignmentUrl, params)
          .then(this._successBulkAssignment, this._failBulkAssignment)
          .finally(() => this.isLoading = false);
      },
      fetchSearchPeople() {
        const params = { params: {
          list_type: 'people',
          page: 1
        } };
        this.isLoading = true;
        this.$http.get(this.itemsUrl, params)
          .then(this._successSearchPeople)
          .finally(() => this.isLoading = false);
      },
      fetchSearchPlaces() {
        const params = { params: {
          list_type: 'places',
          page: 1
        } };
        this.isLoading = true;
        this.$http.get(this.itemsUrl, params)
          .then(this._successSearchPlaces)
          .finally(() => this.isLoading = false);
      },
      _transformToHash(acc, person) {
        const personId = person.attributes.id;
        acc[personId] = person;
        return acc;
      },
      toggleSelectionForPersonId(personId) {
        if (!this.isSelectable(personId)) {
          return;
        }
        this.isSelecting = true;
        const index = this.selectedUnassignedPeopleIds.indexOf(personId);
        if (index > -1) {
          this.selectedUnassignedPeopleIds.splice(index, 1);
        } else {
          this.selectedUnassignedPeopleIds.push(personId);
        }
      },
      _uniqueArray(array) {
        return array.filter((elem, pos) => (elem !== null) && (array.indexOf(elem) === pos));
      },
      isSelectable(personId) {
        if (this.attendanceLimitReached && this.waitlistedPeopleIds.indexOf(personId) >= 0) {
          return false;
        }
        return this.undecidedOrNotAttendingPeopleIds.indexOf(personId) < 0;
      },
      selectAllIdsUnassigned() {
        this.enableSelection();
        this.selectedUnassignedPeopleIds = this.filteredUnassignedPeopleIds;
      },
      unselectAllIdsUnassigned() {
        this.selectedUnassignedPeopleIds = [];
      },
      selectPeopleIds(peopleIds) {
        this.enableSelection();
        this.selectedUnassignedPeopleIds = this._uniqueArray(
          this.selectedUnassignedPeopleIds.concat(peopleIds)
        );
      },
      unselectPeopleIds(peopleIds) {
        this.selectedUnassignedPeopleIds = this._uniqueArray(
          this.selectedUnassignedPeopleIds.filter(selectedId => (peopleIds.indexOf(selectedId) < 0))
        );
      },
      showItemOnSidePanel(itemId, listType) {
        const url = this.itemDetailsUrl.replace('ITEM_ID', itemId);
        const data = { list_type: listType };
        SidePanel.loadFromAjax({ url, data });
      },
      enableSelection() {
        this.isSelecting = true;
      },
      disableSelection() {
        this.isSelecting = false;
      },
      dragStart(event) {
        this.unselectAllIdsUnassigned();
        this.draggingFrom = Number(event.from.dataset.id);
      },
      dragEnd() {
        this.draggingFrom = null;
      },
      handleDropCancel(event) {
        const { from } = event;
        const fromPlaceId = Number(from.dataset.id);
        if (fromPlaceId === 0) {
          this._redrawPeople();
        }
      },
      _redrawPeople() {
        this.peopleListKey += 1;
      }
    },
    mounted: function() {
      $(this.$el).on('assignments:reload', this.fetchSearchPeopleAndPlaces);
      this.unassignedPeopleIds = JSON.parse(this.initialUnassignedPeopleIds);
      this.fetchSearchPeopleAndPlaces();
    },
    computed: {
      orderedPlaces() {
        return orderItems(this.places, this.placesSorting);
      },
      iconDotsVerticalUrl() {
        return this.icon('icon-dots-vertical');
      },
      iconMenuLeft() {
        return this.icon('icon-menu-left');
      },
      iconMenuRight() {
        return this.icon('icon-menu-right');
      },
      eventParts() {
        return JSON.parse(this.initialEventParts);
      },
      peopleHash() {
        return (this.people || []).reduce(this._transformToHash, {});
      },
      filteredUnassignedPeopleIds() {
        const search = this.peopleSearch || "";
        if (search.length === 0) {
          return this.unassignedPeopleIds;
        }
        const filteredPeopleIds = this.fuse.search(search);
        return this.unassignedPeopleIds.filter(unassignedPersonId => filteredPeopleIds.includes(String(unassignedPersonId)));
      },
      fuse() {
        const options = {
          threshold: 0.2,
          keys: JSON.parse(this.searchablePeopleKeys),
          id: 'attributes.id'
        };
        return new Fuse(this.people, options);
      },
      unassignedPeople() {
        const people = this.filteredUnassignedPeopleIds.map((unassignedPersonId) => {
          return (this.peopleHash[unassignedPersonId] || null);
        }).filter(person => (person !== null));
        return orderItems(people, this.peopleSorting);
      },
      waitlistedPeopleIds() {
        return this.people.filter((item) => item.tracking.attending.value === "waitlisted")
          .map((item) => item.attributes.id);
      },
      undecidedOrNotAttendingPeopleIds() {
        const undecidedOrNotAttending = ["undecided", "not_attending"];
        return this.people.filter((item) => undecidedOrNotAttending.includes(item.tracking.attending.value))
          .map((item) => item.attributes.id);
      },
      attendanceLimitReached() {
        return this.hasWaitingList && (this.maxAttendanceLimit <= this.attendingPeopleCount);
      },
      attendingPeopleCount() {
        return this.people.filter((item) => item.tracking.attending.value === "attending").length;
      }
    }
  }
</script>
