import IMask from 'imask/esm/imask';
import 'imask/esm/masked/number';
import SidePanel from './sidepanel';

const ITEM_MASKS = ['Number', 'Pattern'];
const INPUT_MASKS_SELECTOR = ITEM_MASKS.map((type) => `input[data-mask='${type}']`).join(',');

const ItemsMasks = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      const $maskInputs = $sidepanel.find(INPUT_MASKS_SELECTOR);
      if ($maskInputs.length) {
        this.loadMask($sidepanel);
      }
    });
    this.loadMask($(document));
  },

  loadMask($parent) {
    const $maskInputs = $parent.find(INPUT_MASKS_SELECTOR);
    if ($maskInputs.length) {
      $maskInputs.each(function(_index, element) {
        const { mask, scale, pattern } = $(element).data();
        IMask(element, {
          mask,
          blocks: {
            Pattern: {
              mask: pattern
            },
            Number: {
              mask: Number,
              scale,
              signed: false,
              thousandsSeparator: '',
              padFractionalZeros: false,
              normalizeZeros: true,
              radix: '.',
              mapToRadix: ['.', ',']
            }
          }
        });
      });
    }
  }
};

export default ItemsMasks;
