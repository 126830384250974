import SidePanel from './sidepanel';

const ImageUpload = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('[data-image-upload]').click(this.chooseFile.bind(this));
    });
    $('[data-image-upload]').click(this.chooseFile.bind(this));
  },

  chooseFile(e) {
    e.preventDefault();
    const $button = $(e.currentTarget);
    const query = $button.data('imageUploadField');
    const $inputFile = query ? $(query) : $button.siblings('input[type=file]').first();
    $inputFile.one('change', this.submit.bind(this)).click();
  },

  submit(e) {
    const $fileInput = $(e.currentTarget);
    if (this._validateFileSize(e.currentTarget)) {
      $fileInput.parents('form').submit();
    }
  },

  _validateFileSize(fileData) {
    const maxSize = 12 * 1024;
    if (fileData.files && fileData.files[0]) {
      const fsize = fileData.files[0].size / 1024;
      if (fsize > maxSize) {
        sweetAlert({
          title: 'File size is too big',
          text: `File size is ${(fsize / 1024).toFixed(2)}MB, and the limit is ${maxSize
            / 1024} MB.`,
          type: 'error'
        });
        return false;
      }
      return true;
    }
    return true;
  }
};

export default ImageUpload;
