import I18n from 'i18n-js';
import flatpickr from 'flatpickr';
import { German } from 'flatpickr/dist/l10n/de';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { French } from 'flatpickr/dist/l10n/fr';
import { Italian } from 'flatpickr/dist/l10n/it';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { Portuguese } from 'flatpickr/dist/l10n/pt';

const locale = {
  'de': German,
  'es': Spanish,
  'fr': French,
  'it': Italian,
  'nl': Dutch,
  'pt-BR': Portuguese
}[I18n.currentLocale()];

export default {
  start() {
    flatpickr.localize(locale);
  }
}
