const CheckAll = {
  start() {
    $('.js-check-all-table')
      .find('[name=checkall]')
      .on('change', this.toggleCheckAll)
      .end()
      .find('tbody [type=checkbox]')
      .on('change', this.toggleRowCheck);
  },

  toggleCheckAll(e) {
    const checkbox = e.target;
    const $table = $(checkbox).parents('.js-check-all-table');
    $table.find('tbody [type=checkbox]').prop('checked', checkbox.checked);
  },

  toggleRowCheck(e) {
    const checkbox = e.target;
    if (!checkbox.checked) {
      const $table = $(checkbox).parents('.js-check-all-table');
      $table.find('[name=checkall]').prop('checked', false);
    }
  }
};

export default CheckAll;
