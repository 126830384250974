import sweetAlert from 'sweetalert';

const SubmitToAction = {
  start() {
    $('button[data-submit-action]').on('click', this.buttonClick.bind(this));
  },

  buttonClick(e) {
    e.preventDefault();
    const $button = $(e.currentTarget);
    const action = $button.data('submit-action');
    const options = $button.data('submit-options');
    const formId = options.form;
    const $formFromId = $(formId);
    const $form = formId ? $formFromId : $button.closest('form');
    if (options.confirm) {
      this.askConfirmation(options.confirm).then(() => this.submitForm($form, action, options));
    } else {
      this.submitForm($form, action, options);
    }
  },

  submitForm($form, action, options) {
    const oldAction = $form.attr('action');
    $form.attr('action', action);
    if (options.blank) {
      $form.attr('target', '_blank');
    }
    if (options.method) {
      $form
        .attr('method', 'get')
        .find('[name=_method]')
        .val(options.method);
    }
    $form.submit();
    if (options.blank) {
      setTimeout(() => {
        $form
          .attr('action', oldAction)
          .attr('target', '')
          .attr('method', 'post')
          .find('[name=_method]')
          .val('patch');
      }, 0);
    }
  },

  askConfirmation(message) {
    return sweetAlert({
      title: 'Warning!',
      text: message,
      icon: 'warning',
      buttons: ['Cancel', 'Continue']
    }).then(result => (result ? Promise.resolve() : Promise.reject()));
  }
};

export default SubmitToAction;
