import { Controller } from 'stimulus';
import List from 'list.js';

const LOCAL_STORAGE_KEY = 'activityFilter';

export default class extends Controller {
  static values = { eventId: Number };

  static targets = [
    'input',
    'inputWrapper',
    'searchToggle',
    'sortToggle',
    'sortItem',
    'flash',
    'counter',
    'tab',
    'tabContent',
    'filters'
  ];

  connect() {
    this.scrollPositions = JSON.parse(localStorage.getItem('scrollPositions')) || {};

    Turbo.clearCache();

    this.filter = this.getFilter(this.eventIdValue);

    this.list = new List(this.element, {
      searchColumns: ['name'],
      valueNames: [
        'name',
        { data: ['start-date', 'index'] }
      ]
    });

    this.list.on('searchComplete', () => {
      if (this.list.searched) {
        this.flashTarget.classList.remove('is-hidden');
        this.counterTarget.innerText = this.list.visibleItems.length;
      } else {
        this.flashTarget.classList.add('is-hidden');
      }
    });

    this.list.fuzzySearch(this.filter.search);

    this.sortItemTargets.forEach(sortItem => {
      if (sortItem.dataset.sort === this.filter.sortBy) {
        this.sort(sortItem, this.filter.sortOrder);
      }
    });

    this.showTab(this.filter.currentTab);

    this.inputTarget.value = this.filter.search;

    if (this.filter.search || this.filter.enabled) {
      this.searchToggleTarget.classList.add('is-on');
      this.inputWrapperTarget.classList.remove('is-hidden');
    }
  }

  getFilter(eventId) {
    const persisted = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

    if (persisted && persisted.eventId === eventId) {
      return persisted;
    }

    return {
      eventId,
      search: '',
      sortBy: 'start-date',
      sortOrder: 'asc',
      enabled: false,
      currentTab: 'activities'
    };
  }

  toggleSearch(e) {
    if (e.currentTarget.classList.contains('is-on')) {
      this.filter.enabled = false;
      e.currentTarget.classList.remove('is-on');
      this.inputWrapperTarget.classList.add('is-hidden');
    } else {
      this.filter.enabled = true;
      e.currentTarget.classList.add('is-on');
      this.inputWrapperTarget.classList.remove('is-hidden');
    }

    this.persist();
  }

  clear(e) {
    e.preventDefault();
    this.filter.search = '';
    this.inputTarget.value = this.filter.search;
    this.list.fuzzySearch(this.filter.search);
    this.persist();
  }

  handleSortClick(e) {
    e.preventDefault();
    this.sort(e.currentTarget);
  }

  sort(target, order) {
    const sortBy = target.dataset.sort;
    let sortOrder = order;
    if (!sortOrder) {
      sortOrder = this.filter.sortBy !== sortBy
        ? 'asc'
        : this.filter.sortOrder === 'asc' ? 'desc' : 'asc';
    }
    this.sortToggleTarget.innerText = target.textContent;
    this.filter = { ...this.filter, sortBy, sortOrder };
    this.list.sort(sortBy, { order: sortOrder });
    this.persist();
  }

  handleInputChange(e) {
    this.filter.search = e.target.value;
    this.persist();
  }

  handleTabClick(e) {
    e.preventDefault();
    this.filter.currentTab = e.currentTarget.dataset.tab;
    this.persist();
    this.showTab(this.filter.currentTab);
  }

  showTab(tabId) {
    this.tabTargets.forEach(tab => {
      if (tab.dataset.tab === tabId) {
        tab.classList.add('is-on', 'is-active');
        tab.classList.remove('is-off');

        if (tab.dataset.filters) {
          this.filtersTarget.classList.remove('is-hidden');
        } else {
          this.filtersTarget.classList.add('is-hidden');
        }
      } else {
        tab.classList.remove('is-on', 'is-active');
        tab.classList.add('is-off');
      }
    });

    this.tabContentTargets.forEach(tabContent => {
      if (tabContent.dataset.tab === tabId) {
        tabContent.classList.remove('is-hidden');

        if (this.scrollPositions[this.element.id]) {
          this.element.scrollTo(0, this.scrollPositions[this.element.id]);
        }

      } else {
        tabContent.classList.add('is-hidden');
      }
    });
  }

  persist() {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(this.filter));
  }
}
