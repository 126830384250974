import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['hint', 'select'];

  handleSelectChange() {
    const selectedValue = this.selectTarget.value;

    this.hintTargets.forEach((hint) => {
      const { hintValue } = hint.dataset;
      if (hintValue === selectedValue) {
        hint.classList.remove('is-hidden');
      } else {
        hint.classList.add('is-hidden');
      }
    });
  }
}
