import SidePanel from './sidepanel';

const CopySegmentedList = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      const $selectWithExistingLists = $sidepanel.find('.js-copy-existing-list-select');
      if ($selectWithExistingLists.length === 0) {
        return;
      }

      $selectWithExistingLists.change(this.watchChange);
      $selectWithExistingLists.change();
    });
  },

  watchChange() {
    const data = $(this)
      .find('option:selected')
      .data();
    if (data === undefined) {
      return;
    }
    const $typeWrapper = $('.js-kinds-for-copy-existing-lists-wrapper');
    const currentType = data.listType;
    $typeWrapper.find('input').prop('disabled', !data.isMaster);
    const $input = $typeWrapper.find(`input[value="${currentType}"]`);
    $input.prop('checked', true);
  }
};

export default CopySegmentedList;
