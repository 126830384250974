import I18n from 'i18n-js/index.js.erb';
import ToggleVisible from '../application/app/toggle_visible';

const t = (key, opts) => I18n.t(key, {
  scope: 'datatables',
  ...opts
});

const getRowId = (rowData) => {
  if (rowData.attributes) {
    return rowData.attributes.id;
  }
  return $(rowData[0]).data('id');
};

const renderHiddenRowIds = ($wrapper, selectedData) => {
  const idsFieldName = $wrapper.data('elementName');
  const selectedIds = selectedData.toArray().map(getRowId);
  const hiddenInputs = selectedIds.map(id => `<input type='hidden' name='${idsFieldName}[]' value='${id}'>`);
  $wrapper.html(hiddenInputs.join(''));
};

export const updateCheckAllCheckbox = (table, $checkAllCheckbox) => {
  const selectedRowsFromCurrentPage = table
    .rows({ selected: true, search: 'applied', page: 'current' })
    .data().length;
  const rowsFromCurrentPage = table.rows({ search: 'applied', page: 'current' }).data().length;
  $checkAllCheckbox.prop(
    'checked',
    rowsFromCurrentPage > 0 && selectedRowsFromCurrentPage === rowsFromCurrentPage
  );
};

export const evaluateSelectedRows = (table, $checkAllCheckbox, renderFlash = true) => {
  updateCheckAllCheckbox(table, $checkAllCheckbox);
  const selectedRowsFromAllPages = table.rows({ selected: true }).data().length;
  const selectedRowsData = table.rows({ selected: true }).data();
  const $hiddenSelectedWrapper = $checkAllCheckbox
    .closest('.js-action-bar-flash-wrapper')
    .find('.js-selected-rows-ids');
  renderHiddenRowIds($hiddenSelectedWrapper, selectedRowsData);
  if (!renderFlash) {
    return;
  }
  const $flash = $checkAllCheckbox.closest('.js-action-bar-flash-wrapper').find('.flash');
  if (selectedRowsFromAllPages > 0) {
    const pluralizedCounter =
      selectedRowsFromAllPages === 1 ? '1 Item' : `${selectedRowsFromAllPages} Items`;
    $flash.find('.js-items-selected-counter').text(`${pluralizedCounter} Selected`);
    $flash.trigger('selected-items:changed', [selectedRowsFromAllPages]);
    ToggleVisible.show($flash);
  } else {
    $flash.find('.js-items-selected-counter').text('');
    $flash.trigger('selected-items:changed', [0]);
    ToggleVisible.hide($flash);
  }
};

const setupSelectAllButton = (table) => {
  const $checkAllCheckbox = $('#checkall');
  $checkAllCheckbox.on('change', (event) => {
    const checkbox = event.target;
    if (checkbox.checked) {
      table.rows({ search: 'applied', page: 'current' }).select();
    } else {
      table.rows().deselect();
    }
  });
};

const setupUnselectAllButton = (table) => {
  $('#uncheckall').on('click', () => {
    table.rows().deselect();
  });
};

const setupSelectHooks = (table) => {
  const $checkAllCheckbox = $('#checkall');
  table.on('deselect', () => evaluateSelectedRows(table, $checkAllCheckbox));
  table.on('select', () => evaluateSelectedRows(table, $checkAllCheckbox));
};

const setupSearchInput = (table) => {
  let debounce = null;
  let drawnInput = null;
  const timeout = 350;
  table.on('destroy.interval', () => clearTimeout(debounce));
  $('.js-search-input').on('keyup change', (event) => {
    clearTimeout(debounce);
    debounce = setTimeout(() => {
      const value = $(event.target).val();
      if (value !== drawnInput) {
        table.search(value).draw();
        drawnInput = value;
      }
    }, timeout);
  });
};

const setupPageLengthDropdown = (table) => {
  $('.js-page-length-dropdown a').on('click', (event) => {
    event.preventDefault();
    event.stopPropagation();
    const value = $(event.currentTarget).data('pageLength');
    table.page.len(value).draw();
    const labelForCurrentPageLength = $(event.currentTarget).text();
    $('.js-current-page-length').text(labelForCurrentPageLength);
  });
};

const exportedContentFormat = {
  body(data, _row, _column, node) {
    return node.dataset.display || data;
  }
};

const setupExportButtons = (table) => {
  const tableButtons = new $.fn.dataTable.Buttons(table, {
    buttons: [
      {
        extend: 'print',
        className: 'js-print-button',
        title() {
          return $(table.header()[0]).data('title') || $('head title').text();
        },
        customize(win) {
          const classes =
            'table__data table__data--tiny js-checkbox-row table__header table__header--tiny is-print-hidden';
          $(win.document.body)
            .find('th, td')
            .removeClass(classes);
        },
        exportOptions: {
          format: exportedContentFormat,
          modifier: {
            page: 'all',
            search: 'applied'
          },
          columns: ':not(.not-export):visible'
        }
      },
      {
        extend: 'csv',
        className: 'js-csv-button',
        charset: 'UTF-8',
        bom: true,
        exportOptions: {
          format: exportedContentFormat,
          modifier: {
            page: 'all',
            search: 'applied'
          },
          columns: ':not(.not-export):visible'
        }
      },
      {
        extend: 'copyHtml5',
        className: 'js-copy-to-clipboard-button',
        title: null,
        exportOptions: {
          format: exportedContentFormat,
          modifier: {
            page: 'all',
            search: 'applied'
          },
          columns: ':not(.not-export):visible'
        }
      }
    ]
  });

  $('.js-export-print').click((event) => {
    event.preventDefault();
    table.button('.js-print-button').trigger();
  });

  $('.js-export-csv').click((event) => {
    event.preventDefault();
    table.button('.js-csv-button').trigger();
  });

  $('.js-export-clipboard').click((event) => {
    event.preventDefault();
    table.button('.js-copy-to-clipboard-button').trigger();
  });

  return tableButtons;
};

export const defaultTableOptions = {
  pagingType: 'full_numbers',
  language: {
    decimal: t('decimal'),
    emptyTable: t('empty_table'),
    info: t('info'),
    infoEmpty: t('info_empty'),
    infoFiltered: t('info_filtered'),
    infoPostFix: t('info_postFix'),
    thousands: t('thousands'),
    lengthMenu: t('length_menu'),
    loadingRecords: t('loading_records'),
    processing: t('processing'),
    search: t('search'),
    zeroRecords: t('zero_records'),
    paginate: {
      first: t('paginate.first'),
      last: t('paginate.last'),
      next: t('paginate.next'),
      previous: t('paginate.previous')
    },
    aria: {
      sortAscending: t('aria.sort_ascending'),
      sortDescending: t('aria.sort_descending')
    }
  }
};

export const setupPaginationOnDOM = () => {
  const datatablePagination = $('.pagination__holder').detach();
  datatablePagination.appendTo('.js-pagination-wrapper');
};

export const customizePageLength = (table) => {
  const { length } = table.api().state();
  const lengthAsText = length === -1 ? 'All' : length;
  $('.js-current-page-length').text(lengthAsText);
};

export const updateTableItemsCount = (table) => {
  const tableApi = table.api();
  const { recordsTotal, recordsDisplay } = tableApi.page.info();
  const $totalItems = $(table).closest('.js-table-container').find('[data-total-items]');
  const { entityName } = $totalItems.data();
  const totalItemsLabel = `${recordsTotal} ${entityName}`;
  if (recordsDisplay === recordsTotal) {
    $totalItems.text(totalItemsLabel);
  } else {
    $totalItems.text(`${recordsDisplay} of ${totalItemsLabel}`);
  }
};

export const setup = (table, opts = {}) => {
  setupSelectAllButton(table);
  setupUnselectAllButton(table);
  if (opts.selectHooks !== false) {
    setupSelectHooks(table);
  }
  setupSearchInput(table);
  setupPageLengthDropdown(table);
  setupExportButtons(table);
};
