<template>
  <form @submit.prevent="createTicket" id="feedback-widget">
    <div class="card__header card__header--center">
      <h6 class="card__title">{{t('title')}}</h6>
    </div>
    <div class="card__content">
      <p v-if="errorMessage" class="alert alert--danger">{{errorMessage}}</p>
      <textarea id="feedback-comment" v-model="comment" class="input input--invisible" cols="30" name="feedback" :placeholder="t('placeholder')" rows="5"></textarea>
    </div>
    <div class="card__footer">
      <button class="button button--square button--full card__button" :disabled="!isValid || submitting">{{submitText}}</button>
    </div>
  </form>
</template>

<script>
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) => I18n.t(key, {
  scope: 'js.organizations.show.feedback',
  ...opts
});
export default {
  props: ['subdomain', 'apiKey', 'name', 'email'],

  data() {
    return {
      comment: null,
      errorDetails: null,
      errorMessage: null,
      submitting: false,
    };
  },
  methods: {
    t,
    createTicket() {
      const data = {
        name: this.name,
        email: this.email,
        subject: "Feedback",
        type: "Request",
        description: this.comment,
        priority: 1,
        status: 2
      };
      const options = {
        url: `https://${this.subdomain}.freshdesk.com/api/v2/tickets.json`,
        method: 'POST',
        contentType: "application/json; charset=utf-8",
        headers: {
          "Authorization": "Basic " + btoa(this.apiKey + ":x")
        },
        body: data
      }

      this.submitting = true;
      this.$http(options).then(this.createTicketSuccess, this.createTicketError);
    },
    createTicketSuccess(response) {
      this.comment = null;
      this.errorDetails = null;
      this.errorMessage = null;
      this.submitting = false;
      sweetAlert({ title: t('thank_you_message'), icon: "success" });
    },
    createTicketError(response) {
      this.errorMessage = response.statusText;
      this.submitting = false;
    }
  },
  computed: {
    isValid() {
      return Boolean(this.name && this.email && this.comment);
    },
    submitText() {
      return this.submitting ? t('sending') : t('send_feedback');
    }
  }
}
</script>
