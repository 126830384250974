import jstz from 'jstimezonedetect';
import SidePanel from './sidepanel';

const UserTimeZone = {
  start() {
    const $input = $('#user_time_zone');
    this.setTimezone($input);

    SidePanel.onUpdate(($sidepanel) => {
      const $inputSidepanel = $sidepanel.find('#user_time_zone');
      this.setTimezone($inputSidepanel);
    });
  },

  setTimezone($input) {
    if ($input.length && !$input.val()) {
      const timezone = jstz.determine();
      $input.val(timezone.name());
      $('.oauth_form').each((_index, form) => {
        const $form = $(form);
        let url = new URL(`https://example.com${$form.attr('action')}`);
        url.searchParams.set('time_zone', timezone.name());
        url = `${url.pathname}${url.search}`;
        $form.attr('action', url);
      });
    }
  }
};

export default UserTimeZone;
