import Rails from '@rails/ujs';
import moment from 'moment-timezone';
import SidePanel from './sidepanel';

const SubmitEventSave = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('.js-submit-update-event-form').on('submit', this.submitHandler.bind(this));
    });
  },

  submitHandler(event) {
    const $form = $(event.currentTarget);
    const $submitButton = $form.closest('aside.sidepanel').find('button.js-submit-event-save');
    $submitButton.prop('disabled', true);
    $submitButton.text($submitButton.data('disableWith'));
    const { firstScheduledSend } = $form.data();
    const publishValue = $form.serializeArray().find(field => field.name === 'event[publish]').value;
    const publishAtValue = $form.serializeArray().find(field => field.name === 'event[publish_at]').value;
    const $selectedOption = $form.find("select[name='event[publish_time_zone]'] option:selected");
    const timezoneIana = $selectedOption.data('identifier');
    const eventPublishMoment = moment.tz(publishAtValue, timezoneIana);

    if (!$form.data('confirmed')
        && firstScheduledSend
        && publishValue === 'scheduled'
        && eventPublishMoment.isAfter(firstScheduledSend)) {
      this._showScheduledSendBeforeEventPublishDialog().then((confirmed) => {
        if (confirmed) {
          $form.data('confirmed', true);
          Rails.fire($form.get(0), 'submit');
        } else {
          $submitButton.removeAttr('disabled');
          $submitButton.text($submitButton.data('enableWith'));
        }
      });

      event.preventDefault();
      return false;
    }
    return true;
  },

  _showScheduledSendBeforeEventPublishDialog() {
    return sweetAlert({
      title: 'Warning!',
      text: 'One or more scheduled Sends detected. By confirming your scheduled Publish date, any scheduled Sends before your Publish Date will be changed to Draft status and you will need to re-schedule them.',
      icon: 'warning',
      buttons: ['Cancel', 'Confirm']
    });
  }
};

export default SubmitEventSave;
