const addIsActive = (event) => {
  $(event.currentTarget)
    .parents('.dropdown:first')
    .addClass('is-active');
};

const removeIsActiveOnDropdown = (event) => {
  $(event.currentTarget)
    .removeClass('is-active');
};

const removeIsActive = (event) => {
  $(event.currentTarget)
    .parents('.dropdown:first')
    .removeClass('is-active');
};

const toggleIsActive = (event) => {
  $(event.currentTarget)
    .parents('.dropdown:first')
    .toggleClass('is-active');
};

export { addIsActive, removeIsActiveOnDropdown, removeIsActive, toggleIsActive };
