import displayDatatablesPagination from '../shared/datatables_pagination';
import { withSaveState } from '../shared/datatables_state';
import {
  overridePaginationClasses,
  customizeDOMPaginationClasses
} from '../../datatables/customization-v2';
import {
  setup as tableSetup,
  setupPaginationOnDOM,
  customizePageLength,
  evaluateSelectedRows,
  defaultTableOptions
} from '../../datatables/table-setup';

export const LISTS_TABLE_SELECTOR = '.lists-table';

const ListsTable = {
  start() {
    const $listsTable = $(`${LISTS_TABLE_SELECTOR}:not(.dataTable)`);
    if (!$listsTable.length) {
      return;
    }
    this.startListsTable($listsTable);
  },

  tearDown() {
    $(`${LISTS_TABLE_SELECTOR}.dataTable`).DataTable().destroy();
  },

  startListsTable($listsTable) {
    overridePaginationClasses();
    const $checkAllCheckbox = $('#checkall');
    let table;
    const tableOptions = {
      ...defaultTableOptions,
      order: [[5, 'desc'], [1, 'asc']],
      dom: 'rtp',
      select: {
        style: 'multi',
        selector: 'label.js-checkbox-for-row',
        className: 'is-selected'
      },
      columnDefs: [
        { targets: [0], orderable: false, width: '7%' },
        { targets: [1], searchable: true, width: '20%' },
        { targets: [-1], orderable: false, width: '9%' },
        { targets: '_all', searchable: false }
      ],
      drawCallback() {
        customizePageLength(this);
        customizeDOMPaginationClasses();
        evaluateSelectedRows(table, $checkAllCheckbox, false);
        displayDatatablesPagination(this);
      },
      initComplete: () => {
        setupPaginationOnDOM();
      },
      language: {
        ...defaultTableOptions.language,
        zeroRecords: '<span class="alert alert--info">Sorry, no matching Lists were found</span>',
        emptyTable: '<span class="alert alert--info">No Lists available</span>'
      }
    };
    const tableOptionsWithSaveState = withSaveState($listsTable, tableOptions);
    table = $listsTable.DataTable(tableOptionsWithSaveState);
    tableSetup(table);
  }
};

export default ListsTable;
