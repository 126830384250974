import 'select2';
import 'select2/dist/css/select2.css';
import SidePanel from './sidepanel';

const Select2 = {
  start() {
    const self = this;
    SidePanel.onUpdate(($sidepanel) => {
      const $inputs = $sidepanel.find('.js-select2');
      self._setupSelect2Input($inputs);
    });

    self._setupSelect2Input($('.js-select2'));
  },

  _setupSelect2Input($inputs) {
    $inputs.each(function(_i, input) {
      const $input = $(input);
      const isMultipleSelection = $input.attr('multiple') === 'multiple';
      const select2Instance = $input.select2({
        placeholder: {
          id: '-1',
          text: 'Choose the Options'
        },
        closeOnSelect: !isMultipleSelection,
        width: '100%'
      });
      const $select2Container = $(select2Instance.data('select2').$container);
      $select2Container.addClass('select2--no-space-out');
    });
  }
};

export default Select2;
