import autosize from 'autosize/dist/autosize';
import SidePanel from './sidepanel';

const TextareaAutosize = {
  start() {
    autosize($('.js-autosize'));
    SidePanel.onUpdate(($sidepanel) => {
      autosize($sidepanel.find('.js-autosize'));
    });
  }
};

export default TextareaAutosize;
