const NewListItemsTable = {
  start() {
    this.startTable();
  },

  startTable() {
    $('.new-list-items').DataTable({
      info: false,
      ordering: false,
      paging: false,
      scrollX: true,
      sScrollX: '100%',
      sScrollXInner: '100%',
      scrollY: '300px',
      searching: false,
      select: false,
    });
  }
};

export default NewListItemsTable;
