import sweetAlert from 'sweetalert';
import I18n from 'i18n-js/index.js.erb';
import SidePanel from './sidepanel';
import displayDatatablesPagination from '../shared/datatables_pagination';
import { withSaveState } from '../shared/datatables_state';
import {
  overridePaginationClasses,
  customizeDOMPaginationClasses
} from '../../datatables/customization-v2';
import {
  setup as tableSetup,
  setupPaginationOnDOM,
  customizePageLength,
  evaluateSelectedRows,
  defaultTableOptions,
  updateTableItemsCount
} from '../../datatables/table-setup';
import TableRowActive from './table_row_active';

export const LISTS_TABLE_SELECTOR = '.js-items-table';

const ListItemsTable = {
  start() {
    const $listItemsTable = $(`${LISTS_TABLE_SELECTOR}:not(.dataTable)`);
    if (!$listItemsTable.length) {
      return;
    }
    this.startListItemsTable($listItemsTable);
    $listItemsTable.on('datatable:reload', () => $listItemsTable.DataTable().ajax.reload());
    $listItemsTable.on('filters:set', (e, data) => {
      const { filters } = data;
      const _countEnabled = (accumulator, value) => (value.enabled ? accumulator + 1 : accumulator);
      const count = filters.reduce(_countEnabled, 0);
      const $counter = $('.js-active-filters-counter');
      $counter.html(count);
      if (count === 0) {
        $counter.addClass('is-hidden');
      } else {
        $counter.removeClass('is-hidden');
      }
    });
  },

  tearDown() {
    $(`${LISTS_TABLE_SELECTOR}.dataTable`).DataTable().destroy();
  },

  tableColumns(columns) {
    columns[0].render = (data) => {
      const id = data;
      return `
        <input data-id="${id}" id="check_${id}" class="checkbox table__checkbox" type="checkbox">
        <label for="check_${id}" class="table__label js-checkbox-for-row no-space-out voidclick"></label>`;
    };
    columns.forEach((column) => {
      if (column.response_type === 'comment') {
        column.className = 'table__data--truncate has-truncate-text';
      }
    });
    return columns;
  },

  runDelete(result) {
    const table = this;
    if (result) {
      const itemsIds = table
        .rows({ selected: true })
        .data()
        .pluck('id')
        .toArray();
      const deleteUrl = $('.js-delete-items').data('deleteUrl');
      $.ajax({
        url: deleteUrl,
        method: 'DELETE',
        data: { ids: itemsIds }
      }).done((response) => {
        if (response.error) {
          sweetAlert({
            title: 'Something went wrong',
            text: response.error,
            icon: 'error',
            buttons: {
              confirm: {
                text: 'OK',
                value: true,
                visible: true,
                className: '',
                closeModal: true
              }
            }
          });
        }
        table.ajax.reload(null, false);
      });
    }
  },

  watchDelete(table) {
    $('.js-delete-items').click((event) => {
      const text = $(event.currentTarget).data('alertText');
      sweetAlert({
        title: 'Warning!',
        text,
        icon: 'info',
        dangerMode: true,
        buttons: ['Cancel', 'OK']
      }).then(this.runDelete.bind(table));
    });
  },

  watchEdit(table) {
    $('.js-edit-items').click((event) => {
      const templateEditUrl = $(event.currentTarget).data('editUrl');
      const itemId = table
        .rows({ selected: true })
        .data()
        .pluck('id')
        .toArray()[0];
      const url = templateEditUrl.replace('ITEM_ID', itemId);
      SidePanel.loadFromAjax({ url, method: 'get' });
    });
  },

  watchRowActivate() {
    TableRowActive.attach($('.js-items-table').find('tbody'));
  },

  watchDuplicate(table) {
    $('.js-duplicate-items').click((event) => {
      const itemsIds = table
        .rows({ selected: true })
        .data()
        .pluck('id')
        .toArray();
      const duplicateUrl = $(event.currentTarget).data('duplicateUrl');
      $.ajax({
        url: duplicateUrl,
        method: 'POST',
        data: { ids: itemsIds }
      })
        .done(() => {
          table.rows().deselect();
          table.ajax.reload(null, false);
        })
        .fail((response) => {
          const errorMessage = response.responseJSON
            ? response.responseJSON.error
            : response.responseText;
          sweetAlert({ title: 'Error!', text: errorMessage, icon: 'error' });
        });
    });
  },

  startListItemsTable($listItemsTable) {
    const templateEditUrl = $('.js-edit-items').data('editUrl');
    overridePaginationClasses();
    const $checkAllCheckbox = $('#checkall');
    const itemsName = $listItemsTable.data('plural-entity-name') || 'List Items';
    const table = $listItemsTable.DataTable(withSaveState($listItemsTable, {
      ...defaultTableOptions,
      select: {
        style: 'multi',
        selector: 'label.js-checkbox-for-row',
        className: 'is-selected'
      },
      order: [1, 'asc'],
      columns: this.tableColumns($listItemsTable.data('columns')),
      ajax: {
        url: $listItemsTable.data('source')
      },
      processing: true,
      serverSide: true,
      createdRow(row, data) {
        const itemId = data.id;
        const editUrl = templateEditUrl.replace('ITEM_ID', itemId);
        const $row = $(row);
        $row.addClass('table__row table__row--link');
        $row.click((e) => {
          const isCheckbox = $(e.target).closest('.js-checkbox-row').length > 0;
          if (isCheckbox) {
            e.preventDefault();
            return;
          }
          e.currentTarget.dataset.sidepanelHref = editUrl;
          SidePanel.loadAndShow(e);
        });
      },
      dom: 'rtp',
      drawCallback() {
        customizePageLength(this);
        customizeDOMPaginationClasses();
        evaluateSelectedRows(table, $checkAllCheckbox);
        displayDatatablesPagination(this);
        updateTableItemsCount(this);
      },
      initComplete: () => {
        setupPaginationOnDOM();
        tableSetup(table);
        this.watchDelete(table);
        this.watchDuplicate(table);
        this.watchEdit(table);
        this.watchRowActivate();
      },
      language: {
        ...defaultTableOptions.language,
        zeroRecords: `<span class="alert alert--info">Sorry, no matching ${itemsName} were found</span>`,
        emptyTable: `<span class="alert alert--info">No ${itemsName} available</span>`
      }
    }));
    return $listItemsTable;
  }
};

export default ListItemsTable;
