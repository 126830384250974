import moment from 'moment-timezone';

const SubmitSendForm = {
  start() {
    $('.js-submit-send-form').on('submit', this.submitHandler.bind(this));
  },

  submitHandler(event) {
    const $form = $(event.currentTarget);
    const { eventScheduledForCaption, eventScheduledFor, eventPublishStatus } = $form.data();
    let sendOnValue;
    let sendMoment;
    const momentEventScheduledFor = moment(eventScheduledFor);
    const sendValue = $form.serializeArray().find(field => field.name === 'send_on_kind').value;
    if (sendValue === 'now') {
      sendMoment = moment();
    } else {
      sendOnValue = $form.serializeArray().find(field => field.name === 'event_mailing[send_on]').value;
      const $selectedOption = $form.find('select[name=\'event_mailing[send_on_time_zone]\'] option:selected');
      const timezoneIana = $selectedOption.data('identifier');
      sendMoment = moment.tz(sendOnValue, timezoneIana);
    }
    const sendingAfterScheduledPublish = (
      eventPublishStatus === 'Scheduled' && sendValue === 'select' && sendMoment.isSameOrAfter(momentEventScheduledFor)
    );

    if ($form.data('confirmed') || eventPublishStatus === 'Published' || sendingAfterScheduledPublish) {
      return true;
    }
    event.preventDefault();


    if (eventPublishStatus === 'Unpublished' && sendValue === 'select') {
      this._checkScheduledSendOnUnpublishedEvent($form);
    }
    if (eventPublishStatus === 'Scheduled' && sendValue === 'select') {
      this._checkScheduledSendOnScheduledEvent(
        $form, momentEventScheduledFor, sendMoment, eventScheduledForCaption
      );
    }
    if (eventPublishStatus !== 'Published' && sendValue === 'now') {
      this._checkSendNowOnNotPublishedEvent($form);
    }

    return false;
  },

  _checkScheduledSendOnScheduledEvent($form, momentEventScheduledFor, sendMoment,
    eventScheduledForCaption) {
    if (momentEventScheduledFor.isSameOrBefore(sendMoment)) {
      $form.data('confirmed', true);
      $form.submit();
    } else {
      sweetAlert({
        title: 'Warning!',
        text: `This Event will not yet be published at the time of your scheduled Send. To proceed, select a new Send Date on or after ${eventScheduledForCaption}`,
        icon: 'warning',
        button: {
          text: 'OK',
          value: true,
          visible: true,
          closeModal: true
        }
      });
    }
  },

  _checkScheduledSendOnUnpublishedEvent($form) {
    this._showUnpublishedSendScheduledDialog().then((confirmed) => {
      if (confirmed) {
        $form.data('confirmed', true);
        $form.submit();
      }
    });
  },

  _showUnpublishedSendScheduledDialog() {
    return sweetAlert({
      title: 'Warning!',
      text: 'This Event is not yet published. You can cancel or set your Event to publish on the same date and time as your scheduled Send.',
      icon: 'warning',
      buttons: ['Cancel', 'Publish and Send on Selected Date']
    });
  },

  _checkSendNowOnNotPublishedEvent($form) {
    this._showUnpublishedSendNowDialog().then((confirmed) => {
      if (confirmed) {
        $form.data('confirmed', true);
        $form.submit();
      }
    });
  },

  _showUnpublishedSendNowDialog() {
    return sweetAlert({
      title: 'Warning!',
      text: 'This is Event is not yet Published. You can cancel your Send or publish your Event immediately and proceed with your Send.',
      icon: 'warning',
      buttons: ['Cancel', 'Publish and Send']
    });
  }
};

export default SubmitSendForm;
