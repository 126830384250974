import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const { element } = this;

    this.scrollPositions = JSON.parse(localStorage.getItem('scrollPositions')) || {};

    if (this.scrollPositions[element.id]) {
      element.scrollTo(0, this.scrollPositions[element.id]);
    }

    if (this.scrollPositions.body) {
      $('body').scrollTop(this.scrollPositions.body);
    }

    document.addEventListener('turbo:click', this.save.bind(this));
    $(document).on('click', '.js-trigger-autoscroll', this.save.bind(this));
  }

  save() {
    const { element } = this;
    this.scrollPositions[element.id] = element.scrollTop;
    this.scrollPositions.body = $('body').scrollTop();
    localStorage.setItem('scrollPositions', JSON.stringify(this.scrollPositions));
  }

  disconnect() {
    document.removeEventListener('turbo:click', this.save);
    $(document).off('click', '.js-trigger-autoscroll', this.save);
  }
}
