<template>
  <ul class="list list--bordered">
    <li class="list__item">

      <div class="list__block list__block--tighter" v-show="!editName">
        <label class="switch switch--start no-space-out">
          <span v-if="showToggle">
            <input class="switch__el" v-model="filterSet.enabled" type="checkbox">
            <span class="switch__holder">
              <a class="switch__button"></a>
            </span>
          </span>
          <span class="switch__text" @click="editName = true">{{filterSet.set_name}}</span>
        </label>
        <button @click="editName = true" class="button button--circle button--sky list__button" type="button">
          <svg class="icon button__icon">
            <use :xlink:href="iconEdit"></use>
          </svg>
        </button>
      </div>

      <div class="list__block list__block--tighter" v-show="editName">
        <div class="input--full">
          <input @blur="editName = false" @keyup.enter="editName = false" v-model="filterSet.set_name" class="input input--line" type="text">
        </div>
        <button @click="editName = false" class="button button--circle button--blue list__button" type="button">
          <svg class="icon icon--white button__icon">
            <use :xlink:href="iconCheckmark"></use>
          </svg>
        </button>
      </div>
    </li>

    <rule-row
      :remove-rule-from-parent="removeRule"
      :update-rule-from-parent="updateRule"
      :columns="columns"
      :initial-filter="filter"
      :filter-position="filterPosition"
      :key="filter.row_id"
      :icon-trash="iconTrash"
      :is-editing="editName"
      v-for="(filter, filterPosition) in filterSet.conditions">
    </rule-row>

    <li class="list__item">
      <div class="list__block list__block--tighter">
        <button v-show="!editName" class="button button--sky button--light button--square button--full no-space-out" @click="addRuleRow">
          <div class="button__holder">
            <svg class="icon button__icon">
              <use :xlink:href="iconPlus"></use>
            </svg>
            <span class="button__text">New {{ruleCaption}}</span>
          </div>
        </button>

        <button v-show="editName" class="button button--red button--square button--full no-space-out" @click="$emit('remove-set')">
          <div class="button__holder">
            <svg class="icon icon--white button__icon">
              <use :xlink:href="iconTrash"></use>
            </svg>
            <span class="button__text">Delete Set</span>
          </div>
        </button>
      </div>
    </li>
  </ul>
</template>

<script>
import ruleRow from './rule_row.vue';

export default {
  props: {
    'filter-set': Object,
    'columns': Array,
    'icon-trash': String,
    'icon-edit': String,
    'icon-plus': String,
    'icon-checkmark': String,
    'generate-id': Function,
    'show-toggle': Boolean,
    'rule-caption': String
  },
  components: {
    ruleRow
  },
  data() {
    return {
      editName: false
    }
  },
  methods: {
    addRuleRow() {
      const rowId = this.generateId();
      this.filterSet.conditions.push({"row_id": rowId, "value": ""});
    },

    removeRule(filterPosition) {
      this.filterSet.conditions.splice(filterPosition, 1);
    },

    updateRule(filterPosition, filter) {
      this.filterSet.conditions.splice(filterPosition, 1, filter);
    }
  }
}
</script>

<style lang="scss">

  .filterset {
    &__error-message {
      background-color: #fbe3e4;
      box-shadow: 0 0 5px #ad141e;
      color: #ad141e;
      display: block important;
      padding: 15px;
      position: fixed;
      top: 0;
      transition: transform .2s linear;
      width: 100%;
      z-index: 99;

      * {
        display: block !important;
      }

      *:last-of-type {
        margin: 0;
      }

      &:not(.is-visible) {
        transform: translateY(-105%);
      }

      &.is-visible {
        transform: translateY(0);
      }
    }
  }
</style>
