export const overridePaginationClasses = () => {
  $.fn.dataTable.ext.classes = Object.assign(
    $.fn.dataTable.ext.classes,
    {
      sPageButton: 'pagination__button',
      sPageButtonActive: 'is-active',
      sPageButtonDisabled: 'is-disabled',
      sPaging: 'pagination__holder paging_', /* Note that the type is postfixed */
    }
  );
};

export const customizeDOMPaginationClasses = () => {
  const $holder = $('.pagination__holder');
  const $buttonPrevious = $holder.children('.previous');
  const $buttonNext = $holder.children('.next');
  $buttonPrevious.addClass('pagination__button--previous');
  $buttonNext.addClass('pagination__button--next');
  const $span = $holder.children('span');
  $span.addClass('pagination__nav');
  $span.children('a').removeClass('pagination__button').addClass('pagination__link');
  const $ellipsis = $span.children('span.ellipsis');
  $ellipsis.addClass('pagination__link is-disabled');
};
