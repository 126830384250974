const spotsCounter = (place, alternativeSpotsName) => {
  const assignedCount =
    place.assignments === null ? 0 : place.assignments.length;
  const totalSpotsCount = place.spots;
  if (alternativeSpotsName) {
    return `${alternativeSpotsName}: <span class="${assignedCount === totalSpotsCount ? 'is-danger' : 'is-success'}"> ${assignedCount}/${totalSpotsCount} </span>`;
  }
  return `${assignedCount}/${totalSpotsCount}`;
};

const placeNameWithSpots = (place) => {
  const counter = spotsCounter(place);
  return `${place.attributes['Place Name']} (${counter})`;
};

const hasAvailableSpots = (place, spotsNecessary = 1) => {
  const assignedCount = (place.assignments === null) ? 0 : place.assignments.length;
  const totalSpotsCount = place.spots;
  const totalMinusAssigned = totalSpotsCount - assignedCount;
  return (totalMinusAssigned >= spotsNecessary);
};

export { spotsCounter, placeNameWithSpots, hasAvailableSpots };
