import I18n from 'i18n-js/index.js.erb';
import displayDatatablesPagination from '../shared/datatables_pagination';
import { withSaveState } from '../shared/datatables_state';
import SidePanel from './sidepanel';
import MobileDropdown from './mobile_dropdown';
import {
  overridePaginationClasses,
  customizeDOMPaginationClasses
} from '../../datatables/customization-v2';
import {
  setup as tableSetup,
  setupPaginationOnDOM,
  customizePageLength,
  defaultTableOptions
} from '../../datatables/table-setup';

const exportedContentFormat = {
  body(data, _row, _column, node) {
    return node.dataset.display || data;
  }
};

const setupExportButtons = (table) => {
  const tableButtons = new $.fn.dataTable.Buttons(table, {
    buttons: [
      {
        extend: 'print',
        className: 'js-print-button',
        title() {
          return $('head title').text();
        },
        customize(win) {
          const classes =
            'table__data table__data--tiny js-checkbox-row table__header table__header--tiny is-print-hidden';
          const $body = $(win.document.body);
          $body
            .find('table.print-orders-table')
            .removeClass('is-hidden');
          $body
            .find('th, td')
            .removeClass(classes);
        },
        exportOptions: {
          format: exportedContentFormat,
          modifier: {
            page: 'all',
            search: 'applied'
          },
          columns: ':not(.not-export)'
        }
      },
      {
        extend: 'csv',
        className: 'js-csv-button',
        exportOptions: {
          format: exportedContentFormat,
          modifier: {
            page: 'all',
            search: 'applied'
          },
          columns: ':not(.not-export)'
        }
      },
      {
        extend: 'copyHtml5',
        className: 'js-copy-to-clipboard-button',
        title: null,
        exportOptions: {
          format: exportedContentFormat,
          modifier: {
            page: 'all',
            search: 'applied'
          },
          columns: ':not(.not-export)'
        }
      }
    ]
  });

  $('.js-print-export-print').click((event) => {
    event.preventDefault();
    table.button('.js-print-button').trigger();
  });

  $('.js-print-export-csv').click((event) => {
    event.preventDefault();
    table.button('.js-csv-button').trigger();
  });

  $('.js-print-export-clipboard').click((event) => {
    event.preventDefault();
    table.button('.js-copy-to-clipboard-button').trigger();
  });

  return tableButtons;
};

const OrdersTable = {
  start() {
    const $table = $('.orders-table:not(.dataTable)');
    if (!$table.length) {
      return;
    }
    const $printTable = $('.print-orders-table:not(.dataTable)');
    this.setupDataTable($table, $printTable);
  },

  setupDataTable($table, $printTable) {
    overridePaginationClasses();
    const printTableOptions = {
      dom: 't',
      paging: false,
      order: [[2, 'desc']],
      columnDefs: [
        { targets: [2, 5], searchable: false },
        { targets: '_all', searchable: true }
      ]
    };
    const { processing, serverSide, source, columns } = $table.data();
    const tableOptions = {
      ...defaultTableOptions,
      lengthMenu: [[10, 50, 100, -1], [10, 50, 100, 'All']],
      ajax: { url: source },
      pagingType: 'full_numbers',
      processing,
      serverSide,
      columns,
      order: [[2, 'desc']],
      dom: 'rtp',
      columnDefs: [
        { targets: [0], orderable: true, width: '7%' }
      ],
      drawCallback() {
        customizePageLength(this);
        customizeDOMPaginationClasses();
        displayDatatablesPagination(this);
        MobileDropdown.hookEvents($table);
      },
      initComplete: () => {
        setupPaginationOnDOM();
        this._initialSearch($table);
      },
      language: {
        ...defaultTableOptions.language,
        zeroRecords:
          '<span class="alert alert--info">Sorry, no matching Orders were found</span>',
        emptyTable: '<span class="alert alert--info">No Orders</span>'
      }
    };
    const tableOptionsWithSaveState = withSaveState($table, tableOptions);
    const table = $table.DataTable(tableOptionsWithSaveState);
    const printTable = $printTable.DataTable(printTableOptions);
    this._watchTableChanges(table, printTable);
    setupExportButtons(printTable);
    table.on('draw', this._updateItemsCounter.bind(this));
    $table.find('tbody').on('click', '.js-expand-order-items', this._toggleOrderItemsDetails);
    $table.find('tbody').on('click', '[data-sidepanel]', SidePanel.loadAndShow.bind(SidePanel));
    tableSetup(table);
  },

  _initialSearch($table) {
    setTimeout(() => {
      const searchValue = $table.closest('.js-orders-table-container').find('.js-search-input').val() || '';
      if (searchValue !== '') {
        const table = $table.dataTable().api();
        table.search(searchValue).draw();
      }
    }, 10);
  },

  _updateItemsCounter(event) {
    const $table = $(event.currentTarget);
    const tableApi = $table.dataTable().api();
    const { recordsTotal, recordsDisplay } = tableApi.page.info();
    const $totalItems = $table.closest('.js-orders-table-container').find('[data-total-items]');
    const totalItemsLabel = I18n.t('my_events.orders.orders_table.items', { count: recordsTotal });
    if (recordsDisplay === recordsTotal) {
      $totalItems.text(totalItemsLabel);
    } else {
      $totalItems.text(`${recordsDisplay} of ${totalItemsLabel}`);
    }
  },

  _watchTableChanges(table, printTable) {
    table.on('draw.dt', () => {
      const searchValue = $('.js-search-input').val();
      printTable.order(table.order()).search(searchValue).draw();
    });
  },

  _toggleOrderItemsDetails(event) {
    event.preventDefault();

    const $td = $(event.currentTarget);
    const $tr = $td.closest('tr');
    const table = $td.closest('table').DataTable();
    const row = table.row($tr);
    const $arrowUp = $td.find('.js-arrow-up');
    const $arrowDown = $td.find('.js-arrow-down');

    if ($arrowDown.hasClass('is-hidden')) {
      $arrowDown.removeClass('is-hidden');
      $arrowUp.addClass('is-hidden');
      row.child.hide();
    } else {
      $arrowDown.addClass('is-hidden');
      $arrowUp.removeClass('is-hidden');
      const content = $td.find('.js-order-items-table').html();
      row.child(content).show();
      const $childRow = $(row.child());
      $childRow.find('td').addClass('table__data table__data--merged');
      $childRow.addClass('table__row table__row--merged');
    }
  }
};

export default OrdersTable;
