<template>
  <div>
    <fieldset>
      <datepicker-with-timezone
        :initial-time="startDatetimeMoment"
        :initial-timezone="initialStartTimezone"
        :datetime-field-name="startDatetimeFieldName"
        :timezone-field-name="startTimezoneFieldName"
        :required="required"
        :disabled="isDisabled"
        @change="setStartDatetimeMoment($event)"
        @change:timezone="setStartTimezone($event)"
        :row-label="t('start')"
      >
      </datepicker-with-timezone>
    </fieldset>
    <fieldset v-bind:class="{ field_with_errors: endDatetimeErrors.length > 0 }">
      <datepicker-with-timezone
        :initial-time="endDatetimeMoment"
        :initial-timezone="initialEndTimezone"
        :datetime-field-name="endDatetimeFieldName"
        :timezone-field-name="endTimezoneFieldName"
        :required="required"
        :disabled="isDisabled"
        @change="setEndDatetimeMoment($event)"
        @change:timezone="initialEndTimezone = $event"
        :row-label="t('end')"
      >
      </datepicker-with-timezone>
      <div v-for="error in endDatetimeErrors" :key="error" class="error">{{error}}</div>
    </fieldset>
  </div>
</template>


<script>
import DatepickerWithTimezone from "./datepicker_with_timezone.vue";
import moment from 'moment';
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) =>
  I18n.t(key, {
    scope: 'js.datepicker_range_with_timezone',
    ...opts,
  });

export default {
  props: [
    'startDatetimeFieldName',
    'endDatetimeFieldName',
    'startTimezoneFieldName',
    'endTimezoneFieldName',
    'startDatetime',
    'endDatetime',
    'startTimezone',
    'endTimezone',
    'endDatetimeError',
    'disabled',
    'required'
  ],

  data: function() {
    return {
      initialStartTimezone: this.startTimezone,
      initialEndTimezone: this.endTimezone,
      isDisabled: String(this.disabled) === "true",
      startDatetimeMoment: moment(this.startDatetime),
      endDatetimeMoment: moment(this.endDatetime),
      endDatetimeErrors: JSON.parse(this.endDatetimeError)
    }
  },

  components: {
    DatepickerWithTimezone
  },

  methods: {
    t,

    setStartDatetimeMoment(value) {
      value = moment(value);
      var changeDifference = value.diff(this.startDatetimeMoment, "minutes");
      var endString = moment(this.endDatetimeMoment).add(changeDifference, "minutes").toISOString();
      this.setEndDatetimeMoment(endString);
      this.startDatetimeMoment = value;
    },

    setEndDatetimeMoment(value) {
      value = moment(value);
      this.endDatetimeMoment = value;
    },

    setStartTimezone(value) {
      if (this.initialStartTimezone === this.initialEndTimezone) {
        this.initialEndTimezone = value;
      }
      this.initialStartTimezone = value;
    }
  }
}
</script>
