<template>
  <div>
    <div class="sidepanel__block">
      <h6 class="subtitle">{{t('select_event')}}</h6>
      <hr>
      <fieldset>
        <select v-model="selected" class="select select--full">
          <option
            v-for="option in events"
            v-bind:value="option.value"
            :key="option.id"
          >{{ option.name_with_start_date }}</option>
        </select>
        <input v-model="selected" name="event[copy_from_id]" type="hidden">
      </fieldset>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">{{t('event_name')}}</h6>
      <hr>
      <fieldset>
        <input
          v-model="eventName"
          ref="eventName"
          maxlength="50"
          size="50"
          type="text"
          name="event[name]"
          id="event_name"
          class="input input--full input-max-length"
        >
        <div v-for="error in eventNameError" :key="error" class="error">{{error}}</div>
      </fieldset>
    </div>

    <div class="sidepanel__block event-start">
      <h6 class="subtitle">{{t('start_and_end')}}</h6>
      <hr>
      <fieldset>
        <input type="hidden" :disabled="isCustomStartTime" name="event[start_date_time]" v-model="fixedStartTime">
        <input type="hidden" :disabled="isCustomStartTime" name="event[time_zone]" v-model="eventInitialTimezone">

        <input class="radio" type="radio" id="advance_day" value="day" v-model="selectedStartTime">
        <label :class="{ 'is-hidden': !isFutureAfterA('day') }" class="voidclick" for="advance_day">{{t('plus_one_day')}}</label>

        <input class="radio" type="radio" id="advance_week" value="week" v-model="selectedStartTime">
        <label :class="{ 'is-hidden': !isFutureAfterA('week') }" class="voidclick" for="advance_week">{{t('plus_one_week')}}</label>

        <input class="radio" type="radio" id="advance_month" value="month" v-model="selectedStartTime">
        <label :class="{ 'is-hidden': !isFutureAfterA('month') }" class="voidclick" for="advance_month">{{t('plus_one_month')}}</label>

        <input class="radio" type="radio" id="advance_custom" value="custom" v-model="selectedStartTime">
        <label for="advance_custom" class="voidclick">{{t('custom')}}</label>

        <span :class="{ 'is-hidden': isCustomStartTime }" class="alert alert--info" role="alert">{{eventStartTimeFormatted}}</span>

        <datepicker-with-timezone
          v-if="isCustomStartTime"
          :row-label="t('start')"
          :initial-time="customInitialStartTime"
          :initial-timezone="eventInitialTimezone"
          datetime-field-name="event[start_date_time]"
          timezone-field-name="event[time_zone]"
          required="true"
        />
        <div v-for="error in eventStartError" :key="error" class="error">{{error}}</div>
      </fieldset>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">{{t('location')}}</h6>
      <hr>
      <fieldset>
        <location-autocomplete
          :initialLocation="location"
          :initialLocationName="locationName"
          :initialLocationCaption="locationCaption"
          :initialLocationLat="locationLat"
          :initialLocationLng="locationLng"
          :errors="locationError"
          resourceName="event"
        ></location-autocomplete>
      </fieldset>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">{{t('6_publish')}}</h6>
      <hr>
      <fieldset>
        <label for="event_copy_publish_event">{{t('publish_event')}}</label>
        <input v-model="publishOption" type="radio" value="no" checked="checked" name="event[publish]" id="event_copy_publish_no" class="radio">
        <label class="voidclick" for="event_copy_publish_no">{{t('schedule_no')}}</label>
        <input v-model="publishOption" type="radio" value="yes" name="event[publish]" id="event_copy_publish_yes" class="radio">
        <label class="voidclick" for="event_copy_publish_yes">{{t('schedule_yes')}}</label>
        <input v-model="publishOption" type="radio" value="scheduled" name="event[publish]" id="event_copy_publish_scheduled" class="radio">
        <label class="voidclick" for="event_copy_publish_scheduled">{{t('schedule_on')}}</label>
        <div class="publish-copy-datepicker" :class="{ 'is-hidden': publishOption != 'scheduled' }">
          <datepicker-with-timezone
            :initial-time="customInitialStartTime"
            :initial-timezone="eventInitialTimezone"
            datetime-field-name='event[publish_at]'
            timezone-field-name='event[publish_time_zone]'
            required="false"
          ></datepicker-with-timezone>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import datepickerWithTimezone from "./datepicker_with_timezone.vue";
import locationAutocomplete from "./location_autocomplete.vue";
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) => I18n.t(key, {
  scope: 'events.form_from_event',
  ...opts
});
export default {
  props: [
    "initialTime",
    "copyFromId",
    "events",
    "eventStartError",
    "eventNameError",
    "locationError"
  ],

  data() {
    return {
      publishOption: "no",
      selected: null,
      selectedStartTime: "day",
      eventName: null,
      eventInitialTime: null,
      eventInitialTimezone: null,
      location: "",
      locationName: "",
      locationCaption: "",
      locationLat: null,
      locationLng: null
    };
  },

  components: {
    datepickerWithTimezone,
    locationAutocomplete
  },

  mounted() {
    this.$nextTick(() => {
      this.selected = this.copyFromId ? parseInt(this.copyFromId, 10) : (this.events[0] ? this.events[0].value : null);
      this.updateCopyEvent();
    });
  },

  methods: {
    t,
    updateCopyEvent() {
      if (!this.selected) {
        this.eventInitialTime = moment();
        return;
      }
      this.eventName = `Copy of ${this.selectedEvent.name}`;
      this.eventInitialTimezone = this.selectedEvent.timezone;
      this.location = this.selectedEvent.location;
      this.locationName = this.selectedEvent.location_name;
      this.locationCaption = this.selectedEvent.location_caption;
      this.locationLat = this.selectedEvent.location_lat;
      this.locationLng = this.selectedEvent.location_lng;
      this.selectedStartTime = this.minimalValidPeriod();
      this.eventInitialTime = moment(this.selectedEvent.start_date_time).add(1, this.selectedStartTime).format("YYYY-MM-DDTHH:mm:ss");
      this.$nextTick(() => $(this.$refs.eventName).change());
    },

    minimalValidPeriod() {
      if (this.isFutureAfterA("day")) {
        return "day";
      } else if (this.isFutureAfterA("week")) {
        return "week";
      } else if (this.isFutureAfterA("month")) {
        return "month";
      } else {
        return "custom"
      }
    },
    isFutureAfterA(period) {
      if (!this.selectedEvent) return false;

      return moment(this.selectedEvent.start_date_time).add(1, period).isAfter(moment());
    }
  },

  watch: {
    selected() {
      this.updateCopyEvent();
    },

    selectedStartTime(val) {
      if (val === "custom") return;

      this.eventInitialTime = moment(this.selectedEvent.start_date_time).add(1, val).format("YYYY-MM-DDTHH:mm:ss");
    }
  },

  computed: {
    customInitialStartTime() {
      if (!this.selectedEvent) return;

      if (this.initialTime) {
        return moment(this.initialTime).format("YYYY-MM-DDTHH:mm:ss");
      } else {
        const now = moment();
        const year = now.get("year");
        const month = now.get("month");
        const day = now.get("date");
        return moment(this.selectedEvent.start_date_time).year(year).month(month).date(day).add(1, "day").format("YYYY-MM-DDTHH:mm:ss");
      }
    },
    selectedEvent() {
      return this.events.find(event => event.value === this.selected);
    },
    startDateTime() {
      if (!this.selectedEvent) return;

      return moment(this.selectedEvent.start_date_time).add(1, this.selectedStartTime);
    },
    eventStartTimeFormatted() {
      if (!this.selectedEvent) return "";

      const startDateTime = this.startDateTime.format("MMM DD, YYYY - LT");
      const timezone = this.selectedEvent.timezone;
      return `${startDateTime} ${timezone}`;
    },
    fixedStartTime() {
      return this.eventInitialTime;
    },
    isCustomStartTime() {
      return this.selectedStartTime === "custom";
    }
  }
};
</script>
