<template>
  <fieldset :id="fieldsetId" ref="fieldset">
    <label class="label" v-if="rowLabel">
      <abbr v-show="required" title="required">*</abbr>
      {{ rowLabel }}
    </label>
    <div class="datepicker__fields">
      <div class="datepicker__mask" v-show="!inlineEditMode">
        <input
          type="hidden"
          :disabled="isDisabled"
          :name="datetimeFieldName"
          :value="inputTimeMoment"
        />
        <datepicker
          ref="datepickerInput"
          class="datepicker-vue input input--invisible no-space-out no-space-in datepicker__input"
          format="D M/d/yyyy"
          v-model="formattedDate"
          v-show="!inlineEditMode"
          @closed="inlineEditMode = true"
          :disabled="datepickerDisabledOption"
        ></datepicker>
        <span class="datepicker__icon" v-bind:class="{ 'is-disabled': isDisabled }"></span>
      </div>
      <div class="datepicker__mask" @click="openDatepicker" v-show="inlineEditMode">
        <input
          :disabled="isDisabled"
          ref="formattedDateInput"
          class="input input--invisible no-space-out no-space-in datepicker__input"
          v-model.lazy="formattedDate"
          type="text"
        />
        <span class="datepicker__icon" v-bind:class="{ 'is-disabled': isDisabled }"></span>
      </div>
      <input
        :disabled="isDisabled"
        class="input datepicker__input datepicker__input--time"
        v-model.lazy="formattedTime"
        type="text"
      />
      <select
        :disabled="isDisabled"
        v-if="timezoneFieldName"
        :name="timezoneFieldName"
        class="select select--full datepicker__select"
        v-model="timezone"
      >
        <option disabled value>Please select one</option>
        <option
          v-for="option in timezonesOptions"
          v-bind:value="option.value"
          :data-identifier="option.identifier"
          :key="option.value"
          >{{ option.text }}</option
        >
      </select>
    </div>
  </fieldset>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import moment from 'moment-timezone';
import { timezones } from '../constants/timezones';

export default {
  props: [
    'fieldsetId',
    'datetimeFieldName',
    'timezoneFieldName',
    'initialTime',
    'initialTimezone',
    'disabled',
    'required',
    'rowLabel',
    'mustBeHigherThan'
  ],

  data: function() {
    return {
      timeMoment: moment(this.initialTime),
      timezone: this.initialTimezone,
      inlineEditMode: true,
      isDisabled: String(this.disabled) === 'true'
    };
  },

  components: {
    Datepicker
  },

  watch: {
    initialTime: function(val, oldVal) {
      if (this.timeMomentIsValid(val)) {
        this.timeMoment = moment(val);
      } else {
        this.timeMoment = moment(oldVal);
        this.$emit('change', this.timeMoment.toISOString());
      }
    },

    timezone: function(val, _oldVal) {
      this.timezone = val;
      this.$emit('change:timezone', val);
      this.triggerChangeTimeEvent();
    },

    initialTimezone: function(val, _oldVal) {
      this.timezone = val;
    },
    disabled(val, oldVal) {
      this.isDisabled = val;
    }
  },

  methods: {
    openDatepicker() {
      if (this.isDisabled) {
        return;
      }
      this.inlineEditMode = !this.inlineEditMode;
      var input = this.$refs.datepickerInput.$el.querySelector('input');
      this.$nextTick(() => input.click());
    },

    timeMomentIsValid(value) {
      return this.mustBeHigherThan == null || value > this.mustBeHigherThan;
    },

    triggerChangeTimeEvent() {
      const timeMomentWithTimezone = moment.tz(this.timeMoment.format('YYYY-MM-DDTHH:mm:ss'), this.timezoneIdentifier);
      $(this.$refs.fieldset).trigger('change:time', [timeMomentWithTimezone]);
    }
  },

  computed: {
    datepickerDisabledOption: function() {
      if (this.mustBeHigherThan) {
        var previousDay = moment(this.mustBeHigherThan).subtract(1, 'days');
        var timeMoment = this.timeMoment;
        var mustBeHigherThan = this.mustBeHigherThan;
        return {
          customPredictor: function(date) {
            var possibleValue = moment(date).set({
              hour: timeMoment.get('hour'),
              minute: timeMoment.get('minute')
            });
            return possibleValue.isBefore(mustBeHigherThan);
          }
        };
      } else {
        return false;
      }
    },

    formattedTime: {
      get: function() {
        return this.timeMoment.format('LT');
      },

      set: function(val) {
        var val = moment(val, 'LT');
        if (!val.isValid()) {
          this.$forceUpdate();
          return;
        }
        this.timeMoment = moment(this.timeMoment).set({
          hour: val.get('hour'),
          minute: val.get('minute')
        });
        this.$emit('change', this.timeMoment.toISOString());
        this.triggerChangeTimeEvent();
        this.$forceUpdate();
      }
    },

    formattedDate: {
      get: function() {
        return this.timeMoment.format('ddd l');
      },

      set: function(val) {
        var newValue = moment(val, 'l');
        var nextPossibleValue = moment(newValue).set({
          hour: this.timeMoment.get('hour'),
          minute: this.timeMoment.get('minute')
        });
        if (!newValue.isValid() || !this.timeMomentIsValid(nextPossibleValue)) {
          this.$forceUpdate();
          return false;
        }
        this.timeMoment = moment(this.timeMoment).set({
          year: newValue.get('year'),
          month: newValue.get('month'),
          date: newValue.get('date')
        });
        this.$emit('change', this.timeMoment.toISOString());
        this.triggerChangeTimeEvent();
        this.inlineEditMode = true;
        const input = this.$refs.formattedDateInput;
        this.$nextTick(() => $(input).change());
      }
    },

    inputTimeMoment: {
      get: function() {
        return this.timeMoment.format('YYYY-MM-DDTHH:mm:ss');
      }
    },

    timezoneIdentifier: {
      get() {
        return timezones.find(timezone => timezone.value == this.timezone).identifier;
      }
    },

    timezonesOptions: {
      get() {
        return timezones;
      }
    }
  }
};
</script>

<style lang="scss">
@import '~unicorn-design-system/css/apps/settings/colors';

.datepicker {
  &__fields {
    display: flex;

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  &__select:disabled,
  &__icon.is-disabled {
    cursor: not-allowed;
  }

  &__mask {
    align-items: center;
    background-color: $input-color;
    border: 1px solid $whisper;
    border-radius: 4px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 0 13px;
    position: relative;

    .field_with_errors & {
      border: 1px solid $red-hex;
    }

    &,
    input:not([disabled]) {
      cursor: pointer;
    }

    &:hover {
      border-color: #959ca6;
    }

    input {
      max-height: 100%;
      max-width: 100px;
    }

    @media screen and (min-width: 480px) {
      margin-right: 0.75rem;
    }
  }

  &-vue input {
    border: 0;
    margin: 0;
    max-height: 38px;
    padding: 0;
  }

  &__mask,
  &-vue input,
  &__input,
  &__select {
    @media screen and (max-width: 480px) {
      width: 100%;
      margin-left: 0 !important;
    }
  }

  &__select {
    flex: 0 1 auto;

    @media screen and (min-width: 481px) {
      max-width: 35.5%;
    }
  }

  &__input--time {
    @media screen and (min-width: 480px) {
      max-width: 90px;
    }
  }

  &__icon {
    background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20123%20125%22%3E%3Cpath%20d%3D%22M110.59%20125H12.41A12.426%2012.426%200%200%201%200%20112.587V25.7a12.424%2012.424%200%200%201%2012.41-12.41h12.927V6.033A6.03%206.03%200%200%201%2031.364%200h.957a6.03%206.03%200%200%201%206.03%206.03v7.26H84.5V6.033A6.03%206.03%200%200%201%2090.527%200h.956a6.03%206.03%200%200%201%206.028%206.03v7.26h13.08A12.424%2012.424%200%200%201%20123%2025.7v86.885A12.426%2012.426%200%200%201%20110.59%20125zm-1.618-81.665H14.028v67.634h94.944V43.33zM46.254%2084.4a2.29%202.29%200%200%200%201.953.915%202.71%202.71%200%200%200%202.04-.793%202.87%202.87%200%200%200%20.768-2.087%202.84%202.84%200%200%200-.9-2.27%203.842%203.842%200%200%200-2.588-.78q-.366%200-.562.01c-.13.01-.244.013-.342.013v-7.223h.537a4.138%204.138%200%200%200%202.478-.623%202.146%202.146%200%200%200%20.842-1.843%202.1%202.1%200%200%200-.562-1.517%201.908%201.908%200%200%200-1.44-.586%201.666%201.666%200%200%200-1.416.646%203.058%203.058%200%200%200-.488%201.868H34.78q.1-5.86%203.65-8.886t10.34-3.027q6.053%200%209.728%202.77a8.653%208.653%200%200%201%203.674%207.24%206.97%206.97%200%200%201-5.1%207.2%208.03%208.03%200%200%201%204.8%202.844%208.757%208.757%200%200%201%201.648%205.506%2010.078%2010.078%200%200%201-4%208.373q-4%203.124-10.79%203.124-7.277%200-11.39-3.59t-4.114-9.91v-.1H45.5a4.86%204.86%200%200%200%20.754%202.722zm44.214%209.924H77.626V69.768H72.28v-10.84h18.188v35.4z%22%20fill%3D%22%236B7C93%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: center;
    bottom: 0;
    height: 16px;
    width: 16px;
  }
}

.vdp-datepicker__calendar {
  background: $white !important;
  border-color: $white-to-darker-color !important;
  border-radius: 4px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  left: -15px;
  padding: 0.5rem;
  top: 55px;

  .cell {
    border-radius: 4px;

    &:not(.disabled):hover {
      background-color: $sky-hex !important;
      border-color: transparent !important;
      color: $black-hex !important;
    }
  }

  .selected,
  .selected:hover {
    background-color: $blue-hex !important;
    color: $white-hex !important;
  }
}
</style>
