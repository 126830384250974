import { Turbo } from '@hotwired/turbo-rails';

const TableRowLink = {
  start() {
    $('tr[data-href]').on('click', this.navigate);
  },
  navigate(e) {
    if (e.target.tagName === 'TD' || e.target.classList.contains('js-row-link')) {
      e.preventDefault();
      Turbo.visit(e.currentTarget.dataset.href);
    }
  }
};

export default TableRowLink;
