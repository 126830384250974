import URL from 'url';
import sweetAlert from 'sweetalert';

const EmailConfirmationMessage = {
  start() {
    const url = URL.parse(document.location.href);
    const result = url.path.match(/sign_in\?confirmation=(.*)/);
    if (result && result.length > 1) {
      this.showDialog(decodeURIComponent(result[1]));
    }
  },

  showDialog(email) {
    sweetAlert({
      title: 'Thank you for signing up for Eventene!',
      text: `Please check your email and click "Confirm my account" in the message we just sent to ${email}\n\nOnce your account is activated, you'll be able to Log In and start planning and attending Events.`,
      icon: 'success'
    });
  }
};

export default EmailConfirmationMessage;
