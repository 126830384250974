import ProductTour from '../vendor/product-tour';

const ProductTours = {
  _instance: null,
  start() {
    const tourData = $('.product-tour:first').data() || {};
    const { autoStart, name } = tourData;
    if (!name) {
      this._instance = null;
      return;
    }
    this._instance = this.initialize(tourData);
    if (autoStart) {
      this.show();
    }
  },

  initialize(tourData = {}) {
    const tour = new ProductTour({
      overlay: true, // optional (true || false) defaults: true
      onStart() {
        $('main > *').css('zIndex', 'auto');
      }, // called when tour starts || optional
      onChanged() {}, // called when tour changes || optional
      onClosed() {
        $('main > *').css('zIndex', '');
        if (tourData.saveUrl && tourData.autoStart) {
          $.ajax({
            url: tourData.saveUrl,
            method: 'POST',
            data: { tour: { name: tourData.name } }
          });
        }
      }, // called when the tour has been closed || optional
      next: '', // optional defaults: 'Next'
      prev: '', // optional defaults: 'Previous'
      of: '' // optional defaults: 'of'
    });

    tour.steps(tourData.steps || []);
    return tour;
  },

  show() {
    if (ProductTours._instance) {
      ProductTours._instance.startTour(); // initialize the tour
    }
  },

  isActive() {
    return Boolean(ProductTours._instance);
  }
};

export default ProductTours;
