import SidePanel from './sidepanel';

const CustomFieldsRank = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      const $rankWrapper = $sidepanel.find('.list-item-rank-wrapper');
      if ($rankWrapper.length) {
        $rankWrapper.each((_i, rankWrapper) => {
          this._setupRankWidget($(rankWrapper));
        });
      }
    });
  },

  _setupRankWidget($rankWrapper) {
    $rankWrapper
      .find('select')
      .change({ $rankWrapper }, function(e) {
        const selectedValue = $(this).find('option:selected').val();
        const otherSelects = e.data.$rankWrapper.find('select').not($(this));
        otherSelects.find('option:selected').each((_, selectedOption) => {
          const $selectedOption = $(selectedOption);
          if (selectedValue === $selectedOption.val()) {
            $selectedOption.parent().prop('selectedIndex', 0);
          }
        });
      });
  }
};

export default CustomFieldsRank;
