import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = ['productsSelect', 'quantitySelect', 'totalPrice'];

  connect() {
    this.locale = document.documentElement.lang;
    this.currency = this.totalPriceTarget.dataset.currency;
    this.handleProductChange();
  }

  handleProductChange() {
    const option = this.productsSelectTarget.options[this.productsSelectTarget.selectedIndex];
    const priceCents = parseFloat(option.dataset.priceCents) || 0.0;
    const quantity = parseInt(this.quantitySelectTarget.value, 10) || 0;
    const price = priceCents * quantity / 100;
    this.totalPriceTarget.innerHTML = this.priceFormatter(price);
  }

  priceFormatter(price) {
    return new Intl.NumberFormat(this.locale,
      { style: 'currency', currency: this.currency }
    ).format(price);
  }
}
