import SidePanel from './sidepanel';

const MobileDropdown = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      this.hookEvents($sidepanel);
    });
    this.hookEvents($(document));
  },

  hookEvents($parent) {
    $parent
      .find('.actionbar:not(.hooked-actionbar)')
      .addClass('hooked-actionbar')
      .on('click', this._toggleActionBar);

    $parent
      .find('.dropdown:not(.hooked-mobile-dropdown)')
      .addClass('hooked-mobile-dropdown');

    $(document).on('mouseenter', '.hooked-mobile-dropdown', this._activate.bind(this));
    $(document).on('mouseleave', '.hooked-mobile-dropdown', this._deactivate.bind(this));
    $(document).on('click', '.hooked-mobile-dropdown', this._handleDropdownClick);
  },

  _activate(e) {
    const $target = $(e.currentTarget);
    setTimeout(() => this._hookClick($target), 0);
    if ($target.hasClass('is-clickable')) {
      return;
    }
    $target.addClass('is-active');
  },

  _deactivate(e) {
    const $target = $(e.currentTarget);
    $target.removeClass('is-active');
    this._unhookClick($target);
  },

  _hookClick($target) {
    $target.find('.dropdown__button').on('click', this._toggleDropdown);
    $target.find('.dropdown__holder > .button').on('click', this._toggleDropdown);
  },

  _unhookClick($target) {
    $target.find('.dropdown__button').off('click', this._toggleDropdown);
    $target.find('.dropdown__holder > .button').off('click', this._toggleDropdown);
  },

  _toggleDropdown(e) {
    e.preventDefault();
    e.stopPropagation();
    $(e.currentTarget)
      .parents('.dropdown:first')
      .toggleClass('is-active');
  },

  _toggleActionBar(e) {
    if (e.target.tagName !== 'INPUT') {
      $(e.currentTarget).toggleClass('is-active');
    }
  },

  _handleDropdownClick(e) {
    // avoids stop propagation if click is inside a dropdown list
    if (!$(e.target).parents('.dropdown__list').length) {
      e.stopPropagation();
    }
  }
};

export default MobileDropdown;
