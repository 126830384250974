import SidePanel from './sidepanel';

const PrintOnClick = {
  start() {
    $('.print-onclick').click(this.windowPrint);
    $('.print-payments-received').click(this.printPaymentsReceived.bind(this));
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('.print-onclick').click(this.windowPrint);
    });
  },

  windowPrint() {
    window.print();
    return false;
  },

  printPaymentsReceived() {
    $('table.table').addClass('is-print-hidden');
    return this.windowPrint();
  }
};

export default PrintOnClick;
