<template>
  <div>
    <div class="sidepanel__block">
      <h6 class="subtitle subtitle--center">{{ t('manage_tabs') }}</h6>
      <hr />
      <fieldset>
        <div class="form__group form__group--unbreak">
          <div class="form__field form__field--unbreak has-full-width">
            <label for="SectionList">{{ t('tabs_list') }}</label>
            <select id="SectionList" v-model="selected" class="select select--full">
              <option :value="field" :key="field.uid" v-for="field in eventDescriptions">
                {{ field.name }}
              </option>
            </select>
          </div>
          <div class="form__field form__field--unbreak form__field--auto form__field--no-label">
            <button
              :disabled="!canAddNewDescription"
              class="button button--light-turquoise button--circle no-space-out tooltip"
              :data-tooltip="t('new_tab')"
              @click.prevent.stop="newDescription"
            >
              <svg class="icon button__icon icon--white">
                <use :xlink:href="iconUrl('icon-plus')"></use>
              </svg>
            </button>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <label for="tabValueDisplay">{{ t('tab_name') }}</label>
        <input
          id="tabValueDisplay"
          type="text"
          for="emptyTab"
          :placeholder="t('edit_tab_name')"
          class="input input--full"
          v-model="selected.name"
        />
      </fieldset>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle subtitle--center">{{ t('tabs_order') }}</h6>
      <hr />
      <ul class="list list--bordered">
        <li
          class="list__item list__item--tighter"
          :class="{ 'is-hidden': isDeleted(field) }"
          :key="field.uid"
          v-for="(field, index) in eventDescriptions"
        >
          <input
            type="hidden"
            :value="field.id"
            :name="fieldName(index, 'id')"
            v-if="field['id']"
          />
          <input type="hidden" :value="field.name" :name="fieldName(index, 'name')" />
          <input type="hidden" :value="field.visible" :name="fieldName(index, 'visible')" />
          <input type="hidden" :value="index * 1000" :name="fieldName(index, 'row_order')" />
          <input
            type="hidden"
            :value="field['_destroy']"
            :name="fieldName(index, '_destroy')"
            v-if="field['_destroy'] == '1'"
          />

          <span class="list__block list__block--tighter">
            <div class="list__content has-full-width">
              <label class="switch switch--start no-space-out" onclick="">
                <input type="checkbox" class="switch__el" v-model="field.visible" />
                <span class="list__text switch__text switch__text--treeview">{{ field.name }}</span>
                <svg class="icon icon--tiny icon--gray switch__icon">
                  <use :xlink:href="iconUrl('icon-eye')"></use>
                </svg>
              </label>
            </div>
            <div class="dropdown dropdown--floating dropdown--table">
              <div class="dropdown__holder">
                <a href="#" class="button button--circle button--transparent no-space-out noclick">
                  <svg class="icon icon--tiny icon--slategray dropdown__icon">
                    <use :xlink:href="iconUrl('icon-dots-vertical')"></use>
                  </svg>
                </a>
                <ul
                  class="
                    dropdown__list
                    dropdown__list--floating
                    dropdown__list--medium
                    dropdown__list--right
                  "
                >
                  <li class="dropdown__item">
                    <a href="#" class="dropdown__link dropdown__link--mask" @click.prevent.stop>
                      <span class="dropdown__legend dropdown__legend--mask">{{ t('move') }}</span>
                      <select
                        @change="moveDescription($event, index)"
                        class="select select--invisible no-space-out no-space-in dropdown__select"
                      >
                        <option
                          :value="optionIndex"
                          :selected="optionIndex === index"
                          :key="description.uid"
                          v-for="(description, optionIndex) in eventDescriptions"
                        >
                          {{ optionIndex + 1
                          }}{{ optionIndex == index ? ` (${t('current')})` : '' }}
                        </option>
                      </select>
                    </a>
                  </li>
                  <li class="dropdown__item">
                    <a
                      href="#"
                      class="dropdown__link"
                      @click.prevent.stop="deleteDescription(index)"
                    >
                      <span class="dropdown__legend is-danger">{{ t('delete_tab') }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import MobileDropdown from '../../application/app/mobile_dropdown';
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) =>
  I18n.t(key, {
    scope: 'js.edit_description_tabs',
    ...opts,
  });

export default {
  props: {
    initialEventDescriptions: {
      type: Array,
      required: true,
    },
    svgIconsUrl: {
      type: String,
      required: true,
    },
    descriptionTabsLimit: {
      type: Number,
      required: true,
    },
  },

  data() {
    this.initialEventDescriptions.forEach((description) => {
      description['_destroy'] = '0';
      description['uid'] = description.id;
    });
    const selected =
      this.initialEventDescriptions.length > 0 ? this.initialEventDescriptions[0] : {};
    return {
      eventDescriptions: this.initialEventDescriptions,
      selected,
    };
  },

  computed: {
    canAddNewDescription: (vm) => vm.undeletedDescripptions.length < vm.descriptionTabsLimit,
    undeletedDescripptions: (vm) => vm.eventDescriptions.filter(vm.notDeleted),
  },

  methods: {
    t,

    moveDescription($event, currentPosition) {
      const selectElement = $event.currentTarget;
      const newPosition = selectElement.selectedIndex;
      const element = this.eventDescriptions[currentPosition];
      this.eventDescriptions.splice(currentPosition, 1);
      this.eventDescriptions.splice(newPosition, 0, element);
    },

    iconUrl(name) {
      return `${this.svgIconsUrl}#${name}`;
    },

    fieldName(index, fieldName) {
      return `event[event_descriptions_attributes][${index}][${fieldName}]`;
    },

    newDescription() {
      const nextTabCounter = this.eventDescriptions.length + 1;
      this.eventDescriptions.push({
        name: `${t('tab')} ${nextTabCounter}`,
        uid: this.randomInt(),
        _destroy: '0',
        visible: true,
      });
      this.selected = this.eventDescriptions[this.eventDescriptions.length - 1];
      this.$nextTick(() => {
        const $sidepanel = $('aside.sidepanel');
        MobileDropdown.hookEvents($sidepanel);
      });
    },

    deleteDescription(index) {
      const description = this.eventDescriptions[index];
      description['_destroy'] = '1';
    },

    isDeleted(description) {
      return description['_destroy'] === '1';
    },

    notDeleted(description) {
      return !this.isDeleted(description);
    },

    randomInt() {
      return Math.floor(Math.random() * 999999) + 100000;
    },
  },
};
</script>
