import IMask from 'imask/esm/imask';
import 'imask/esm/masked/number';

import SidePanel from './sidepanel';

const MoneyMask = {
  start() {
    SidePanel.onUpdate($sidepanel => {
      const $moneyInputs = $sidepanel.find('[data-mask="money"]');
      $moneyInputs.each((_i, el) => this._maskMoney(el));
    });
    const $moneyInputs = $('[data-mask="money"]');
    $moneyInputs.each((_i, el) => this._maskMoney(el));
  },

  _handleHiddenInput(imask) {
    return () => {
      const { name } = imask.el.input.dataset;
      const hiddenInput = imask.el.input.parentElement.querySelector(`[name="${name}"]`);
      hiddenInput.value = Math.round(imask.unmaskedValue * 100);
    };
  },

  _maskMoney(el) {
    const { symbol, decimalMark, exponent, symbolFirst, name } = el.dataset;
    const mask = symbolFirst === 'true' ? `${symbol}num` : `num${symbol}`;
    const imask = IMask(el, {
      mask,
      blocks: {
        num: {
          mask: Number,
          scale: exponent,
          signed: false,
          thousandsSeparator: '',
          padFractionalZeros: true,
          normalizeZeros: true,
          radix: decimalMark,
          mapToRadix: ['.', ',']
        }
      }
    });
    if (name) {
      imask.on('accept', this._handleHiddenInput(imask));
    }
  }
};

export default MoneyMask;
