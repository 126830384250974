<template>
  <div class="form">
    <div class="sidepanel__block sidepanel__block--merged">
      <button @click="addPackage" class="button button--full button--white button--light button--stroke button--square no-space-out js-sidepanel-alt-trigger">
        <div class="button__holder">
          <span class="button__text">{{ t("new_package") }}</span>
          <svg class="icon button__icon">
            <use :xlink:href="iconPlus"></use>
          </svg>
        </div>
      </button>
    </div>

    <div class="sidepanel__block" :key="pricePosition" v-for="(price, pricePosition) in packagePrices">
      <package-price-item
        :price="price"
        :icon-trash="iconTrash"
        :icon-edit="iconEdit"
        :icon-checkmark="iconCheckmark"
        @remove-price="removePrice(pricePosition)"
      >
      </package-price-item>
    </div>

    <div class="package-prices__error-message" v-bind:class="{ 'is-visible': requestError }">
      <p v-html="t('error_message_html')"></p>
    </div>
  </div>
</template>

<script>
import packagePriceItem from './package_price_item.vue';
import { Turbo } from '@hotwired/turbo-rails';
import I18n from 'i18n-js/index.js.erb';

const t = (key, opts) => I18n.t(key, {
  scope: 'my_events.payments.package_prices_editor',
  ...opts
});

export default {
  props: {
    'initial-package-prices': String,
    'update-url': String,
    'icon-trash': String,
    'icon-edit': String,
    'icon-plus': String,
    'icon-checkmark': String,
    'redirect-on-success': String,
  },
  components: {
    packagePriceItem
  },
  data() {
    return {
      requestError: false,
      packagePrices: JSON.parse(this.initialPackagePrices)
    }
  },
  methods: {
    t,
    showRequestErrorMessage() {
      this.requestError = true;
      setTimeout(() => {
        this.requestError = false;
      }, 7000);
    },

    addPackage() {
      this.packagePrices.push({
        id: null,
        name: t("package_name"),
        active: true,
        price: "9.99",
        package_quantity: 2
      });
    },

    removePrice(pricePosition) {
      this.packagePrices.splice(pricePosition, 1);
    },

    savePackages() {
      const authenticityToken = $('meta[name="csrf-token"]')[0].content;
      let data = { items: this.packagePrices };
      this.$http.put(this.updateUrl,
        data,
        { responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticityToken
          }
        }).then(response => {
          this.requestError = false;
          this.packagePrices = response.body;
          if (this.redirectOnSuccess) {
            Turbo.visit(this.redirectOnSuccess);
          }
        }, response => {
          this.showRequestErrorMessage();
        });
    },
  },
  mounted() {
    $('[data-sidepanel-submit]').click(this.savePackages);
  }
}
</script>

<style lang="scss">

  .package-prices {
    &__error-message {
      background-color: #fbe3e4;
      box-shadow: 0 0 5px #ad141e;
      color: #ad141e;
      display: block important;
      padding: 15px;
      position: fixed;
      top: 0;
      transition: transform .2s linear;
      width: 100%;
      z-index: 99;

      * {
        display: block !important;
      }

      *:last-of-type {
        margin: 0;
      }

      &:not(.is-visible) {
        transform: translateY(-105%);
      }

      &.is-visible {
        transform: translateY(0);
      }
    }
  }
</style>
