import SidePanel from './sidepanel';

const FileUpload = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('[data-file-upload]').click(this.chooseFile.bind(this));
    });
  },

  chooseFile(e) {
    e.preventDefault();
    const $button = $(e.currentTarget);
    const inputId = $button.data('fileUpload');
    const $input = $(inputId);
    $input.on('change', function() {
      const filename = this.value.replace('C:\\fakepath\\', '');
      $button.find('.button__text').html(filename);
    });
    $input.click();
  }
};

export default FileUpload;
