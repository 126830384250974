const ToggleVisible = {
  start() {
    $('[data-toggle-visible]').click(this.toggle);
  },
  toggle(e) {
    e.preventDefault();
    const $target = $(e.currentTarget.dataset.toggleVisible);
    if ($target.hasClass('is-invisible')) {
      ToggleVisible.show($target);
    } else if ($target.hasClass('is-visible')) {
      ToggleVisible.hide($target);
    }
  },
  hide($element) {
    $element.removeClass('is-visible').addClass('is-invisible');
  },
  show($element) {
    $element.removeClass('is-invisible').addClass('is-visible');
  }
};

export default ToggleVisible;
