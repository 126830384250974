<template>
  <div class="card card--bonded card--overlap is-sticky" id="cardUnassigned" data-collapse>
    <div class="card__holder has-full-height">
      <header class="card__header card__header--dark card__header--bonded card__header--absolute">
        <span class="card__title card__title--truncate">
          <strong>{{ t('unassigned_people') }}</strong>
          <small class="card__subtext has-truncate-text"
            >{{ items.length }} {{ peopleEntityName }}</small
          >
        </span>

        <div class="dropdown dropdown--floating dropdown--tiny">
          <div :class="{ 'is-invisible': isDisabled }" class="dropdown__holder">
            <a @click.prevent href="#" class="dropdown__button no-space-in">
              <svg class="icon icon--tiny icon--slategray dropdown__icon">
                <use :xlink:href="iconDotsVerticalUrl"></use>
              </svg>
            </a>
            <ul
              class="
                dropdown__list dropdown__list--medium dropdown__list--floating dropdown__list--right
              "
            >
              <li class="dropdown__item">
                <a href="#" @click.prevent.stop="enableSelection" class="dropdown__link">
                  <span class="dropdown__legend">{{ t('select') }}</span>
                </a>
              </li>
              <li class="dropdown__item">
                <a href="#" @click.prevent.stop="selectPeopleIds(peopleIds)" class="dropdown__link">
                  <span class="dropdown__legend">{{ t('select_all') }}</span>
                </a>
              </li>
              <li class="dropdown__item">
                <a href="#" @click.prevent.stop="unselectAll" class="dropdown__link">
                  <span class="dropdown__legend">{{ t('unselect') }}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <draggable
        tag="ul"
        v-bind="{ filter: '.is-not-draggable', group: 'people', sort: false, disabled: isDisabled }"
        @start="onDragStart"
        @end="onDragEnd"
        @add="handlePeopleDrop"
        data-id="0"
        class="list list--overlap list--static list--droppable"
        :key="peopleListKey"
      >
        <li
          v-if="attendingItems.length != 0"
          class="
            is-not-draggable
            list__item list__item--regular list__item--merged list__item--tighter
            no-space-out
          "
        >
          <div class="list__block list__block--merged list__block--short">
            <span role="alert" class="alert alert--info no-space-out">{{
              t('attendees_only_message')
            }}</span>
          </div>
          <a
            @click.prevent.stop="toggleShow('attending')"
            href="#"
            class="list__block list__block--merged list__block--short"
          >
            <strong class="list__text">{{ t('attending') }} ({{ attendingItems.length }})</strong>
            <svg class="icon icon--tiny icon--slategray" :class="arrowClass(show['attending'])">
              <use :xlink:href="icon('icon-arrow-down')"></use>
            </svg>
          </a>
        </li>

        <li
          :key="item.attributes.id"
          @click.prevent.stop="handleClickOnPerson($event, item)"
          v-touch:longtap="handleLongTap(item.attributes.id)"
          :data-id="item.attributes.id"
          class="list__item list__item--regular list__item--merged list__item--tighter no-space-out"
          v-for="item in attendingVisibleItems"
        >
          <Person
            :item="item"
            :loadOnSidepanel="loadOnSidepanel"
            :requestForPeopleToPlace="requestForPeopleToPlace"
            :iconDotsVerticalUrl="iconDotsVerticalUrl"
            :selectPeopleIds="selectPeopleIds"
            :unselectPeopleIds="unselectPeopleIds"
            :places="places"
            :peopleLabels="peopleLabels"
            :isSelectable="true"
            :isSelected="isSelected"
            :isDisabled="isDisabled"
          />
        </li>

        <li
          v-if="waitlistedItems.length != 0"
          class="
            is-not-draggable
            list__item list__item--regular list__item--merged list__item--tighter
            no-space-out
          "
        >
          <a
            @click.prevent.stop="toggleShow('waitlisted')"
            href="#"
            class="list__block list__block--merged list__block--short"
          >
            <strong class="list__text">{{ t('waitlisted') }} ({{ waitlistedItems.length }})</strong>
            <svg class="icon icon--tiny icon--slategray" :class="arrowClass(show['waitlisted'])">
              <use :xlink:href="icon('icon-arrow-down')"></use>
            </svg>
          </a>
        </li>

        <li
          :key="item.attributes.id"
          @click.prevent.stop="handleClickOnPerson($event, item)"
          v-touch:longtap="handleLongTap(item.attributes.id)"
          :data-id="item.attributes.id"
          :class="{ 'is-not-draggable': attendanceLimitReached }"
          class="list__item list__item--regular list__item--merged list__item--tighter no-space-out"
          v-for="item in waitlistedVisibleItems"
        >
          <Person
            :item="item"
            :loadOnSidepanel="loadOnSidepanel"
            :requestForPeopleToPlace="requestForPeopleToPlace"
            :iconDotsVerticalUrl="iconDotsVerticalUrl"
            :selectPeopleIds="selectPeopleIds"
            :unselectPeopleIds="unselectPeopleIds"
            :places="places"
            :peopleLabels="peopleLabels"
            :isSelectable="true"
            :isSelected="isSelected"
            :isDisabled="isDisabled || attendanceLimitReached"
          />
        </li>

        <li
          v-if="notAttendingItems.length != 0"
          class="
            is-not-draggable
            list__item list__item--regular list__item--merged list__item--tighter
            no-space-out
          "
        >
          <a
            @click.prevent.stop="toggleShow('notAttending')"
            href="#"
            class="list__block list__block--merged list__block--short"
          >
            <strong class="list__text"
              >{{ t('not_attending') }} ({{ notAttendingItems.length }})</strong
            >
            <svg class="icon icon--tiny icon--slategray" :class="arrowClass(show['notAttending'])">
              <use :xlink:href="icon('icon-arrow-down')"></use>
            </svg>
          </a>
        </li>

        <li
          :key="item.attributes.id"
          @click.prevent.stop="handleClickOnPerson($event, item)"
          v-touch:longtap="handleLongTap(item.attributes.id)"
          :data-id="item.attributes.id"
          :class="{ 'is-hidden': notAttendingVisibleItems.length == 0 }"
          class="
            is-not-draggable
            list__item list__item--regular list__item--merged list__item--tighter
            no-space-out
          "
          v-for="item in notAttendingVisibleItems"
        >
          <Person
            :item="item"
            :loadOnSidepanel="loadOnSidepanel"
            :requestForPeopleToPlace="requestForPeopleToPlace"
            :iconDotsVerticalUrl="iconDotsVerticalUrl"
            :selectPeopleIds="selectPeopleIds"
            :unselectPeopleIds="unselectPeopleIds"
            :places="places"
            :peopleLabels="peopleLabels"
            :isSelectable="false"
            :isSelected="isSelected"
            :isDisabled="isDisabled"
          />
        </li>

        <li
          v-if="undecidedItems.length != 0"
          class="
            is-not-draggable
            list__item list__item--regular list__item--merged list__item--tighter
            no-space-out
          "
        >
          <a
            @click.prevent.stop="toggleShow('undecided')"
            href="#"
            class="list__block list__block--merged list__block--short"
          >
            <strong class="list__text">{{ t('undecided') }} ({{ undecidedItems.length }})</strong>
            <svg class="icon icon--tiny icon--slategray" :class="arrowClass(show['undecided'])">
              <use :xlink:href="icon('icon-arrow-down')"></use>
            </svg>
          </a>
        </li>

        <li
          :key="item.attributes.id"
          @click.prevent.stop="handleClickOnPerson($event, item)"
          v-touch:longtap="handleLongTap(item.attributes.id)"
          :data-id="item.attributes.id"
          :class="{ 'is-hidden': undecidedVisibleItems.length == 0 }"
          class="
            is-not-draggable
            list__item list__item--regular list__item--merged list__item--tighter
            no-space-out
          "
          v-for="item in undecidedVisibleItems"
        >
          <Person
            :item="item"
            :loadOnSidepanel="loadOnSidepanel"
            :requestForPeopleToPlace="requestForPeopleToPlace"
            :iconDotsVerticalUrl="iconDotsVerticalUrl"
            :selectPeopleIds="selectPeopleIds"
            :unselectPeopleIds="unselectPeopleIds"
            :places="places"
            :peopleLabels="peopleLabels"
            :isSelectable="false"
            :isSelected="isSelected"
            :isDisabled="isDisabled"
          />
        </li>
      </draggable>
    </div>
  </div>
</template>

<script>
import _ from '../../../application/vendor/lodash.custom';
import draggable from 'vuedraggable';
import Person from './person.vue';
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) =>
  I18n.t(key, {
    scope: 'assignments.assignments_component.people_component',
    ...opts,
  });

export default {
  props: [
    'peopleEntityName',
    'icon',
    'isDisabled',
    'enableSelection',
    'isSelecting',
    'items',
    'showItemOnSidePanel',
    'onDragStart',
    'onDragEnd',
    'peopleListName',
    'peopleListKey',
    'peopleSearch',
    'places',
    'requestForPeopleToPlace',
    'selectPeopleIds',
    'unselectPeopleIds',
    'selectedPeopleIds',
    'toggleSelectionForPersonId',
    'unselectAll',
    'peopleLabels',
    'attendanceLimitReached',
    'unassignPeople',
  ],
  components: {
    Person,
    draggable,
  },
  data() {
    return {
      show: { attending: true, waitlisted: true, notAttending: false, undecided: false },
    };
  },
  methods: {
    t,
    loadOnSidepanel(person) {
      this.showItemOnSidePanel(person.attributes.id, 'people');
    },
    handleLongTap(personId) {
      return () => this.toggleSelectionForPersonId(personId);
    },
    handleClickOnPerson(event, person) {
      if (event.ctrlKey || event.metaKey || this.isSelecting) {
        if (!this.isSelectable(person.attributes.id)) {
          return;
        }
        this.toggleSelectionForPersonId(person.attributes.id);
      } else {
        this.loadOnSidepanel(person);
      }
    },
    isSelectable(personId) {
      return !this.isDisabled && this.undecidedOrNotAttendingItemsIds.indexOf(personId) < 0;
    },
    isSelected(personId) {
      const index = this.selectedPeopleIds.indexOf(personId);
      return index > -1;
    },
    handlePeopleDrop(event) {
      const peopleIds = Array(event.item.dataset.id).map(Number);
      this.unassignPeople(peopleIds);
    },
    toggleShow(attendance) {
      this.show[attendance] = !this.show[attendance];
    },
    arrowClass(isActive) {
      if (isActive) {
        return '';
      } else {
        return 'icon--rotate';
      }
    },
  },
  computed: {
    undecidedOrNotAttendingItemsIds() {
      const undecidedOrNotAttending = ['undecided', 'not_attending'];
      return this.items
        .filter((item) => undecidedOrNotAttending.includes(item.tracking.attending.value))
        .map((item) => item.attributes.id);
    },
    peopleIds() {
      return this.attendingItems.map((item) => item.attributes.id);
    },
    attendingVisibleItems() {
      if (!this.show['attending']) {
        return [];
      }

      return this.attendingItems;
    },
    attendingItems() {
      return this.items.filter((item) => item.tracking.attending.value === 'attending');
    },
    waitlistedVisibleItems() {
      if (!this.show['waitlisted']) {
        return [];
      }

      return this.waitlistedItems;
    },
    waitlistedItems() {
      return _.orderBy(
        this.items.filter((item) => item.tracking.attending.value === 'waitlisted'),
        'tracking.waiting_list_at.timestamp'
      );
    },
    undecidedVisibleItems() {
      if (!this.show['undecided']) {
        return [];
      }

      return this.undecidedItems;
    },
    undecidedItems() {
      return this.items.filter((item) => item.tracking.attending.value === 'undecided');
    },
    notAttendingVisibleItems() {
      if (!this.show['notAttending']) {
        return [];
      }

      return this.notAttendingItems;
    },
    notAttendingItems() {
      return this.items.filter((item) => item.tracking.attending.value === 'not_attending');
    },
    iconCheckmarkUrl() {
      return this.icon('icon-checkmark');
    },
    iconDotsVerticalUrl() {
      return this.icon('icon-dots-vertical');
    },
  },
};
</script>
