import Vue from 'vue/dist/vue.esm';
import { Turbo } from '@hotwired/turbo-rails';
import sweetAlert from 'sweetalert';

const AjaxErrorHandler = {
  start() {
    // @rails/ujs
    $(document).on('ajax:error', (event) => {
      const [, , xhr] = event.detail;
      this.handleXhr(xhr);
    });
    // jquery $.ajax
    $(document).ajaxError((event, jqxhr) => {
      if (jqxhr.status === 401) {
        Turbo.visit(window.location);
      }
    });
    // vue-resource
    Vue.http.interceptors.push(this._unauthorizedInterceptor);
  },
  handleXhr(xhr) {
    switch (xhr.status) {
      case 401:
        Turbo.visit(window.location);
        return Promise.resolve();
      case 403:
        return sweetAlert({
          title: xhr.responseText,
          text: 'Please, upgrade your account to have access to this feature',
          icon: 'warning'
        });
      default:
        return Promise.reject();
    }
  },
  _unauthorizedInterceptor: (req, next) => {
    next((res) => {
      if (res.status === 401) {
        Turbo.visit(window.location);
      }
    });
  }
};

export default AjaxErrorHandler;
