import SidePanel from './sidepanel';

const GROUPS_WITH_ID = { 'List Fields': 'list_field', Questions: 'question' };

const EventPartSelectSpots = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      this.hookEvents($sidepanel);
    });
    this.hookEvents($(document));
  },

  hookEvents($parent) {
    const $listsSelect = $parent.find('.js-places-segmented-list');
    if ($listsSelect.length) {
      $listsSelect.change(this.changeList);
      $listsSelect.change(this.changePlacesAltName);
      $parent.find('.js-select-spots').change(this.changeSpotsField);
      $parent.find('.js-people-segmented-list').change(this.changePeopleAltName);
      $listsSelect.change();
    }
  },

  changePeopleAltName(event) {
    const entityName = $(event.target)
      .find(':selected')
      .data('entityName');
    const $container = $(event.target).closest('.js-event-part-select-spots');
    const $placeholder = $container.find('#js-people-list-alt-name');
    $placeholder.attr('placeholder', entityName);
  },

  changePlacesAltName(event) {
    const entityName = $(event.target)
      .find(':selected')
      .data('entityName');
    const $container = $(event.target).closest('.js-event-part-select-spots');
    const $placeholder = $container.find('#js-places-list-alt-name');
    $placeholder.attr('placeholder', entityName);
  },

  changeSpotsField(event) {
    const selectedSpotsOption = $(event.target).find(':selected')[0];
    const $container = $(event.target).closest('.js-event-part-select-spots');
    const $spotsPlaceholder = $container.find('.js-spots-placeholder');
    $spotsPlaceholder.hide();
    if (selectedSpotsOption === undefined || selectedSpotsOption.value === '') {
      return;
    }
    const segmentedListId = $container.find('.js-places-segmented-list :selected')[0].value;
    const spotsId = selectedSpotsOption.value;
    const spotsSource = $(selectedSpotsOption).data('group');
    const spotsUrl = $(event.target)
      .data('spotsCountUrl')
      .replace('SPOTS_ID', spotsId);
    const ajaxUrl = `${spotsUrl}?spots_source=${spotsSource}&segmented_list_id=${segmentedListId}`;
    $('.js-spots-source').val(spotsSource);
    $.ajax({
      url: ajaxUrl
    }).done((response) => {
      $spotsPlaceholder.html(`${response.spots_count} Spots`);
      $spotsPlaceholder.show();
    });
  },

  mountSpots($selectSpots, fields) {
    const selectedSpotsSourceId = $selectSpots.data('selectedSpotsSourceId');
    $selectSpots.empty();
    $selectSpots.append($('<option value="">Select a field</option>'));
    fields.forEach((element) => {
      const group = element[0];
      const groupId = GROUPS_WITH_ID[group];
      const items = element[1];
      $selectSpots.append($(`<optgroup label="${group}"></optgroup>`));
      items.forEach((item) => {
        const $option = $(`<option data-group="${groupId}" value="${item[1]}">${item[0]}</option>`);
        $selectSpots.find('optgroup:last').append($option);
      });
    });
    const $option = $selectSpots.find(`option[value='${selectedSpotsSourceId}']`);
    if ($option.length > 0) {
      $option.prop('selected', 'selected');
    } else {
      $selectSpots.find('option[value="spots"]').prop('selected', 'selected');
    }
    $selectSpots.attr('data-dirrty-initial-value', $selectSpots.val());
    $selectSpots.change();
  },

  changeList(event) {
    const selectedSegmentedList = $(event.target).find(':selected')[0].value;
    const $container = $(event.target).closest('.js-event-part-select-spots');
    const $selectSpots = $container.find('.js-select-spots');
    $.ajax({
      url: `${$selectSpots.data('spotsFieldsUrl')}?segmented_list_id=${selectedSegmentedList}`
    }).done((response) => {
      EventPartSelectSpots.mountSpots($selectSpots, response);
    });
  }
};

export default EventPartSelectSpots;
