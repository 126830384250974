import SidePanel from './sidepanel';

const ChangeActiveNavbar = {
  start() {
    SidePanel.onUpdate(() => {
      $('.js-show-active-tab .tabbar__link').click(this.activateTab);
    });
  },
  activateTab(e) {
    e.preventDefault();
    $(e.currentTarget)
      .closest('.js-show-active-tab')
      .find('.tabbar__link')
      .removeClass('is-active');
    $(e.currentTarget).addClass('is-active');
    const activeTabId = e.currentTarget.hash;
    $('.sidepanel__data').addClass('is-hidden');
    $(`.sidepanel__data${activeTabId}`).removeClass('is-hidden');
  }
};

export default ChangeActiveNavbar;
