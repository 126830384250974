import displayDatatablesPagination from '../shared/datatables_pagination';
import { withSaveState } from '../shared/datatables_state';
import {
  overridePaginationClasses,
  customizeDOMPaginationClasses
} from '../../datatables/customization-v2';
import {
  setup as tableSetup,
  setupPaginationOnDOM,
  customizePageLength,
  evaluateSelectedRows,
  defaultTableOptions
} from '../../datatables/table-setup';

const PaymentsTable = {
  start() {
    const $table = $('.payments-table:not(.dataTable)');
    if (!$table.length) {
      return;
    }
    this.setupDataTable($table);
  },

  setupDataTable($table) {
    overridePaginationClasses();
    const $checkAllCheckbox = $('#checkall');
    let table;
    const tableOptions = {
      ...defaultTableOptions,
      order: [[1, 'asc']],
      dom: 'rtp',
      select: {
        style: 'multi',
        selector: 'label.js-checkbox-for-row',
        className: 'is-selected'
      },
      columnDefs: [
        { targets: [0], orderable: false, width: '7%' },
        { targets: [1], orderable: true, width: '7%' },
        { targets: [2], searchable: true, width: '20%' },
        { targets: [-1], orderable: false, width: '9%' },
        { targets: '_all', searchable: false }
      ],
      drawCallback() {
        customizePageLength(this);
        customizeDOMPaginationClasses();
        evaluateSelectedRows(table, $checkAllCheckbox);
        displayDatatablesPagination(this);
      },
      initComplete: () => {
        setupPaginationOnDOM();
      },
      language: {
        ...defaultTableOptions.language,
        zeroRecords:
          '<span class="alert alert--info">Sorry, no matching Event Payments were found</span>',
        emptyTable: '<span class="alert alert--info">No Payment Items</span>'
      }
    };
    const tableOptionsWithSaveState = withSaveState($table, tableOptions);
    table = $table.DataTable(tableOptionsWithSaveState);
    tableSetup(table);
  }
};

export default PaymentsTable;
