import moment from 'moment-timezone';

const CheckSendOnEmail = {
  start() {
    const $checkSendOnDate = $('.js-check-send-on-date');
    const $radioSendOptions = $('#send-on-now, #send-on-select');
    let submitButtonDisabledForSendOn = false;

    $radioSendOptions.on('change', (e) => {
      if (e.currentTarget.value === 'now') {
        $checkSendOnDate.attr('disabled', false);
      }
      if (e.currentTarget.value === 'select') {
        $checkSendOnDate.attr('disabled', submitButtonDisabledForSendOn);
      }
    });

    $('#send-on-datepicker-with-timezone').on('change:time', (_value, timeMoment) => {
      const sendOnPastValue = timeMoment.isBefore(moment());
      submitButtonDisabledForSendOn = sendOnPastValue;
      $checkSendOnDate.attr('disabled', sendOnPastValue);
    });
  }
};

export default CheckSendOnEmail;
