import SidePanel from './sidepanel';

const Questions = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      if ($sidepanel.find('.question-form').length) {
        this.initializeQuestions();
        this.setupInputErrors();
        this.toogleQuestionDetails();
        this.updateFieldsList();
        this.initializeDateTimeFields();
        this.toggleDateTimeFields();
        this.handleMultipleChoiceTypeChange();
      }
    });
  },

  hideAndDisable($element) {
    $element.hide().find('select, input, textarea').prop('disabled', true);
  },

  showAndEnable($element) {
    $element.show().find('select, input, textarea').not('.edit-question').prop('disabled', false);
  },

  updateRequiredFieldOrder(order) {
    $('span.question-required-options-order').text(order);
  },

  initializeQuestions() {
    const selectedType = $(".question-type-option input[type='radio']:checked");
    const requiredFieldOrder = $(selectedType).data('required-field-order');
    const visibleDiv = $(`.question-${$(selectedType).val()}`);
    this.updateRequiredFieldOrder(requiredFieldOrder);
    this.enableQuestion(visibleDiv);
  },

  initializeDateTimeFields() {
    const selectedDateType = $(".question-date-options input[type='radio']:checked").val();
    const requiredFieldOrder = $(".question-type-option input[type='radio']:checked").data(
      'required-field-order'
    );
    if (selectedDateType === 'date_and_time' || selectedDateType === 'date_only') {
      this.updateRequiredFieldOrder(requiredFieldOrder);
      $('.date-format').show();
    } else {
      this.updateRequiredFieldOrder(requiredFieldOrder - 1);
      $('.date-format').hide();
    }
  },

  toggleDateTimeFields() {
    const self = this;
    $(".question-date-options input[type='radio']").change(function () {
      self.initializeDateTimeFields();
    });

    $(".question-type-option input[type='radio']:checked");
  },

  enableQuestion($element) {
    this.hideAndDisable($('.question-detail-option'));
    this.showAndEnable($element);
  },

  toogleQuestionDetails() {
    const self = this;
    $(".question-type-option input[type='radio']").on('change', (e) => {
      const $target = $(e.target);
      const showRequiredField = $target.val() !== 'information_only';
      const requiredFieldOrder = $target.data('required-field-order');

      self.toggleRequiredField(showRequiredField);
      self.updateRequiredFieldOrder(requiredFieldOrder);

      const visibleDiv = $(`.question-${$target.val()}`);
      self.enableQuestion(visibleDiv);

      if ($target.val() === 'date_and_time') {
        self.initializeDateTimeFields();
      }
    });
  },

  updateFieldsList() {
    const self = this;
    const loadFieldsToQuestionType = ($target) => {
      const $container = $target.closest('.question-form');
      const $fieldsList = $container.find('.js-fields-list');
      const fieldsUrl = $fieldsList.data('fieldsUrl').replace('QUESTION_TYPE', $target.val());
      $.ajax({
        url: fieldsUrl
      }).done((response) => {
        self.mountFieldsList($fieldsList, response);
      });
    };
    $(".question-type-option input[type='radio']").on('change', (e) => {
      const $questionTypeRadio = $(e.target);
      loadFieldsToQuestionType($questionTypeRadio);
    });
    loadFieldsToQuestionType($(".question-type-option input[type='radio']:checked"));
  },

  mountFieldsList($fieldsList, groupedFields) {
    const selectedFieldId = $fieldsList.data('selectedFieldId');
    $fieldsList.empty();
    if (groupedFields.length) {
      $fieldsList.append($('<option value="">Select a field</option>'));
      groupedFields.forEach(({ group, fields }) => {
        const $group = $(`<optgroup label="${group}"></optgroup>`);
        fields.forEach((field) => {
          $group.append(
            `<option value="${field.id}" ${field.id === selectedFieldId ? 'selected' : ''}>${
              field.name
            }</option>`
          );
        });
        $fieldsList.append($group);
      });
    } else {
      $fieldsList.append($('<option value="" selected>No compatible fields</option>'));
    }
    $fieldsList.attr('data-dirrty-initial-value', $fieldsList.val());
    $fieldsList.change();
  },

  toggleRequiredField(show) {
    if (show) {
      $('.required-field').addClass('is-shown').removeClass('is-hidden');
    } else {
      $('.required-field').addClass('is-hidden').removeClass('is-shown');
    }
  },

  setupInputErrors() {
    $('.question-extra-attributes-input').each(function (_, div) {
      if ($(div).has('.error').length !== 0) {
        $(div).addClass('field_with_errors');
      }
    });
  },

  handleMultipleChoiceTypeChange() {
    const $rankChoices = $('.js-rank-choices');
    $rankChoices.hide();
    $(".js-multiple-choice-types input[type='radio']").on('change', (event) => {
      if (event.currentTarget.value === 'rank') {
        $rankChoices.show();
      } else {
        $rankChoices.hide();
      }
    });
  }
};

export default Questions;
