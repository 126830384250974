import I18n from 'i18n-js/index.js.erb';
import displayDatatablesPagination from '../shared/datatables_pagination';
import { withSaveState } from '../shared/datatables_state';
import {
  overridePaginationClasses,
  customizeDOMPaginationClasses
} from '../../datatables/customization-v2';
import {
  setup as tableSetup,
  setupPaginationOnDOM,
  customizePageLength,
  evaluateSelectedRows,
  defaultTableOptions
} from '../../datatables/table-setup';

const t = (key, opts) => I18n.t(key, {
  scope: 'js.my_events.event_mailings.table',
  ...opts
});

const EventMailingsTable = {
  start() {
    const $table = $('.event-mailings-table:not(.dataTable)');
    if (!$table.length) {
      return;
    }
    this.setupDataTable($table);
  },

  setupDataTable($table) {
    overridePaginationClasses();
    const $checkAllCheckbox = $('#checkall');
    let table;
    const tableOptions = {
      ...defaultTableOptions,
      dom: 'rtp',
      select: {
        style: 'multi',
        selector: 'label.js-checkbox-for-row',
        className: 'is-selected'
      },
      columnDefs: [
        { targets: [0], orderable: false, searchable: false, width: '7%' },
        { targets: [1], searchable: true, width: '10%' },
        { targets: [2], searchable: true, width: '2%' },
        { targets: [5], searchable: true, width: '10%' },
        { targets: [6], searchable: true, width: '10%' },
        { targets: [7], searchable: true, width: '10%' },
        { targets: [-1], orderable: false, searchable: false, width: '9%' },
        { targets: '_all', searchable: false }
      ],
      drawCallback() {
        customizePageLength(this);
        customizeDOMPaginationClasses();
        evaluateSelectedRows(table, $checkAllCheckbox);
        displayDatatablesPagination(this);
        if ($(this).find('.dataTables_empty').length == 1) {
          $(this).children('.table__head').hide();
        }
      },
      initComplete: () => {
        setupPaginationOnDOM();
      },
      language: {
        ...defaultTableOptions.language,
        zeroRecords: `<span class="alert alert--info">${t('zero_records')}</span>`,
        emptyTable: `<span class="alert alert--info">${t('empty')}</span>`
      }
    };
    const tableOptionsWithSaveState = withSaveState($table, tableOptions);
    table = $table.DataTable(tableOptionsWithSaveState);
    tableSetup(table);
  }
};

export default EventMailingsTable;
