import ProductTours from './product-tours';

const ToggleTour = {
  start() {
    if (ProductTours.isActive()) {
      $('[data-tour-active]')
        .removeClass('is-hidden')
        .click(ProductTours.show);
    }
  }
};

export default ToggleTour;
