import SidePanel from './sidepanel';
import ToggleVisible from './toggle_visible';

const TIMEOUT = 5000;

const FlashMessage = {
  start() {
    SidePanel.onUpdate(() => this.hideFlashMessages());
    this.hideFlashMessages();
    $('.js-close-flash').on('click', this.hideClick.bind(this));
  },
  hideFlashMessages() {
    $('.flash.is-visible')
      .not('.is-permanent')
      .toArray()
      .reverse()
      .forEach((flash, i) => {
        setTimeout(() => ToggleVisible.hide($(flash)), TIMEOUT * (i + 1));
      });
  },
  showMessageWithTimer(flashType, message) {
    $('.flashes').html(`<div class="flash flash--${flashType} is-visible">${message}</div>`);
    this.hideFlashMessages();
  },
  hideClick(e) {
    e.preventDefault();
    const $closeButton = $(e.currentTarget);
    ToggleVisible.hide($closeButton.closest('.flash'));
  }
};

export default FlashMessage;
