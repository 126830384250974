<template>
  <chartist
    type="Bar"
    :class="className"
    :event-handlers="events"
    :data="chartData"
    :options="chartOptions"
  />
</template>

<script>
export default {
  props: ['series', 'labels', 'className'],
  data() {
    return {
      chartData: {
        series: JSON.parse(this.series),
        labels: JSON.parse(this.labels)
      },
      chartOptions: {
        distributeSeries: true,
        axisX: {
          showGrid: false,
          onlyInteger: true
        },
        axisY: {
          onlyInteger: true
        }
      },
      events: [{
        event: 'draw',
        fn: data => {
          if (data.type === 'bar') {
            data.element.attr({ style: 'stroke-width: 30px'});
          }
        }
      }]
    }
  }
}
</script>
