const ListNameUpdate = {
  start() {
    this.watchEditEnable();
    this.watchEditEntity();
    this.watchEditDescription();
    this.watchListKindChange();
  },

  watchEditEnable() {
    $('.show-edit-name').click((event) => {
      event.preventDefault();
      $('.edit-name').show();
      $('.show-name').hide();
      $('.submit-section').show();
    });
  },

  watchEditEntity() {
    $('.show-edit-entity-name').click((event) => {
      event.preventDefault();
      $('.edit-entity-name').show();
      $('.show-entity-name').hide();
      $('.submit-section').show();
    });
  },

  watchEditDescription() {
    $('.show-edit-description').click((event) => {
      event.preventDefault();
      $('.edit-description').show();
      $('.show-description').hide();
      $('.submit-section').show();
    });
  },

  watchListKindChange() {
    $('.list-kind-check').change(() => {
      $('.submit-section').show();
    });
  }
};

export default ListNameUpdate;
