<template>
  <div>
    <fieldset>
      <label for="event_responses_accepted_option">{{ t('responses_accepted_option') }}</label>
      <input
        v-model="responsesAcceptedOption"
        type="radio"
        value="anytime"
        name="event[responses_accepted_option]"
        id="event_responses_accepted_option_anytime"
        class="radio"
      />
      <label for="event_responses_accepted_option_anytime" class="voidclick">
        {{ t('responses_accepted_options.anytime') }}
      </label>
      <input
        v-model="responsesAcceptedOption"
        type="radio"
        value="from"
        name="event[responses_accepted_option]"
        id="event_responses_accepted_option_from"
        class="radio"
      />
      <label for="event_responses_accepted_option_from" class="voidclick">
        {{ t('responses_accepted_options.from') }}
      </label>
      <input
        v-model="responsesAcceptedOption"
        type="radio"
        value="to"
        name="event[responses_accepted_option]"
        id="event_responses_accepted_option_to"
        class="radio"
      />
      <label for="event_responses_accepted_option_to" class="voidclick">
        {{ t('responses_accepted_options.to') }}
      </label>
      <input
        v-model="responsesAcceptedOption"
        type="radio"
        value="from_to"
        checked="checked"
        name="event[responses_accepted_option]"
        id="event_responses_accepted_option_from_to"
        class="radio"
      />
      <label for="event_responses_accepted_option_from_to" class="voidclick">
        {{ t('responses_accepted_options.from_to') }}
      </label>
    </fieldset>
    <fieldset>
      <datepicker-with-timezone
        v-if="showFrom"
        :initial-time="responsesAcceptedFrom"
        :initial-timezone="responsesAcceptedFromTimezone"
        fieldsetId="accepted-from"
        datetimeFieldName="event[responses_accepted_from]"
        timezoneFieldName="event[responses_accepted_from_time_zone]"
        :rowLabel="t('responses_accepted_from')"
        required="true"
        @change="setFromDatetimeMoment($event)"
        @change:timezone="setFromTimezone($event)"
      >
      </datepicker-with-timezone>
    </fieldset>
    <fieldset v-bind:class="{ field_with_errors: untilErrors.length > 0 }">
      <datepicker-with-timezone
        v-if="showTo"
        :initial-time="responsesAcceptedUntil"
        :initial-timezone="responsesAcceptedUntilTimezone"
        fieldsetId="accepted-to"
        datetimeFieldName="event[responses_accepted_until]"
        timezoneFieldName="event[responses_accepted_until_time_zone]"
        :rowLabel="t('responses_accepted_until')"
        required="true"
        @change="setUntilDatetimeMoment($event)"
        @change:timezone="setUntilTimezone($event)"
      >
      </datepicker-with-timezone>
      <div v-for="error in untilErrors" :key="error" class="error">{{ capitalize(error) }}</div>
    </fieldset>
  </div>
</template>

<script>
import DatepickerWithTimezone from './datepicker_with_timezone.vue';
import moment from 'moment-timezone';
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) =>
  I18n.t(key, {
    scope: 'events.edit',
    ...opts,
  });

export default {
  props: {
    initialResponsesAcceptedOption: {
      type: String,
      required: true,
    },
    initialResponsesAcceptedFrom: String,
    initialResponsesAcceptedFromTimezone: String,
    initialResponsesAcceptedUntil: String,
    initialResponsesAcceptedUntilTimezone: String,
    initialUntilErrors: String
  },

  data() {
    return {
      responsesAcceptedOption: this.initialResponsesAcceptedOption,
      responsesAcceptedFrom: moment(this.initialResponsesAcceptedFrom),
      responsesAcceptedFromTimezone: this.initialResponsesAcceptedFromTimezone,
      responsesAcceptedUntil: moment(this.initialResponsesAcceptedUntil),
      responsesAcceptedUntilTimezone: this.initialResponsesAcceptedUntilTimezone,
      untilErrors: JSON.parse(this.initialUntilErrors)
    };
  },

  components: {
    DatepickerWithTimezone,
  },

  methods: {
    t,

    setFromDatetimeMoment(value) {
      value = moment(value);
      const changeDifference = value.diff(this.responsesAcceptedFrom, 'minutes');
      const endString = moment(this.responsesAcceptedUntil)
        .add(changeDifference, 'minutes')
        .toISOString();
      this.setUntilDatetimeMoment(endString);
      this.responsesAcceptedFrom = value;
    },

    setUntilDatetimeMoment(value) {
      value = moment(value);
      this.responsesAcceptedUntil = value;
    },

    setFromTimezone(value) {
      if (this.responsesAcceptedFromTimezone === this.responsesAcceptedUntilTimezone) {
        this.responsesAcceptedUntilTimezone = value;
      }
      this.responsesAcceptedFromTimezone = value;
    },

    setUntilTimezone(value) {
      this.responsesAcceptedUntilTimezone = value;
    },

    capitalize(message) {
      return message.charAt(0).toUpperCase() + message.slice(1);
    }
  },

  computed: {
    showFrom() {
      return ['from', 'from_to'].includes(this.responsesAcceptedOption);
    },

    showTo() {
      return ['to', 'from_to'].includes(this.responsesAcceptedOption);
    },
  },
};
</script>
