const ChangePlanURLTrigger = {
  start() {
    if (window.location.href.includes('target=change-plan')) {
      const button = $('#subscription-side-panel');
      button.click();
    }
  }
};

export default ChangePlanURLTrigger;
