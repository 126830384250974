import SidePanel from './sidepanel';

const TableRowActive = {
  start() {
    if ($('tr[data-sidepanel-href]').length) {
      this.attach($('tr[data-sidepanel-href]').parent());
    }
  },
  attach($selector) {
    $selector.on('sidepanel:shown', this._setAsActive.bind(this));
    SidePanel.onReset(() => $selector.find('tr').removeClass('is-active'));
  },
  _setAsActive(e) {
    $(e.target)
      .addClass('is-active')
      .siblings('tr')
      .removeClass('is-active');
  }
};

export default TableRowActive;
