import 'jquery.payment';
import './vendor/jquery.dirrty';
import './vendor/jquery.watermark';
import './vendor/jquery.geocomplete';

const JqueryAppModules = require.context('./app', true, /\.js$/);

const excludedFiles = [
  'ajax_error_handler.js',
  'list_fields_by_user.js',
  'list_name_update.js',
  'new_list_items_table.js',
  'product-tours.js',
  'tooltip.js'
];

export default {
  modules: [],

  start() {
    JqueryAppModules.keys().forEach(moduleName => {
      if (!excludedFiles.includes(moduleName.replace('./', ''))) {
        const module = JqueryAppModules(moduleName);
        this.modules.push(module);
        if (typeof module.default.start === 'function') {
          module.default.start.bind(module.default);
          module.default.start();
        }
      }
    });
  },

  tearDown() {
    this.modules.forEach(module => {
      if (typeof module.default.tearDown === 'function') {
        module.default.tearDown.bind(module.default);
        module.default.tearDown();
      }
    });
  }
};
