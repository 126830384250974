import deLocale from '@fullcalendar/core/locales/de';
import frLocale from '@fullcalendar/core/locales/fr';
import ptBRLocale from '@fullcalendar/core/locales/pt-br';

const languageLocales = {
  'pt-BR': ptBRLocale,
  'fr': frLocale,
  'de': deLocale,
  'en': 'en'
};

export const locale = (language) => {
  return languageLocales[language];
};
