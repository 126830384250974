import SidePanel from './sidepanel';

const RequestPlanUpgrade = {
  start() {
    $('.js-request-plan-upgrade').each(this._showDialog.bind(this));
    $('.js-radio-plan-upgrade').click(this._handleRadio.bind(this));
  },

  _handleRadio(event) {
    event.preventDefault();
    const { sidepanelHref } = event.currentTarget.dataset;

    this._showAlert(event.currentTarget).then((willSubmit) => {
      if (willSubmit) {
        SidePanel.loadFromAjax({url: sidepanelHref});
      }
    });
  },

  _showDialog(_index, element) {
    this._showAlert(element).then((willSubmit) => {
      if (willSubmit) {
        $(element).click();
      }
    });
  },

  _showAlert(element) {
    const { title, message } = element.dataset;
    return sweetAlert({
      title,
      text: message,
      icon: 'warning',
      buttons: ['Cancel', 'Upgrade']
    });
  }
};

export default RequestPlanUpgrade;
