<template>
  <div class="input string optional">
    <div>
      <input
        ref="autocomplete"
        class="location-for-copy input input--full input-max-length"
        maxlength="250"
        size="250"
        :placeholder="t('type_an_adress')"
        type="text"
        v-model="location"
        :name="fieldLocation"
        :id="inputId"
      >
    </div>
    <div>
      <input
        ref="locationCaption"
        class="location-caption-for-copy input input--full input-max-length"
        maxlength="50"
        size="50"
        :placeholder="t('display_name')"
        type="text"
        v-model="locationCaption"
        :name="fieldLocationCaption"
      >
    </div>
    <google-static-map :name="locationName" :lat="locationLat" :lng="locationLng"></google-static-map>
    <div v-for="(error, index) in locationErrors" :key="index" class="error">{{error}}</div>
    <input v-model="locationName" :name="fieldLocationName" type="hidden">
    <input v-model="locationLat" :name="fieldLocationLat" type="hidden">
    <input v-model="locationLng" :name="fieldLocationLng" type="hidden">
  </div>
</template>
<script>
import GoogleStaticMap from "./google_static_map";
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) => I18n.t(key, {
  scope: 'events.form_from_event',
  ...opts
});

const MIN_LOCATION_LENGTH = 2;

export default {
  props: {
    resourceName: String,
    initialLocation: String,
    initialLocationCaption: String,
    initialLocationName: String,
    initialLocationLat: String,
    initialLocationLng: String,
    errors: {
      type: String,
      default: "[]"
    }
  },
  components: {
    GoogleStaticMap
  },
  data() {
    return {
      inputId: null,
      location: this.initialLocation,
      locationAddress: this.initialLocation,
      locationName: this.initialLocationName,
      locationCaption: this.initialLocationCaption,
      locationLat: this.initialLocationLat,
      locationLng: this.initialLocationLng,
      locationErrors: JSON.parse(this.errors)
    };
  },
  computed: {
    fieldLocation() {
      return `${this.resourceName}[location]`;
    },
    fieldLocationName() {
      return `${this.resourceName}[location_name]`;
    },
    fieldLocationCaption() {
      return `${this.resourceName}[location_caption]`;
    },
    fieldLocationLat() {
      return `${this.resourceName}[location_lat]`;
    },
    fieldLocationLng() {
      return `${this.resourceName}[location_lng]`;
    }
  },
  methods: {
    t,
    handlePlaceChange(_name, placeResultData) {
      const { formatted_address, name, geometry } = placeResultData;
      this.location = this.$refs.autocomplete.value;
      this.locationAddress = formatted_address;
      this.locationName = name;
      this.initialLocationCaption = name;
      this.locationLat = geometry.location.lat();
      this.locationLng = geometry.location.lng();
      this.$nextTick(() => {
        $(this.$refs.autocomplete).change();
        $(this.$refs.locationCaption).change();
      });
    },
    initGeoComplete() {
      $(this.$refs.autocomplete).geocomplete({
        onChange: this.handlePlaceChange,
        geolocate: true,
        types: ["establishment", "geocode"],
        appendToParent: true
      });
    }
  },
  watch: {
    location(newVal) {
      if (newVal.length <= MIN_LOCATION_LENGTH) {
        this.locationAddress = "";
        this.locationName = "";
        this.locationCaption = "";
        this.locationLat = "";
        this.locationLng = "";
        this.$nextTick(() => {
          $(this.$refs.autocomplete).change();
          $(this.$refs.locationCaption).change();
        });
      }
    },
    initialLocation(newVal) {
      this.location = newVal;
      this.$nextTick(() => $(this.$refs.autocomplete).change());
    },
    initialLocationName(newVal) {
      this.locationName = newVal;
    },
    initialLocationCaption(newVal) {
      this.locationCaption = newVal;
      this.$nextTick(() => $(this.$refs.locationCaption).change());
    },
    initialLocationLat(newVal) {
      this.locationLat = newVal;
    },
    initialLocationLng(newVal) {
      this.locationLng = newVal;
    }
  },
  mounted() {
    this.inputId = "autocomplete-" + this._uid;
    this.$nextTick(() => this.initGeoComplete());
  }
};
</script>
