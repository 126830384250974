const SyncEditor = {
  start() {
    $('[data-sync-editor]').on('toggle:changed', this.sizeSyncIframe);
  },
  sizeSyncIframe() {
    $(document).trigger('syncFroalaIframe');
  }
};

export default SyncEditor;
