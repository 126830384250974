<template>
  <div class="card card--overlap">
    <header class="card__header card__header--actions">
      <h6 class="card__title">
        <span>{{t('participants.events.event_list')}}</span>
        <small class="card__subtext">{{ eventsCounterCaption }}</small>
      </h6>
      <ul class="card__actions actionbar is-print-hidden">
        <li class="card__action">
          <div class="search">
            <input v-model="search" :placeholder="t('participants.events.search_here')" type="search" class="input input--invisible search__input">
            <svg class="search__icon icon icon--tiny icon--slategray">
              <use :xlink:href="iconUrl('icon-search')"></use>
            </svg>
          </div>
        </li>
        <li class="card__action">
          <div class="dropdown dropdown--floating">
            <div class="dropdown__holder">
              <a href="#" class="dropdown__button">
                <div class="dropdown__content">
                  <span class="dropdown__label">
                    {{t('participants.events.customize')}}
                  </span>
                </div>
                <svg class="icon icon--tiny icon--slategray dropdown__icon">
                  <use :xlink:href="iconUrl('icon-paint')"></use>
                </svg>
              </a>
              <ul class="dropdown__list dropdown__list--floating dropdown__list--medium treeview dropdown__list--right">
                <li class="dropdown__item treeview__item">
                  <label class="switch dropdown__switch no-click noclick">
                    <input checked="checked" type="checkbox" class="switch__el">
                    <svg class="icon icon--tiny icon--gray switch__icon is-invisible">
                      <use :xlink:href="iconUrl('icon-eye')"></use>
                    </svg>
                    <span class="switch__text switch__text--treeview">
                      {{t('participants.events.status')}}
                    </span>
                  </label>
                  <ul class="treeview">
                    <li class="treeview__item">
                      <label class="switch dropdown__switch">
                        <input v-model="filters.showInProgress" type="checkbox" class="switch__el">
                        <svg class="icon icon--tiny icon--gray switch__icon">
                          <use :xlink:href="iconUrl('icon-eye')"></use>
                        </svg>
                        <span class="switch__text switch__text--treeview">
                          {{t('participants.events.in_progress')}}
                        </span>
                      </label>
                    </li>
                    <li class="treeview__item">
                      <label class="switch dropdown__switch">
                        <input v-model="filters.showUpcoming" type="checkbox" class="switch__el">
                        <svg class="icon icon--tiny icon--gray switch__icon">
                          <use :xlink:href="iconUrl('icon-eye')"></use>
                        </svg>
                        <span class="switch__text switch__text--treeview">
                          {{t('participants.events.upcoming')}}
                        </span>
                      </label>
                    </li>
                    <li class="treeview__item">
                      <label class="switch dropdown__switch">
                        <input v-model="filters.showCompleted" type="checkbox" class="switch__el">
                        <svg class="icon icon--tiny icon--gray switch__icon">
                          <use :xlink:href="iconUrl('icon-eye')"></use>
                        </svg>
                        <span class="switch__text switch__text--treeview">
                          {{t('participants.events.completed')}}
                        </span>
                      </label>
                    </li>
                  </ul>
                </li>

                <li class="dropdown__item treeview__item">
                  <label class="switch dropdown__switch no-click noclick">
                    <input checked="checked" type="checkbox" class="switch__el">
                    <svg class="icon icon--tiny icon--gray switch__icon is-invisible">
                      <use :xlink:href="iconUrl('icon-eye')"></use>
                    </svg>
                    <span class="switch__text switch__text--treeview">
                      {{t('participants.events.personas')}}
                    </span>
                  </label>
                  <ul class="treeview">
                    <li class="treeview__item" v-for="persona in uniquePersonas" :key="persona">
                      <label class="switch dropdown__switch">
                        <input v-model="showEventForPersonas" :value="persona" type="checkbox" class="switch__el">
                        <svg class="icon icon--tiny icon--gray switch__icon">
                          <use :xlink:href="iconUrl('icon-eye')"></use>
                        </svg>
                        <span class="switch__text switch__text--treeview">{{ persona }}</span>
                      </label>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </header>

    <div class="card__content card__content--high">
      <div class="card__block card__block--merged" :class="{'is-hidden': !filters.showInProgress }">
        <h6 class="subtitle">{{t('participants.events.in_progress')}} ({{ active.length }})</h6>
        <hr class="no-space-out">
      </div>
      <div class="container__row container__row--default container__row--middle container__row--break" :class="{'is-hidden': !filters.showInProgress }">
        <participant-event-tile
          :personasAttendances="personasAttendancesForEvent(event.id)"
          :event="event" v-for="event in active"
          :iconUrl="iconUrl"
          :key="event.id">
        </participant-event-tile>
        <div class="has-full-width" :class="{'is-hidden': active.length}">
          <span class="alert alert--info alert--center">
            {{t('participants.events.no_events_available')}}
          </span>
        </div>
      </div>

      <div class="card__block card__block--merged" :class="{'is-hidden': !filters.showUpcoming }">
        <h6 class="subtitle">{{t('participants.events.upcoming')}} ({{ pending.length }})</h6>
        <hr class="no-space-out">
      </div>

      <div class="container__row container__row--default container__row--middle container__row--break" :class="{'is-hidden': !filters.showUpcoming }">
        <participant-event-tile
          :personasAttendances="personasAttendancesForEvent(event.id)"
          :event="event" v-for="event in pending"
          :iconUrl="iconUrl"
          :key="event.id">
        </participant-event-tile>
        <div class="has-full-width" :class="{'is-hidden': pending.length}">
          <span class="alert alert--info alert--center">
            {{t('participants.events.no_events_available')}}
          </span>
        </div>
      </div>

      <div class="card__block card__block--merged" :class="{'is-hidden': !filters.showCompleted }">
        <h6 class="subtitle">{{t('participants.events.completed')}} ({{ completed.length }})</h6>
        <hr class="no-space-out">
      </div>

      <div class="container__row container__row--default container__row--middle container__row--break" :class="{'is-hidden': !filters.showCompleted }">
        <participant-event-tile
          :personasAttendances="personasAttendancesForEvent(event.id)"
          :event="event" v-for="event in completed"
          :iconUrl="iconUrl"
          :key="event.id">
        </participant-event-tile>
        <div class="has-full-width" :class="{'is-hidden': completed.length}">
          <span class="alert alert--info alert--center">
            {{t('participants.events.no_events_available')}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import I18n from 'i18n-js/index.js.erb';
import participantEventTile from './participant_event_tile.vue';

export default {
  props: [
    'eventsUrl',
    'svgIconsUrl',
    'personasAttendances'
  ],

  data() {
    const duplicatedPersonas = Object.values(this.personasAttendances);
    const duplicatedPersonasNames = duplicatedPersonas.flat().map((persona) => persona.full_name);
    const uniquePersonas = Array.from(new Set(duplicatedPersonasNames)).sort();

    return {
      events: [],
      filters: { showInProgress: true, showUpcoming: true, showCompleted: true },
      search: '',
      showEventForPersonas: uniquePersonas,
      uniquePersonas
    }
  },

  components: {
    participantEventTile
  },

  mounted: function () {
    this.$nextTick(function () {
      $.get(this.eventsUrl, (data) => {
        this.events = data;
      });
    })
  },

  methods: {
    t: I18n.t,
    personasAttendancesForEvent(eventId) {
      return this.personasAttendances[eventId];
    },

    iconUrl(iconName) {
      return `${this.svgIconsUrl}#${iconName}`;
    }
  },

  computed: {
    fuse() {
      const options = {
        threshold: 0.3,
        keys: ['name']
      };
      return new Fuse(this.eventsVisibleForPersonas, options);
    },

    eventsCounterCaption() {
      const { length: count } = this.events;
      const { length: filteredCount } = this.filteredEvents;
      if (count === 1 || count === filteredCount) {
        return I18n.t('participants.events.item', { count });
      } else {
        return I18n.t('participants.events.items_out_of', { total: filteredCount, count });
      }
    },

    eventsVisibleForPersonas() {
      const personasWithEvents = Object.values(this.personasAttendances);
      const flatPersonasWithEvents = personasWithEvents.flat();
      const eventIds = flatPersonasWithEvents.filter(persona => this.showEventForPersonas.includes(persona.full_name)).map(persona => persona.id);
      const distinctEventIds = Array.from(new Set(eventIds));
      return this.events.filter(event => distinctEventIds.includes(event.id));
    },

    filteredEvents() {
      if (this.search.trim() === '') {
        return this.eventsVisibleForPersonas;
      } else {
        return this.fuse.search(this.search);
      }
    },
    active() {
      return this.filteredEvents.filter(event => event.status === 'Active');
    },
    pending() {
      return this.filteredEvents.filter(event => event.status === 'Pending');
    },
    completed() {
      return this.filteredEvents.filter(event => event.status === 'Completed');
    },
  }
}
</script>
