import { Turbo } from '@hotwired/turbo-rails';
import MobileDropdown from './mobile_dropdown';

const LIST_FIELD_HANDLE_SELECTOR = '.js-list-field-handle';

const ListFieldsSortable = {
  start() {
    if ($('#custom-list-fields').length) {
      const $document = $(document);
      $document.on('change', '.js-choose-custom-field-position', this.moveOrder.bind(this));
    }
  },

  moveOrder(event) {
    const $target = $(event.target);
    const nextPosition = parseInt($target.find(':selected').val(), 10);
    const $card = $target.closest(LIST_FIELD_HANDLE_SELECTOR);
    const currentPosition = parseInt($card.data('position'), 10);
    const $referenceCard = $(LIST_FIELD_HANDLE_SELECTOR).eq(nextPosition);
    if (nextPosition > currentPosition) {
      $referenceCard.after($card);
    } else {
      $referenceCard.before($card);
    }
    this.updateOrder();
  },

  orderedIds() {
    const ids = $(`#custom-list-fields ${LIST_FIELD_HANDLE_SELECTOR}`).map(function() {
      return $(this).data('id');
    });
    return Array.from(ids);
  },

  displayErrorMessage() {
    sweetAlert({
      title: 'Something went wrong',
      text: 'This page will be reloaded to guarantee the consistency of your data.',
      icon: 'error'
    }).then(() => {
      Turbo.visit(window.location);
    });
  },

  replaceCustomFields(content) {
    $('.js-list-fields').replaceWith(content);
    MobileDropdown.hookEvents($('.js-list-fields'));
  },

  updateOrder() {
    const updateUrl = $('#custom-list-fields').data('sortUrl');
    const customFieldsIds = ListFieldsSortable.orderedIds();
    $.ajax({
      url: updateUrl,
      method: 'PUT',
      dataType: 'html',
      data: { custom_fields_ids: customFieldsIds },
      success: ListFieldsSortable.replaceCustomFields,
      error: ListFieldsSortable.displayErrorMessage
    });
  }
};

export default ListFieldsSortable;
