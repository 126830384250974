<template>
  <div class="form">
    <div class="sidepanel__block sidepanel__block--merged">
      <button @click="addRuleSet" class="button button--full button--white button--light button--stroke button--square no-space-out js-sidepanel-alt-trigger">
        <div class="button__holder">
          <svg class="icon button__icon">
            <use :xlink:href="iconPlus"></use>
          </svg>
          <span class="button__text">New {{ruleCaption}} Set</span>
        </div>
      </button>
    </div>

    <div class="sidepanel__block" v-for="(filterSet, setPosition) in filtersSet">
      <rule-set
        :filter-set="filterSet"
        :columns="columns"
        :icon-trash="iconTrash"
        :icon-edit="iconEdit"
        :icon-plus="iconPlus"
        :icon-checkmark="iconCheckmark"
        @remove-set="removeSet(setPosition)"
        :generate-id="generateId"
        :show-toggle="showSetToggle"
        :rule-caption="ruleCaption"
      >
      </rule-set>
    </div>

    <div class="filterset__error-message" v-bind:class="{ 'is-visible': requestError }">
      <p>
        Unable to save your {{ruleCaption}} settings, properly.<br>
        A {{ruleCaption}} value cannot be empty and must contain a valid entry.<br>
        Please review your entries and try again.<br>
        If the problem persists, please contact our Support team.
      </p>
    </div>
  </div>
</template>

<script>
import ruleSet from './rule_set.vue';
import { Turbo } from '@hotwired/turbo-rails';

export default {
  props: {
    'initial-filters-set': String,
    'initial-columns': String,
    'resource-name': String,
    'update-url': String,
    'icon-trash': String,
    'icon-edit': String,
    'icon-plus': String,
    'icon-checkmark': String,
    'redirect-on-success': String,
    'show-set-toggle': {
      type: Boolean,
      default: false
    },
    'rule-caption': {
      type: String,
      default: 'Rule'
    }
  },
  components: {
    ruleSet
  },
  data() {
    return {
      requestError: false,
      filtersSet: JSON.parse(this.initialFiltersSet),
      columns: JSON.parse(this.initialColumns),
      deleted: []
    }
  },
  methods: {
    showRequestErrorMessage() {
      this.requestError = true;
      setTimeout(() => {
        this.requestError = false;
      }, 7000);
    },

    addRuleSet() {
      const rowId = this.generateId();
      const nextSetCounter = this.filtersSet.length + 1;
      const newName = `${this.ruleCaption} Set #${nextSetCounter}`;
      this.filtersSet.push({
        "set_name": newName,
        "enabled": true,
        "conditions": [{"row_id": rowId, "value": ""}]
      });
    },

    removeSet(filterSetPosition) {
      const filterSet = this.filtersSet[filterSetPosition];
      if (filterSet && filterSet.id) {
        this.deleted.push(filterSet.id);
      }
      this.filtersSet.splice(filterSetPosition, 1);
    },

    saveRules() {
      const authenticityToken = $('meta[name="csrf-token"]')[0].content;
      let data = { filters: this.filtersSet, deleted: this.deleted };
      if (this.resourceName) {
        data = {
          [this.resourceName]: { filters: this.filtersSet }
        }
      }
      this.$http.put(this.updateUrl,
        data,
        { responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticityToken
          }
        }).then(response => {
          this.requestError = false;
          $('.table').trigger('datatable:reload');
          $('.table').trigger('filters:set', [response.body]);
          $('#filters-dropdown').trigger('filters:set', [response.body]);
          if (this.redirectOnSuccess) {
            Turbo.visit(this.redirectOnSuccess);
          }
        }, response => {
          this.showRequestErrorMessage();
        });
    },

    generateId() {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      )
    }
  },
  mounted() {
    $('[data-sidepanel-submit]').click(this.saveRules);
  }
}
</script>

<style lang="scss">

  .filterset {
    &__error-message {
      background-color: #fbe3e4;
      box-shadow: 0 0 5px #ad141e;
      color: #ad141e;
      display: block important;
      padding: 15px;
      position: fixed;
      top: 0;
      transition: transform .2s linear;
      width: 100%;
      z-index: 99;

      * {
        display: block !important;
      }

      *:last-of-type {
        margin: 0;
      }

      &:not(.is-visible) {
        transform: translateY(-105%);
      }

      &.is-visible {
        transform: translateY(0);
      }
    }
  }
</style>
