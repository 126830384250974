<template>
  <ul class="list list--bordered">
    <li class="list__item">
      <div class="list__block list__block--tighter" v-show="!editName">
        <label class="switch switch--start no-space-out">
          <span>
            <input class="switch__el" v-model="price.active" type="checkbox">
            <span class="switch__holder">
              <a class="switch__button"></a>
            </span>
          </span>
          <span class="switch__text" @click.prevent="editName = true">{{price.name}}</span>
        </label>
        <button @click="editName = true" class="button button--circle button--sky list__button" type="button">
          <svg class="icon button__icon">
            <use :xlink:href="iconEdit"></use>
          </svg>
        </button>
      </div>

      <div class="list__block list__block--tighter" v-show="editName">
        <div class="input--full">
          <input @blur="editName = false" @keyup.enter="editName = false" v-model="price.name" class="input input--line" type="text">
        </div>
        <button @click="editName = false" class="button button--circle button--blue list__button" type="button">
          <svg class="icon icon--white button__icon">
            <use :xlink:href="iconCheckmark"></use>
          </svg>
        </button>
      </div>
    </li>

    <li class="list__item list__item--merged">
      <div class="list__block list__block--merged">
        <fieldset class="form__field form__field--unbreak form__field--full">
          <label>{{ t("price") }}</label>
          <input v-model.number="price.price" type="text" value="" placeholder="$" class="input input--full no-space-out" :disabled="price.id">
        </fieldset>
      </div>
      <div class="list__block list__block--merged">
        <fieldset class="form__field form__field--unbreak form__field--full">
          <label>{{ t("units") }}</label>
          <input v-model.number="price.package_quantity" type="text" placeholder="Tennis Classes for Kids" class="input input--full no-space-out" :disabled="price.id">
        </fieldset>
      </div>
    </li>

    <li class="list__item list__item--merged no-space-out" v-if="!price.id">
      <div class="list__block list__block--merged list__block--short">
        <button v-show="editName" class="button button--red button--square button--full no-space-out" @click="$emit('remove-price')">
          <div class="button__holder">
            <svg class="icon icon--white button__icon">
              <use :xlink:href="iconTrash"></use>
            </svg>
            <span class="button__text">{{ t("delete_package") }}</span>
          </div>
        </button>
      </div>
    </li>
  </ul>
</template>

<script>
import I18n from 'i18n-js/index.js.erb';

const t = (key, opts) => I18n.t(key, {
  scope: 'my_events.payments.package_price_item',
  ...opts
});

export default {
  props: {
    'price': Object,
    'icon-trash': String,
    'icon-edit': String,
    'icon-checkmark': String
  },
  data() {
    return {
      editName: false
    }
  },
  methods: {
    t
  }
}
</script>

