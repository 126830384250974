export const serializeFormToJSON = function($form) {
  const dataArray = $form.serializeArray();
  return dataArray.reduce((acc, item) => {
    if (acc[item.name]) {
      if (!acc[item.name].push) {
        acc[item.name] = [acc[item.name]];
      }
      acc[item.name].push(item.value || '');
    } else {
      acc[item.name] = item.value || '';
    }
    return acc;
  }, {});
};
