import Rails from '@rails/ujs';
import SidePanel from './sidepanel';

const AutoSubmit = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('select[data-autosubmit]').change(this.submit.bind(this));
      $sidepanel.find('select[data-autosubmit-button]').change(this.clickButton.bind(this));
      $sidepanel.find('a[data-autosubmit-link]').click(this.clickLink.bind(this));
    });
    $('a[data-autosubmit-button]').click(this.clickButtonAndPrevent.bind(this));
    $('select[data-autosubmit-button]').change(this.clickButton.bind(this));
  },

  submit(e) {
    const $form = $(e.currentTarget).parents('form');
    if ($form.length) {
      Rails.fire($form.get(0), 'submit');
    }
  },

  clickButton(e) {
    const target = e.currentTarget.dataset.autosubmitButton;
    $(target).click();
  },

  clickButtonAndPrevent(e) {
    const $target = $(e.currentTarget.dataset.autosubmitButton);
    if ($target.length) {
      e.preventDefault();
      $target.click();
    }
  },

  clickLink(e) {
    const $form = $(e.currentTarget.dataset.autosubmitLink);
    if ($form.length) {
      e.preventDefault();
      Rails.fire($form.get(0), 'submit');
    }
  }
};

export default AutoSubmit;
