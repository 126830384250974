const SubmitSendNoteForm = {
  start() {
    $('#event-mailing-notes-form').on('submit', this.submitWithSendOnFields.bind(this));
  },

  submitWithSendOnFields(event) {
    const fieldNames = ['event_mailing[send_on]', 'event_mailing[send_on_time_zone]'];
    const sendOnFields = $('.js-submit-send-form').serializeArray().filter(field => fieldNames.indexOf(field.name) >= 0);
    const clearSchedule = sendOnFields.length === 1;

    if (clearSchedule) {
      fieldNames.forEach(function(fieldName) {
        $(event.currentTarget).append(`<input type="hidden" name="${fieldName}">`);
      });
    } else {
      sendOnFields.forEach(function(item) {
        $(event.currentTarget).append(`<input type="hidden" name="${item.name}" value="${item.value}">`);
      });
    }
    return true;
  }
};

export default SubmitSendNoteForm;
