import displayDatatablesPagination from '../shared/datatables_pagination';
import { withSaveState } from '../shared/datatables_state';
import {
  overridePaginationClasses,
  customizeDOMPaginationClasses
} from '../../datatables/customization-v2';
import {
  setup as tableSetup,
  setupPaginationOnDOM,
  customizePageLength,
  evaluateSelectedRows,
  defaultTableOptions
} from '../../datatables/table-setup';
import SidePanel from './sidepanel';
import TableRowActive from './table_row_active';

const columnRenders = {
  check: (data) => {
    const id = data.RowData.pkey;
    return `
      <input data-id="${id}" id="check_${id}" class="checkbox table__checkbox" type="checkbox">
      <label for="check_${id}" class="table__label js-checkbox-for-row no-space-out voidclick"></label>`;
  },
  boolean: (data) => {
    let normalizedData = data === null ? '' : data;
    normalizedData = normalizedData.toString().toLowerCase();
    if (normalizedData === 'yes' || normalizedData === 'true') {
      return 'Yes';
    }
    if (normalizedData === 'no' || normalizedData === 'false') {
      return 'No';
    }
    return '';
  },
  createAttendingRender: defaultResponse => (data, type, full, meta) => {
    const normalizedData = data === null ? '' : data;
    if (normalizedData !== '') {
      return columnRenders.boolean(data, type, full, meta);
    }
    return defaultResponse;
  }
};

const TrackResponsesTable = {
  start() {
    const $table = $('.js-track-responses-table:not(.dataTable)');
    if (!$table.length) {
      return;
    }
    this.setupDataTable($table);
    this.watchRowClick($table);
    const datatable = $table.DataTable();
    $table.on('datatable:deselect', () => datatable.rows().deselect());
    $table.on('datatable:reload', () => datatable.ajax.reload());
  },

  setupDataTable($table) {
    overridePaginationClasses();
    const $checkAllCheckbox = $('#checkall');
    let table;
    const tableOptions = {
      ...defaultTableOptions,
      dom: 'rtp',
      columnDefs: [
        { targets: [0], orderable: false, width: '7%' },
        { targets: '_all', searchable: false }
      ],
      columns: this._getColumns($table),
      rowCallback: this._rowCallback,
      select: {
        style: 'multi',
        selector: 'label.js-checkbox-for-row',
        className: 'is-selected'
      },
      drawCallback() {
        customizePageLength(this);
        customizeDOMPaginationClasses();
        evaluateSelectedRows(table, $checkAllCheckbox);
        displayDatatablesPagination(this);
      },
      ajax: {
        url: $table.data('url'),
        dataSrc: 'data'
      },
      initComplete: () => {
        setupPaginationOnDOM();
      },
      language: {
        ...defaultTableOptions.language,
        zeroRecords:
          '<span class="alert alert--info">Sorry, no matching Responses were found</span>',
        emptyTable: '<span class="alert alert--info">No Responses available</span>'
      }
    };
    const tableOptionsWithSaveState = withSaveState($table, tableOptions);
    table = $table.DataTable(tableOptionsWithSaveState);
    tableSetup(table);
  },

  watchRowClick($table) {
    const url = $table.data('detailsUrl');
    TableRowActive.attach($table.find('tbody'));
    $table.find('tbody').on('click', 'tr:not(.group)', (e) => {
      const listItemId = $(e.currentTarget).data('id');
      if (listItemId) {
        e.currentTarget.dataset.sidepanelHref = url.replace('LIST_ITEM_ID', listItemId);
        SidePanel.loadAndShow(e);
      }
    });
  },

  _getColumns($table) {
    /* eslint-disable no-param-reassign */
    const columns = $table.data('columns').map((column, index) => {
      if (index === 0) {
        column.render = columnRenders.check;
      } else if (column.data === 'attributes.tags') {
        column.render = data => data.map(tag => `<span class="tag tag--${tag.color}">${tag.name}</span>`).join('');
      } else if (column.data === 'tracking.attending' || column.response_type === 'money') {
        column.render = { _: 'display', sort: 'order' };
      } else if (column.response_type === 'num') {
        column.type = 'num';
      } else if (column.response_type === 'boolean') {
        column.render = columnRenders.boolean;
      } else if (column.response_type === 'comment') {
        column.className = 'table__data--truncate has-truncate-text';
      } else if (
        column.response_type === 'date_and_time' &&
        (column.data && column.data.indexOf('tracking.') >= 0)
      ) {
        column.render = { _: 'display', sort: 'timestamp' };
      }
      column.render = column.render || $.fn.dataTable.render.text();
      return column;
    });
    return columns;
    /* eslint-enable no-param-reassign */
  },

  _rowCallback(row, data) {
    const listItemId = data.attributes.id;
    $(row).data('id', listItemId);
  }
};

export default TrackResponsesTable;
