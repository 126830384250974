<template>
  <chartist
    type="Pie"
    :data="chartData"
    :options="chartOptions"
  />
</template>

<script>
const defaultChartOptions = {
  donut: true,
  donutWidth: 8,
  showLabel: false
};
export default {
  props: ['series', 'options'],
  data() {
    return {
      chartData: {
        series: this.series
      },
      chartOptions: {
        ...defaultChartOptions,
        ...this.options,
      }
    }
  }
}
</script>

<style lang="scss">
  .ct-chart-donut {
    bottom: 0;
    left: 0;
    margin: auto;
    max-height: 150px !important;
    max-width: 240px !important;
    position: absolute;
    right: 0;
    top: 0;
  }
</style>
