import Rails from '@rails/ujs';
import sweetAlert from 'sweetalert';

const confirmed = (element, result) => {
  if (result) {
    // User clicked confirm button
    element.removeAttribute('data-sweet-alert-confirm');
    element.click();
  }
};

// Display the confirmation dialog
const showConfirmationDialog = element => {
  const message = element.getAttribute('data-sweet-alert-confirm');
  const text = element.getAttribute('data-text');
  const buttons = element.getAttribute('data-buttons');
  const danger = element.getAttribute('data-danger');

  sweetAlert({
    title: message || 'Are you sure?',
    text: text || '',
    icon: 'warning',
    dangerMode: danger === null ? true : JSON.parse(danger),
    buttons: (buttons && JSON.parse(buttons)) || ['No', 'Yes']
  }).then(result => confirmed(element, result));
};

const allowAction = element => {
  if (element.getAttribute('data-sweet-alert-confirm') === null) {
    return true;
  }

  showConfirmationDialog(element);
  return false;
};

function handleConfirm(element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
}

Rails.delegate(document, '[data-sweet-alert-confirm]', 'click', handleConfirm);
