import Darkmode from 'darkmode-js';
import I18n from '../../i18n-js/index.js.erb';

const { lang } = document.documentElement;
const tooltip = I18n.t('js.dark_mode.tooltip', { locale: lang });

const opts = {
  time: '0.1s',
  saveInCookies: true,
  left: '10px',
  right: 'unset',
  buttonColorDark: '#FFFFFF',
  buttonColorLight: '#2D333D',
  label: `<div class="button__holder has-full-width tooltip tooltip--right" data-tooltip="${tooltip}"><svg class="icon icon--small" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M50 19c-17.1 0-31 13.9-31 31s13.9 31 31 31 31-13.9 31-31-13.9-31-31-31zm0 55.5v-49c13.5 0 24.5 10.9 24.5 24.5S63.5 74.5 50 74.5z"/></svg></div>`
};

const DarkMode = {
  start() {
    new Darkmode(opts).showWidget();
    this.watchDarkmodeToggle();
  },

  watchDarkmodeToggle() {
    $('.darkmode-toggle').click(() => {
      this.updateIframeDarkmode();
    });
  },

  updateIframeDarkmode() {
    const { darkmode } = window.localStorage;
    const $iframeBody = $('iframe.fr-iframe').contents().find('body');
    if (darkmode === 'true') {
      $iframeBody.children().css('color', 'white');
    } else {
      $iframeBody.children().css('color', 'black');
    }
  }
};

export default DarkMode;
