import Rails from '@rails/ujs';
import sweetAlert from 'sweetalert';
import SidePanel from './sidepanel';
import { serializeFormToJSON } from '../utils/form_serializer';

const AttendanceChangeConfirm = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel
        .find('.js-questions-form')
        .on('ajax:success', this._ajaxSuccess)
        .on('ajax:success', this._reloadResponsesTable)
        .trigger('clean');
      $sidepanel
        .find('.js-attendance-change-confirm')
        .on('ajax:success', this._reloadResponsesTable)
        .on('submit', this._askConfirmation.bind(this))
        .trigger('clean');
    });
  },

  _ajaxSuccess() {
    $('.js-assignments-container').trigger('assignments:reload');
  },

  _askConfirmation(e) {
    const $form = $(e.currentTarget);
    const data = serializeFormToJSON($form);
    const attendance = data['attending_attributes[value]'];
    if (['', 'false'].includes(attendance) && !$form.data('confirmed')) {
      e.preventDefault();
      this._showDialog(attendance).then((confirmed) => {
        if (confirmed) {
          $form.data('confirmed', true);
          Rails.fire($form.get(0), 'submit');
        }
      });
      return false;
    }
    return true;
  },

  _showDialog(attendance) {
    const value = attendance === '' ? 'Undecided' : 'Not Attending';
    return sweetAlert({
      title: 'Confirmation Required',
      text: `Changing the Event status for this item to "${value}" will change all Event Activities this item appears in to "${value}" and remove any assignments.`,
      icon: 'warning',
      buttons: ['Cancel', 'Confirm']
    });
  },

  _reloadResponsesTable() {
    $('.js-track-responses-table').trigger('datatable:reload');
  }
};

export default AttendanceChangeConfirm;
