<template>
  <ul id="filters-dropdown" class="dropdown__list dropdown__list--floating dropdown__list--medium">
    <li class="dropdown__item" v-for="(filterSet, setPosition) in filtersSet">
      <label class="switch dropdown__switch" @click.stop>
        <input class="switch__el" type="checkbox" v-model="filterSet.enabled" @change="saveRules" />
        <span class="switch__holder">
          <a class="switch__button"></a>
        </span>
        <span class="switch__text">{{filterSet.set_name}}</span>
      </label>
    </li>
    <li class="dropdown__item dropdown__item--bordered">
      <a class="dropdown__link" data-sidepanel="true" :href="editUrl">
        <span class="dropdown__legend dropdown__legend--center">Edit Filters</span></a>
    </li>
  </ul>
</template>

<script>
import SidePanel from './../../application/app/sidepanel';

export default {
  props: [
    'initial-filters-set',
    'source-url',
    'edit-url'
  ],
  data() {
    return {
      requestError: false,
      filtersSet: JSON.parse(this.initialFiltersSet),
    }
  },
  methods: {
    saveRules() {
      const authenticityToken = $('meta[name="csrf-token"]')[0].content;
      let data = { filters: this.filtersSet };
      if (this.resourceName) {
        data = {
          [this.resourceName]: { filters: this.filtersSet }
        }
      }
      this.$http.put(this.sourceUrl,
        data,
        { responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': authenticityToken
          }
        }).then(response => {
          this.requestError = false;
          this.filtersSet = response.body;
          const enabledCounter = this.filtersSet.reduce(this._countEnabled, 0);
          this.setEnabledFiltersCounter(enabledCounter);
          $('.table').trigger('datatable:reload');
        });
    },

    _countEnabled(accumulator, value) {
      return value.enabled ? (accumulator + 1) : accumulator;
    },

    setEnabledFiltersCounter(counter) {
      const $counter = $('.js-active-filters-counter');
      if (counter === 0) {
        $counter.addClass('is-hidden');
      } else {
        $counter.removeClass('is-hidden');
      }
      $counter.html(counter);
    }
  },
  mounted() {
    $("#filters-dropdown").on("filters:set", (e, data) => {
      const enabledCounter = data.reduce(this._countEnabled, 0);
      this.setEnabledFiltersCounter(enabledCounter);
      this.filtersSet = data;
      SidePanel.reset();
    });
  }
}
</script>
