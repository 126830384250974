import SidePanel from './sidepanel';

const CustomFields = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      if ($('.js-custom-fields').length) {
        this.initializeCustomFields($sidepanel);
        this.toogleCustomFieldDetails($sidepanel);
        this.initializeDateTimeFields($sidepanel);
        this.toggleDateTimeFields($sidepanel);
      }
    });
  },

  _hideAndDisable($element) {
    $element
      .hide()
      .find('select, input, textarea')
      .prop('disabled', true);
  },

  _showAndEnable($element) {
    $element
      .show()
      .find('select, input, textarea')
      .not('.input--readonly')
      .prop('disabled', false);
  },

  initializeCustomFields($sidepanel) {
    const $initialSelectedType = $sidepanel
      .find('.js-custom-field-types input[type="radio"]:checked')
      .val();
    const $visibleDiv = $sidepanel.find(`.custom-field-${$initialSelectedType}`);
    this._enableCustomField($visibleDiv, $sidepanel);
  },

  initializeDateTimeFields($sidepanel) {
    const selectedDateType = $sidepanel
      .find('.custom-field-date-options input[type="radio"]:checked')
      .val();
    if (selectedDateType === 'date_and_time' || selectedDateType === 'date_only') {
      $sidepanel.find('.date-format').show();
    } else {
      $sidepanel.find('.date-format').hide();
    }
  },

  _enableCustomField($element, $sidepanel) {
    this._hideAndDisable($sidepanel.find('.custom-field-detail-option'));
    this._showAndEnable($element);
  },

  toogleCustomFieldDetails($sidepanel) {
    $sidepanel.find(".js-custom-field-types input[type='radio']").change((e) => {
      const checkboxValue = $(e.target).val();
      const $visibleDiv = $sidepanel.find(`.custom-field-${checkboxValue}`);
      this._enableCustomField($visibleDiv, $sidepanel);
    });
  },

  toggleDateTimeFields($sidepanel) {
    $sidepanel.find(".custom-field-date-options input[type='radio']").change(() => {
      this.initializeDateTimeFields($sidepanel);
    });
  }
};

export default CustomFields;
