import SidePanel from './sidepanel';
import RecipientsTable from './recipients_table';
import { serializeFormToJSON } from '../utils/form_serializer';

const RecipientListEdit = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('.recipients-list-form').on('submit', this.handleSubmit);
    });
    SidePanel.onReset(this.reset);
    $('.js-edit-recipients').click(this.handleEditRecipients);
    $('[data-edit-row-recipients]').click(this.handleEditRow.bind(this));
  },

  open() {
    $('.js-edit-recipients').click();
  },

  reset() {
    $('[data-edit-row-recipients]').removeClass('is-active');
  },

  handleEditRecipients(e) {
    e.preventDefault();
    const url = $(e.currentTarget).data('href');
    const $form = $(e.currentTarget).parents('form');
    const data = $form.serializeArray().filter(v => v.name !== '_method');
    SidePanel.loadFromAjax({ url, data, method: 'post' });
  },

  handleEditRow(e) {
    if (['SPAN', 'TD', 'A'].includes(e.target.tagName)) {
      e.preventDefault();
      this.reset();
      const $row = $(e.currentTarget);
      const url = $('.js-edit-recipients').data('href');
      const rowIndex = $row.data('edit-row-recipients');
      const segmentedListId = $row.find('.checkbox').data('id');
      const listItemIds = RecipientsTable.getRecipientsByRow(rowIndex);
      const data = {
        event_mailing: {
          segmented_list_ids: [segmentedListId],
          list_item_ids: listItemIds
        },
        row_index: rowIndex
      };
      SidePanel.loadFromAjax({ url, data, method: 'post' });
      $row.addClass('is-active');
    }
  },

  handleSubmit(e) {
    e.preventDefault();
    const $form = $(e.currentTarget);
    const data = serializeFormToJSON($form);
    if (data.recipients && data.row_index) {
      RecipientsTable.selectRowByIndex(data.row_index);
    }
    const recipients = data.recipients || [];
    RecipientsTable.updateRecipients(recipients, data.row_index);
    SidePanel.reset();
  }
};

export default RecipientListEdit;
