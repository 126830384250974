import SidePanel from './sidepanel';

const NoClick = {
  start() {
    $('.voidclick').click(function() {});
    $('.noclick').click(() => false);
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.find('.voidclick').click(function() {});
      $sidepanel.find('.noclick').click(() => false);
    });
  }
};

export default NoClick;
