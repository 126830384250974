<template>
  <div class="google__img" v-if="lat && lng">
    <img :src="mapUrl">
  </div>
</template>

<style lang="scss">
  .google__img {
    margin-bottom: 1rem;
    img { width: 100%; }
  }
</style>

<script>
const BASE_URL_MAP = "https://maps.googleapis.com/maps/api/staticmap?";

export default {
  props: ["name", "lat", "lng"],
  data() {
    return {
      format: "png",
      zoom: 13,
      size: "430x300",
      scale: "1",
      type: "roadmap"
    };
  },
  computed: {
    mapUrl() {
      const center = [this.lat, this.lng].join(",");
      return `${BASE_URL_MAP}center=${center}&zoom=${this.zoom}&size=${
        this.size
      }&markers=${center}&maptype=${this.type}&format=${this.format}&key=${
        this.googleApiKey
      }&scale=${this.scale}`;
    },
    googleApiKey() {
      return $('meta[name="gmaps"]').attr("content");
    }
  }
};
</script>
