<template>
  <div>
    <div class="sidepanel__block">
      <h6 class="subtitle">2. {{t('source')}}</h6>
      <hr>
      <div class="input">
        <multiselect
          @input="changedSelectedProduct"
          v-model="value"
          trackBy="sku_id"
          :options="options"
          :custom-label="customLabel"
          placeholder="Select an existing Product">
        </multiselect>
        <input :value="value && value.sku_id" name="product_form[copy_product][product_item_sku_id]" type="hidden">
        <div :key="error" v-for="error in errorsFor('product_item_sku_id')" class="error">{{ error }}</div>
      </div>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">3. {{t('product_name')}}</h6>
      <hr>
      <div class="input" :class="{field_with_errors: errorsFor('name')}">
        <input ref="productNameInput" v-model="productName" name="product_form[copy_product][name]" class="string required input input--full input-max-length" maxlength="100" size="100" type="text">
        <div :key="error" v-for="error in errorsFor('name')" class="error">{{ error }}</div>
      </div>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">4. {{t('description')}}</h6>
      <hr>
      <div class="input" :class="{field_with_errors: errorsFor('description')}">
        <textarea v-model="description" name="product_form[copy_product][description]" class="text required input input--full input-max-length" maxlength="200"></textarea>
        <div :key="error" v-for="error in errorsFor('description')" class="error">{{ error }}</div>
      </div>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">5. {{t('event_or_activities')}}</h6>
      <hr>

      <event-parts-for-products
        :initialActivities="initialActivities"
        initialSelectedActivitiesIds="[]"
        :initialProductErrors="initialProductErrors"
        fieldName="product_form[copy_product][event_part_ids][]"
      >
      </event-parts-for-products>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">6. {{t('price')}}</h6>
      <hr>
      <div class="input" :class="{field_with_errors: errorsFor('price')}">
        <input disabled="disabled" placeholder="$" v-model.number="price" name="product_form[copy_product][price]" class="string required input input--readonly" type="text">
        <div :key="error" v-for="error in errorsFor('price')" class="error">{{ error }}</div>
      </div>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">7. {{t('required')}}</h6>
      <hr>
      <div class="input" :class="{field_with_errors: errorsFor('required')}">
        <fieldset>
          <input v-model="isRequired" type="radio" value="true" name="product_form[copy_product][required]" id="product_form_copy_product_required_true" class="radio">
          <label class="voidclick" for="product_form_copy_product_required_true">Required</label>
          <input v-model="isRequired" type="radio" value="false" name="product_form[copy_product][required]" id="product_form_copy_product_required_false" class="radio">
          <label class="voidclick" for="product_form_copy_product_required_false">Optional</label>
        </fieldset>
        <div :key="error" v-for="error in errorsFor('required')" class="error">{{ error }}</div>
      </div>
    </div>

    <div class="sidepanel__block">
      <h6 class="subtitle">8. {{t('limit_per_person')}}</h6>
      <hr>
      <div class="input" :class="{field_with_errors: errorsFor('maximum_quantity')}">
        <input v-model.number="limitPerPerson" name="product_form[copy_product][maximum_quantity]" class="string required input" type="text">
        <div :key="error" v-for="error in errorsFor('maximum_quantity')" class="error">{{ error }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import EventPartsForProducts from './event_parts_for_products';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) => I18n.t(key, {
  scope: 'my_events.payments.form_copy',
  ...opts
});

export default {
  props: [
    'initialFields',
    'initialProductErrors',
    'productsUrl',
    'initialActivities'
  ],

  components: {
    Multiselect,
    EventPartsForProducts
  },

  data() {
    const fields = JSON.parse(this.initialFields);
    const productErrors = this.initialProductErrors && JSON.parse(this.initialProductErrors) || {};
    return {
      fields: fields,
      productErrors: productErrors,
      value: null,
      selectedActivities: [],
      options: [],
      productName: fields.name,
      price: fields.price,
      limitPerPerson: fields.maximum_quantity,
      isRequired: fields.required,
      description: fields.description
    }
  },

  methods: {
    t,

    customLabel({name, price_formatted}) {
      return `${name} - ${price_formatted}`;
    },

    changedSelectedProduct(inputValue) {
      if (inputValue === null) {
        this._clearProduct();
        return;
      }
      const { name, price_formatted } = inputValue;
      this.productName = name;
      this.price = price_formatted;
      this.$nextTick(function () {
        $(this.$refs.productNameInput).trigger('change');
      });
    },

    errorsFor(fieldName) {
      return this.productErrors[fieldName];
    },

    changedSelectedActivities(values, id) {
      const activities = JSON.parse(this.initialActivities);
      const options = activities.map(activity => ({ id: activity[1], name: activity[0] }));
      const eventPartIds = values.map((value) => value.id);
      this.selectedActivities = options.filter(option => eventPartIds.includes(option.id));
    },

    _clearProduct() {
      this.productName = '';
      this.price = null;
      this.$nextTick(function () {
        $(this.$refs.productNameInput).trigger('change');
      });
    }
  },

  mounted: function () {
    this.$nextTick(function () {
      $.get(this.productsUrl, (data) => {
        this.options = data;
        this.value = this.options.find(option => option.sku_id === this.fields.product_item_sku_id);
      });
    })
  },

  computed: {
    eventOrActivities() {
      const activities = JSON.parse(this.initialActivities);
      const options = activities.map(activity => ({ id: activity[1], name: activity[0] }));
      return [{ label: I18n.t('shared.buttons.select_all'), options }];
    }
  }
}
</script>
