import Rails from '@rails/ujs';
import sweetAlert from 'sweetalert';
import SidePanel from './sidepanel';
import { serializeFormToJSON } from '../utils/form_serializer';

const QuestionsResponsesChanges = {
  start() {
    SidePanel.onUpdate($sidepanel => {
      $sidepanel
        .find('.js-questions-responses')
        .on('ajax:success', this._reloadResponsesTable)
    });
  },

  _reloadResponsesTable() {
    $('.js-track-responses-table').trigger('datatable:reload');
  }
};

export default QuestionsResponsesChanges;
