import LazyLoad from 'vanilla-lazyload';

const LazyLoadImages = {
  start() {
    const lazyload = new LazyLoad({
      elements_selector: '.lazy-load'
    });
    $(document).on('lazyload:update', () => lazyload.update());
  }
};

export default LazyLoadImages;
