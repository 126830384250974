import Rails from '@rails/ujs';
import Vue from 'vue/dist/vue.esm';
import AjaxErrorHandler from './ajax_error_handler';

const SIDEPANEL_SELECTOR = '.sidepanel.sidepanel--replaceable';
const SIDEPANEL_CONTENT_SELECTOR = '.sidepanel__content';

/*
  SidePanel data attributes description:

  1. `data-sidepanel=true`: Open the link url content in the sidepanel
  2. Check SidePanelForm for sidepanel internal forms behavior

  Sidepanel special classes:

  1. `form--readonly`: Prevents the form inside a sidepanel to be submitted when submit button
      is clicked.
  2. `sidepanel-vue-app`: Use this class to initialize a Vue app inside a sidepanel.
  3. `sidepanel-stack=true`: Open links inside the sidepanel using stack navigation
*/

const SidePanel = {
  _loaderHtml: null,
  _currentLocation: null,
  _stack: [],

  element() {
    return $(SIDEPANEL_SELECTOR);
  },

  start() {
    this._loaderHtml = this.element().html();
    $('[data-sidepanel]')
      .not((_index, el) => el.dataset.sidepanel === 'false')
      .on('click', this.loadAndShow.bind(this));
    this.onUpdate(($sidepanel) => {
      $sidepanel.find('[data-sidepanel-close]').on('click', this.reset.bind(this));
      $sidepanel.find('[data-sidepanel-submit]').on('click', this.submit.bind(this));
      $sidepanel.find('[data-sidepanel]').on('click', this.loadAndShow.bind(this));
      $sidepanel.find('[data-sidepanel-stack]').on('click', this.pushToStackAndLoad.bind(this));
    });
  },

  _isLoading() {
    return this._loaderHtml === this.element().html();
  },

  show(kind) {
    const $sidepanel = SidePanel.element();
    if (kind === 'big') {
      $sidepanel.addClass('sidepanel--big');
    }
    $sidepanel.addClass('is-visible').removeClass('is-invisible');
  },

  unload(location, callback) {
    callback();
  },

  load(location, params = {}) {
    this.unload(location, () => {
      $.get(this._buildURL(location, params), (content) => {
        this._currentLocation = location;
        this.updateContent(content);
      }).fail((jqXHR) => {
        AjaxErrorHandler.handleXhr(jqXHR)
          .then(() => {
            if (this._isLoading()) {
              this.reset();
            }
          })
          .catch(this.reset);
      });
    });
  },

  // skips links clicked inside a table row with data-sidepanel-href (useful for dropdowns)
  _shouldSkipClick(e) {
    return (
      e.target !== e.currentTarget &&
      e.currentTarget.dataset.sidepanelHref &&
      (['LABEL', 'INPUT', 'A'].includes(e.target.tagName) || e.target.parentNode.tagName === 'A')
    );
  },

  _buildURL(url, params) {
    const strParams = $.param(params);
    if (url.indexOf('?') >= 0) {
      return `${url}&${strParams}`;
    }
    return `${url}?${strParams}`;
  },

  loadAndShow(e, url) {
    if (this._shouldSkipClick(e)) {
      return true;
    }
    e.preventDefault();
    const $target = $(e.currentTarget);
    const location = url || $target.attr('href') || $target.data('sidepanel-href');
    if (!location || location === '#') {
      return true;
    }
    this.show($target.data('sidepanel'));
    $target.trigger('sidepanel:shown');
    this.load(location);
    return false;
  },

  loadFromAjax(ajaxParams) {
    this.show();
    return $.ajax(ajaxParams).done(this.updateContent.bind(this));
  },

  pushToStackAndLoad(e) {
    this._stack.push(this._currentLocation);
    this.loadAndShow(e);
  },

  pop(params = {}) {
    if (this._stack.length) {
      this.element().trigger('sidepanel:reset');
      this.load(this._stack.pop(), params);
      return true;
    }
    return false;
  },

  reset(e) {
    if (e) {
      e.preventDefault();
    }
    if (!SidePanel.pop()) {
      SidePanel.element()
        .html(SidePanel._loaderHtml)
        .addClass('is-invisible')
        .removeClass('is-visible')
        .removeClass('sidepanel--big')
        .trigger('sidepanel:reset');
    }
  },

  submit(e) {
    e.preventDefault();
    const $form = this.element().find('form[data-remote]:not(.form--readonly)');
    if ($form.length) {
      Rails.fire($form.get(0), 'submit');
    }
  },

  disableSubmit() {
    SidePanel.element()
      .find('[data-sidepanel-submit]')
      .attr('disabled', true);
  },

  enableSubmit() {
    SidePanel.element()
      .find('[data-sidepanel-submit]')
      .attr('disabled', false);
  },

  scrollToFirstError() {
    const $fieldWithError = this.element().find('.field_with_errors:visible');
    if ($fieldWithError.length) {
      $(SIDEPANEL_CONTENT_SELECTOR).animate(
        {
          scrollTop: $fieldWithError.offset().top - 100
        },
        2000
      );
    }
  },

  bootVueApp() {
    const $sidepanel = this.element();
    if ($sidepanel.find('.sidepanel-vue-app').length) {
      return new Vue({
        el: '.sidepanel-vue-app'
      });
    }
    return null;
  },

  updateContent(content) {
    const $sidepanel = this.element();
    $sidepanel.html(content);
    this.bootVueApp();
    $sidepanel.trigger('sidepanel:updated');
    $sidepanel.find('.jq_watermark').watermark();
    this.scrollToFirstError();
  },

  onUpdate(callback) {
    this.element().on('sidepanel:updated', () => callback(this.element()));
  },

  onReset(callback) {
    this.element().on('sidepanel:reset', () => callback(this.element()));
  },

  tearDown() {
    $('[data-sidepanel]').off('click', this.loadAndShow.bind(this));
  }
};

export default SidePanel;
