import sweetAlert from 'sweetalert';

const ListDeleteMultiple = {
  start() {
    $('.js-delete-lists').click(
      this._deleteMultipleWithConfirmation.bind(this)
    );
  },

  _deleteMultipleWithConfirmation(event) {
    const $dataTable = $('.dataTable');
    const table = $dataTable.DataTable();
    const selectedRows = table.rows({ selected: true });
    const selectedRowsData = selectedRows.data();
    const ids = selectedRowsData.map(this._extractRecordIds).toArray();
    const listNames = selectedRowsData.map(this._extractRecordNames).join('; ');
    const singularName = event.currentTarget.dataset.recordSingular;

    if (ids.length === 1) {
      $(`.segmented_list_row_id_${ids[0]} .js-delete-single-list`)[0].click();
    } else {
      sweetAlert({
        title: `Deleting ${ids.length} ${singularName}`,
        text: `You are deleting the following ${singularName} and the action will be permanent: ${listNames}`,
        icon: 'warning',
        dangerMode: true,
        buttons: ['Cancel', 'Delete']
      }).then(result => {
        if (result) {
          this._deleteMultiple(event, ids);
        }
      });
    }
  },

  _deleteMultiple(event, ids) {
    $.ajax({
      url: event.currentTarget.dataset.deleteUrl,
      method: 'DELETE',
      data: { ids }
    });
  },

  _extractRecordIds(element) {
    return $(element[0])[0].dataset.id;
  },

  _extractRecordNames(element) {
    return $(element[1]).data('recordName');
  }
};

export default ListDeleteMultiple;
