const REFRESH_INTERVAL = 5000;

const CoverImageRefresh = {
  start() {
    $('.is-processing[data-image-reload]').each((_, element) => {
      const $element = $(element);
      this.refresh($element.data('image-reload'), data => this.showImage($element, data));
    });
  },

  refresh(url, callback) {
    $.ajax({
      url,
      dataType: 'json',
      statusCode: {
        200: callback,
        206: () => {
          setTimeout(() => this.refresh(url, callback), REFRESH_INTERVAL);
        }
      }
    });
  },

  showImage($element, data) {
    const $backdrop = $element.siblings('.cover__backdrop');
    const className = $backdrop.length ? 'cover__image' : '';
    if (data && data.thumb) {
      $element.html(`<img src=${data.thumb} class="${className}">`);
      if (data.small) {
        $backdrop.html(`<img src=${data.small} class="${className}">`);
      }
    }
    $element.removeClass('is-processing');
    $backdrop.removeClass('is-processing');
  }
};

export default CoverImageRefresh;
