import SidePanel from './sidepanel';

const EventRegistrationRadio = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel
        .find('.js-registration-radio')
        .on('change', this.registrationRadioHandler.bind(this));
    });
  },

  registrationRadioHandler(event) {
    const $sidepanel = $(event.currentTarget).closest('.sidepanel-vue-app');
    const $visibilityFieldset = $sidepanel.find('.js-event-visibility');
    if (event.currentTarget.value === 'open') {
      $visibilityFieldset.find('input:radio[value="public"]').prop('checked', true).change();
      $visibilityFieldset.find('input:radio').prop('disabled', true);
    } else {
      $visibilityFieldset.find('input:radio').prop('disabled', false);
    }
  }
};

export default EventRegistrationRadio;
