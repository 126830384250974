import Rails from '@rails/ujs';
import { Controller } from 'stimulus';
import sweetAlert from 'sweetalert';
import I18n from '../i18n-js/index.js.erb';

const t = (key, opts) => I18n.t(key, {
  scope: 'js.my_events.bulk_attendances',
  ...opts
});
const tShared = (key, opts) => I18n.t(key, {
  scope: 'shared',
  ...opts
});

export default class extends Controller {
  static values = { availableSpots: Number, itemsCount: Number, waitlistEnabled: Boolean, resourceName: String, linkedEventPart: Boolean };

  static targets = ['attendanceForm', 'listItemIds', 'attendance'];

  connect() {
    this.confirmed = false;
    this.attendanceFormTarget.addEventListener('submit', this.submitAttendanceForm.bind(this));
  }

  submitAttendanceForm(event) {
    if (this.confirmed) {
      return;
    }

    this.modalForOverallEventAttendance(event);
    this.modalForLinkedEventPartAttendance(event);
    this.modalForWaitlistExcedingLimit(event);
    this.modalForExcedingLimitWithoutWaitlist(event);
  }

  modalForLinkedEventPartAttendance(event) {
    if (this.resourceNameValue === 'event_part' && this.linkedEventPartValue) {
      const attendanceValue = this._attendanceValue();
      if (!['not_attending', 'undecided'].includes(attendanceValue)) {
        return;
      }
      event.preventDefault();
      event.stopImmediatePropagation();
      const message = t(`confirmation.event_part_${attendanceValue}`);
      sweetAlert({
        title: tShared('general.warning'),
        text: message,
        icon: 'warning',
        buttons: [tShared('buttons.cancel'), tShared('buttons.confirm')]
      }).then((confirmed) => {
        this.confirmed = confirmed;

        if (this.confirmed) {
          Rails.fire(this.attendanceFormTarget, 'submit');
        }
      });
    }
  }

  modalForOverallEventAttendance(event) {
    if (this.resourceNameValue === 'event') {
      const attendanceValue = this._attendanceValue();
      if (!['not_attending', 'undecided'].includes(attendanceValue)) {
        return;
      }
      event.preventDefault();
      event.stopImmediatePropagation();
      const message = t(`confirmation.event_${attendanceValue}`);
      sweetAlert({
        title: tShared('general.warning'),
        text: message,
        icon: 'warning',
        buttons: [tShared('buttons.cancel'), tShared('buttons.confirm')]
      }).then((confirmed) => {
        this.confirmed = confirmed;

        if (this.confirmed) {
          Rails.fire(this.attendanceFormTarget, 'submit');
        }
      });
    }
  }

  modalForWaitlistExcedingLimit(event) {
    const attendanceValue = this._attendanceValue();
    const attendingExceedingAvailableSpots = (attendanceValue === 'attending' && this.hasAvailableSpotsValue && this.itemsCountValue > this.availableSpotsValue);
    if (attendingExceedingAvailableSpots && this.waitlistEnabledValue) {
      event.preventDefault();
      event.stopImmediatePropagation();
      const waitlistCount = this.itemsCountValue - this.availableSpotsValue;
      sweetAlert({
        title: tShared('general.warning'),
        text: t('exceeding_with_waitlist_message', { waitlist_count: waitlistCount }),
        icon: 'warning',
        buttons: [tShared('buttons.cancel'), tShared('buttons.confirm')]
      }).then((confirmed) => {
        this.confirmed = confirmed;

        if (this.confirmed) {
          Rails.fire(this.attendanceFormTarget, 'submit');
        }
      });
    }
  }

  modalForExcedingLimitWithoutWaitlist(event) {
    const attendanceValue = this._attendanceValue();
    const attendingExceedingAvailableSpots = (attendanceValue === 'attending' && this.hasAvailableSpotsValue && this.itemsCountValue > this.availableSpotsValue);
    if (attendingExceedingAvailableSpots && !this.waitlistEnabledValue) {
      event.preventDefault();
      event.stopImmediatePropagation();
      const exceedingCount = this.itemsCountValue - this.availableSpotsValue;
      sweetAlert({
        title: tShared('general.warning'),
        text: t('exceeding_without_waitlist_message', { exceeding_count: exceedingCount }),
        icon: 'warning',
        button: {
          text: 'OK',
          value: true,
          visible: true,
          closeModal: true
        }
      });
    }
  }

  _attendanceValue() {
    const checkedAttendance = this.attendanceTargets.find((attendance) => attendance.checked);
    if (checkedAttendance) {
      return checkedAttendance.value;
    }
    return null;
  }
}
