import Choices from 'choices.js';
import SidePanel from './sidepanel';

const Tags = {
  start() {
    SidePanel.onUpdate($sidepanel => {
      const $tagsPicker = $sidepanel.find('.js-tags-picker');
      if ($tagsPicker.length > 0) {
        this.hookUpChoicesPlugin($tagsPicker[0]);
      }
    });
  },

  hookUpChoicesPlugin(element) {
    const choices = new Choices(element, {
      searchEnabled: false,
      removeItemButton: true,
      callbackOnCreateTemplates() {
        return {
          item(
            { item, button, highlightedState, itemSelectable, placeholder },
            { id, value, label, customProperties, active, disabled, highlighted, placeholder: isPlaceholder },
            removeItemButton
          ) {
            const div = Object.assign(document.createElement('div'), {
              className: item,
              innerHTML: label
            });

            Object.assign(div.dataset, {
              item: '',
              id,
              value,
              customProperties
            });

            if (active) {
              div.setAttribute('aria-selected', 'true');
            }

            if (disabled) {
              div.setAttribute('aria-disabled', 'true');
            }

            if (isPlaceholder) {
              div.classList.add(placeholder);
            }

            // Bobcat
            div.classList.add('tag', `tag--${JSON.parse(customProperties).color}`);

            if (removeItemButton) {
              if (disabled) {
                div.classList.remove(itemSelectable);
              }
              div.dataset.deletable = '';
              const REMOVE_ITEM_TEXT = 'Remove item';
              const removeButton = Object.assign(document.createElement('button'), {
                type: 'button',
                className: button,
                innerHTML: REMOVE_ITEM_TEXT
              });
              removeButton.setAttribute('aria-label', `${REMOVE_ITEM_TEXT}: '${value}'`);
              removeButton.dataset.button = '';
              div.appendChild(removeButton);
            }

            return div;
          },

          choice(
            { item, itemChoice, itemSelectable, selectedState, itemDisabled, placeholder },
            { id, value, label, groupId, elementId, disabled: isDisabled, selected: isSelected, placeholder: isPlaceholder, customProperties },
            selectText
          ) {
            const div = Object.assign(document.createElement('div'), {
              id: elementId,
              innerHTML: '', // label
              className: `${item} ${itemChoice}`
            });

            // Bobcat
            const tag = Object.assign(document.createElement('span'), {
              innerHTML: label,
              className: `tag tag--${JSON.parse(customProperties).color}`
            });

            div.appendChild(tag);

            if (isSelected) {
              div.classList.add(selectedState);
            }

            if (isPlaceholder) {
              div.classList.add(placeholder);
            }

            div.setAttribute('role', groupId && groupId > 0 ? 'treeitem' : 'option');

            Object.assign(div.dataset, {
              choice: '',
              id,
              value,
              selectText
            });

            if (isDisabled) {
              div.classList.add(itemDisabled);
              div.dataset.choiceDisabled = '';
              div.setAttribute('aria-disabled', 'true');
            } else {
              div.classList.add(itemSelectable);
              div.dataset.choiceSelectable = '';
            }

            return div;
          }
        };
      }
    });
  }
};

export default Tags;
