/* jshint esversion: 6 */
import Rails from '@rails/ujs';
import { Turbo } from '@hotwired/turbo-rails';
import { Application as Stimulus } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import Vue from 'vue/dist/vue.esm';
import VueResource from 'vue-resource';
import VueChartist from 'vue-chartist';
import Vue2TouchEvents from 'vue2-touch-events';
import VueLazyload from 'vue-lazyload';
import TurboAdapter from '../src/js/vue_turbolinks';

import 'font-awesome/css/font-awesome.css';
import '../application/v2.scss';
import '../styleguide/styleguide';
import '../datatables/datatables-v2';
import '../application/vendor/sweet-alert-2-confirm';
import AjaxErrorHandler from '../application/app/ajax_error_handler';
import JqueryApp from '../application/v2';

import addressFields from '../src/components/address_fields.vue';
import assignments from '../src/components/assignments.vue';
import columnsEditorWithSelect from '../src/components/columns_editor_with_select.vue';
import advancedFiltersEditor from '../src/components/advanced_filters_editor.vue';
import packagePricesEditor from '../src/components/package_prices_editor.vue';
import sortBy from '../src/components/sort_by.vue';
import filtersDropdown from '../src/components/filters_dropdown.vue';
import donutChart from '../src/components/donut_chart.vue';
import halfDonutChart from '../src/components/half_donut_chart.vue';
import pieChart from '../src/components/pie_chart.vue';
import barChart from '../src/components/bar_chart.vue';
import horizontalBarChart from '../src/components/horizontal_bar_chart.vue';
import feedback from '../src/components/feedback.vue';
import datepickerWithTimezone from '../src/components/datepicker_with_timezone.vue';
import datepickerRangeWithTimezone from '../src/components/datepicker_range_with_timezone.vue';
import locationAutocomplete from '../src/components/location_autocomplete.vue';
import copyEvent from '../src/components/copy_event.vue';
import eventImagePicker from '../src/components/event_image_picker.vue';
import eventResponsesAccepted from '../src/components/event_responses_accepted.vue';
import eventPartResponsesAccepted from '../src/components/event_part_responses_accepted.vue';
import copyEventPart from '../src/components/copy_event_part.vue';
import copyProduct from '../src/components/copy_product.vue';
import eventPartsForProducts from '../src/components/event_parts_for_products.vue';
import participantEvents from '../src/components/participant_events.vue';
import editDescriptionTabs from '../src/components/edit_description_tabs.vue';
import renderComponent from '../src/components/render_component.vue';

window.Turbo = Turbo;

Vue.use(VueResource);
Vue.use(TurboAdapter);
Vue.use(VueChartist);
Vue.use(Vue2TouchEvents, { longTapTimeInterval: 200 });
Vue.use(VueLazyload);

Vue.component('address-fields', addressFields);
Vue.component('assignments', assignments);
Vue.component('columns-editor-with-select', columnsEditorWithSelect);
Vue.component('advanced-filters-editor', advancedFiltersEditor);
Vue.component('package-prices-editor', packagePricesEditor);
Vue.component('event-image-picker', eventImagePicker);
Vue.component('sort-by', sortBy);
Vue.component('filters-dropdown', filtersDropdown);
Vue.component('donut-chart', donutChart);
Vue.component('half-donut-chart', halfDonutChart);
Vue.component('pie-chart', pieChart);
Vue.component('bar-chart', barChart);
Vue.component('horizontal-bar-chart', horizontalBarChart);
Vue.component('feedback', feedback);
Vue.component('datepicker-with-timezone', datepickerWithTimezone);
Vue.component('datepicker-range-with-timezone', datepickerRangeWithTimezone);
Vue.component('location-autocomplete', locationAutocomplete);
Vue.component('copy-event', copyEvent);
Vue.component('event-responses-accepted', eventResponsesAccepted);
Vue.component('event-part-responses-accepted', eventPartResponsesAccepted);
Vue.component('copy-event-part', copyEventPart);
Vue.component('copy-product', copyProduct);
Vue.component('event-parts-for-products', eventPartsForProducts);
Vue.component('participant-events', participantEvents);
Vue.component('edit-description-tabs', editDescriptionTabs);
Vue.component('render-component', renderComponent);

function bootVueApp() {
  if (document.querySelector('.vue-app')) {
    return new Vue({
      el: '.vue-app'
    });
  }
  return null;
}

document.addEventListener('turbo:before-fetch-request', event => {
  const { detail: { fetchOptions } } = event;
  const { darkmode } = window.localStorage;
  fetchOptions.headers['X-Darkmode'] = darkmode;
});

document.addEventListener('turbo:load', () => {
  bootVueApp();
  JqueryApp.start();
  $.rails.refreshCSRFTokens();
});

document.addEventListener('turbo:before-cache', () => {
  JqueryApp.tearDown();
});

Rails.start();
AjaxErrorHandler.start();

// Stimulus
const stimulusApp = Stimulus.start();
const stimulusContext = require.context('controllers', true, /.js$/);
stimulusApp.load(definitionsFromContext(stimulusContext));

// react-rails

// const componentRequireContext = require.context('components', true);
// const ReactRailsUJS = require('react_ujs');

// ReactRailsUJS.useContext(componentRequireContext);
