import ClipboardJS from 'clipboard';

const CopyToClipboard = {
  start() {
    const clipboardTarget = new ClipboardJS('[data-clipboard-target]');
    clipboardTarget.on('success', this.copySuccess);
    const clipboardText = new ClipboardJS('[data-clipboard-text]');
    clipboardText.on('success', this.copySuccess);
  },

  copySuccess(e) {
    const tooltip = $(e.trigger).data('tooltip');
    $(e.trigger).attr('data-tooltip', 'Copied!');
    setTimeout(() => {
      $(e.trigger).attr('data-tooltip', tooltip);
    }, 3000);
  }
};

export default CopyToClipboard;
