import SidePanel from './sidepanel';

const SearchList = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      $sidepanel.on('keyup', '[data-search-list]', this._handleSearch);
    });
  },

  _handleSearch(e) {
    const $input = $(e.currentTarget);
    const itemSelector = $input.data('search-list');
    const searchTerm = $input.val().toLowerCase();
    if (searchTerm) {
      $(itemSelector).each((_, item) =>
        $(item).toggle($(item)
          .text()
          .toLowerCase()
          .indexOf(searchTerm) > -1));
    } else {
      $(itemSelector).show();
    }
  }
};

export default SearchList;
