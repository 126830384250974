<template>
  <div>
    <div class="sidepanel__block">
      <h6 class="subtitle subtitle--center">{{subtitle}}</h6>
      <hr />
    </div>

    <div class="sidepanel__block">
      <fieldset>
        <label>{{t('customize_your_event_picture')}}</label>
        <input
          v-model="picked"
          type="radio"
          value="unsplash"
          name="Image Settings"
          id="imageSearch"
          class="radio"
        />
        <label class="voidclick" for="imageSearch">{{t('select_online')}}</label>
        <input
          v-model="picked"
          type="radio"
          value="upload"
          name="Image Settings"
          id="imageUpload"
          class="radio"
        />
        <label class="voidclick" for="imageUpload">{{t('upload_image')}}</label>
        <input
          v-model="picked"
          type="radio"
          value="default"
          name="Image Settings"
          id="imageDefault"
          class="radio"
        />
        <label class="voidclick" for="imageDefault">{{t('use_default')}}</label>
      </fieldset>
    </div>

    <div :class="{ 'is-hidden': picked != 'default' }" class="sidepanel__block">
      <div class="avatar avatar--shadowed has-full-width">
        <div class="avatar__holder">
          <svg class="icon icon--large icon--white cover__icon">
            <use :xlink:href="iconEventsUrl" />
          </svg>
        </div>
      </div>
    </div>

    <div
      :class="{ 'is-hidden': picked != 'unsplash' }"
      class="sidepanel__block sidepanel__block--merged"
    >
      <div class="search sidepanel__search sidepanel__search--sticky">
        <input
          @input="debounceSearch"
          type="search"
          :placeholder="t('search_here')"
          class="input search__input search__input--transparent"
        />
        <svg class="search__icon icon icon--tiny icon--slategray">
          <use :xlink:href="iconSearchUrl" />
        </svg>
        <div
          class="loader--spinner loader--auto search__loader"
          :class="[isLoading ? 'is-visible' : 'is-invisible']"
        ></div>
      </div>

      <small
        class="text--italic has-center-text"
        :class="[!isLoading && images.length > 0 ? 'is-shown' : 'is-hidden']"
      >
        Photos by
        <a href="https://unsplash.com/" target="_blank">Unsplash</a>
      </small>

      <p
        class="alert alert--info has-full-width no-space-out"
        :class="[!isLoading && images.length == 0 ? 'is-shown' : 'is-hidden']"
      >{{t('no_results')}}</p>

      <div
        class="container__row container__row--spaced container__row--maintain container__row--middle container__row--break is-relative"
      >
        <unsplash-image-card
          :handleSelect="handleSelectImage"
          v-for="image in images"
          :image="image.attributes.table"
          :key="image.attributes.table.id"
        ></unsplash-image-card>
        <infinite-loading ref="InfiniteLoading" @infinite="infiniteHandler"></infinite-loading>
      </div>
    </div>

    <div :class="{ 'is-hidden': picked != 'upload' }" class="sidepanel__block">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { Turbo } from "@hotwired/turbo-rails";
import InfiniteLoading from "vue-infinite-loading";
import UnsplashImageCard from "./unsplash_image_card";
import I18n from 'i18n-js/index.js.erb';
const t = (key, opts) => I18n.t(key, {
  scope: 'my_events.event_images.event_image_picker',
  ...opts
});

export default {
  props: {
    subtitle: {
      type: String
    },
    iconsUrl: {
      type: String
    },
    initialPicked: {
      type: String,
      default: "unsplash"
    },
    updateUrl: {
      type: String
    },
    searchUrl: {
      type: String
    }
  },
  data: function() {
    return {
      currentPage: 1,
      currentSearch: '',
      picked: this.initialPicked,
      imagesWithoutSearch: [],
      images: [],
      selectedImage: null,
      debounce: null,
      isLoading: true
    };
  },
  components: {
    UnsplashImageCard,
    InfiniteLoading
  },
  watch: {
    picked(val) {
      const $sidepanel = $(this.$el).closest(".sidepanel--replaceable");
      const $saveButton = $sidepanel.find(".js-save-button");
      if (val === "default") {
        $saveButton.removeClass("is-hidden");
      } else {
        $saveButton.addClass("is-hidden");
      }
    }
  },
  methods: {
    t,
    infiniteHandler($state) {
      this.isLoading = true;
      this.currentPage += 1;
      this.searchFor(this.currentSearch, (imagesResponse) => {
        if (imagesResponse.body.length) {
          $state.loaded();
        } else {
          $state.complete();
        }
      });
    },
    handleSelectImage(image) {
      this.selectedImage = image;
      const authenticityToken = $('meta[name="csrf-token"]')[0].content;
      const requestOptions = {
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": authenticityToken
        }
      };
      const params = { payload: image };
      this.$http
        .patch(this.updateUrl, params, requestOptions)
        .then(this._selectImageCallback);
    },
    debounceSearch(event) {
      this.isLoading = true;
      this.currentPage = 1;
      clearTimeout(this.debounce);
      this.currentSearch = event.target.value;
      if (this.currentSearch === "") {
        this.images = this.imagesWithoutSearch;
        this.isLoading = false;
        this.$refs.InfiniteLoading.stateChanger.reset();
        return;
      }
      this.debounce = setTimeout(() => {
        this.$refs.InfiniteLoading.stateChanger.reset();
        this.searchFor(this.currentSearch);
      }, 600);
    },
    _successSearch(response) {
      if (this.currentPage === 1) {
        $('.sidepanel--replaceable .sidepanel__content').scrollTop(0);
        this.images = response.body;
      } else {
        this.images = this.images.concat(response.body);
      }
      this.isLoading = false;
    },
    _imagesSetup(response) {
      this.images = response.body;
      this.imagesWithoutSearch = response.body;
      this.isLoading = false;
    },
    searchFor(term, successCallback = null) {
      const params = { search: encodeURI(term), page: this.currentPage };
      const requestOptions = {
        responseType: "json",
        headers: {
          "Content-Type": "application/json"
        }
      };
      return this.$http
        .get(this.searchUrl, { ...requestOptions, params })
        .then((response) => {
          this._successSearch(response);
          successCallback && successCallback(response);
        });
    },
    initializeImages() {
      const requestOptions = {
        responseType: "json",
        headers: {
          "Content-Type": "application/json"
        }
      };
      this.$http
        .get(this.searchUrl, { ...requestOptions })
        .then(this._imagesSetup);
    },
    _selectImageCallback() {
      Turbo.visit(window.location);
    }
  },
  created() {
    this.initializeImages();
  },
  computed: {
    iconEventsUrl() {
      return `${this.iconsUrl}#icon-events`;
    },
    iconSearchUrl() {
      return `${this.iconsUrl}#icon-search`;
    }
  }
};
</script>
