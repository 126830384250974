<template>
  <div class="list__block list__block--merged">
    <article :disabled="!item.tracking.attending || isDisabled" :class="{ 'is-selected': isSelected(item.attributes.id), 'pane--draggable': (!isDisabled && item.tracking.attending) }" class="pane pane--signed no-space-out has-full-width">
      <span class="pane__sign" style="background-color: rgb(106, 114, 161);"></span>
      <div class="pane__content pane__content--unbreak">
        <div class="pane__info has-full-width">
          <p class="no-space-out pane__text pane__text--truncate" :class="{ 'text--not-attending': item.tracking.attending === false }">
            <span class="has-truncate-text">{{item.attributes.full_name}}</span>
            <small class="has-truncate-text">{{labelHelpers}}</small>
          </p>
        </div>
      </div>
      <div class="pane__actions pane__actions--small pane__actions--hidden" :class="{ 'is-invisible': isDisabled }">
        <div @mouseleave="removeIsActiveOnDropdown" class="dropdown dropdown--floating dropdown--tiny">
          <div class="dropdown__holder">
            <a href="#" @click.prevent.stop="toggleIsActive" @mouseover="addIsActive" class="dropdown__button no-space-in">
              <svg class="icon icon--tiny icon--slategray dropdown__icon">
                <use :xlink:href="iconDotsVerticalUrl"></use>
              </svg>
            </a>
            <ul class="dropdown__list dropdown__list--medium dropdown__list--floating dropdown__list--right">
              <li class="dropdown__item" v-if="!isSelected(item.attributes.id) && isSelectable">
                <a href="#" @click.prevent.stop="selectPeopleIds([item.attributes.id])" class="dropdown__link dropdown__link--mask">
                  <span class="dropdown__legend">{{t('select')}}</span>
                </a>
              </li>
              <li class="dropdown__item" v-if="isSelected(item.attributes.id) && isSelectable">
                <a href="#" @click.prevent.stop="unselectPeopleIds([item.attributes.id])" class="dropdown__link dropdown__link--mask">
                  <span class="dropdown__legend">{{t('unselect')}}</span>
                </a>
              </li>
              <li class="dropdown__item">
                <a href="#" @click.prevent.stop="loadOnSidepanel(item)" class="dropdown__link dropdown__link--mask">
                  <span class="dropdown__legend">{{t('view_details')}}</span>
                </a>
              </li>
              <li :class="{ 'is-hidden': !item.tracking.attending }" class="dropdown__item">
                <a href="#" @click.prevent.stop class="dropdown__link dropdown__link--mask">
                  <span class="dropdown__legend">Assign</span>
                  <select :data-person-id="item.attributes.id"
                          @change="addPeopleToPlace"
                          v-model="selectedPlace"
                          class="select select--invisible no-space-out no-space-in dropdown__select">
                    <option disabled selected value="">{{t('choose_one_place')}}</option>
                    <option :disabled="!hasAvailableSpots(place)" v-for="place in places" :key="place.attributes.id" :value="place.attributes.id">
                      {{placeNameWithSpots(place)}}
                    </option>
                  </select>
                </a>
              </li>
              <li class="dropdown__item is-web-hidden">
                <a href="#" @click.prevent.stop="removeIsActive" class="dropdown__link">
                  <span class="dropdown__legend is-danger">{{t('close')}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
  import { addIsActive, removeIsActiveOnDropdown, removeIsActive, toggleIsActive } from '../../js/dropdown_actions';
  import { placeNameWithSpots, hasAvailableSpots } from '../../js/spots_counter';
  import I18n from 'i18n-js/index.js.erb';
  const t = (key, opts) => I18n.t(key, {
    scope: 'assignments.assignments_component.person_component',
    ...opts
  });

  export default {

    props: [
      'requestForPeopleToPlace',
      'iconDotsVerticalUrl',
      'isDisabled',
      'isSelected',
      'isSelectable',
      'unselectPeopleIds',
      'selectPeopleIds',
      'peopleLabels',
      'item',
      'places',
      'loadOnSidepanel'
    ],
    data() {
      return {
        selectedPlace: null,
        addIsActive,
        removeIsActiveOnDropdown,
        removeIsActive,
        placeNameWithSpots,
        hasAvailableSpots,
        toggleIsActive,
      }
    },
    methods: {
      t,
      addPeopleToPlace(event) {
        const placeId = this.selectedPlace;
        const personId = $(event.target).data('personId');
        this.requestForPeopleToPlace(placeId, [personId]);
        this.selectedPlace = null;
      }
    },
    computed: {
      labelHelpers() {
        if (!this.peopleLabels) { return '' };
        return this.peopleLabels.map(key => _.get(this.item, key)).join(' • ');
      }
    }
  }
</script>
