import flatpickr from 'flatpickr';
import 'jquery-datetimepicker';
import 'jquery-datetimepicker/jquery.datetimepicker.css';
import SidePanel from './sidepanel';

const DateTimePicker = {
  KEYS: {
    BACKSPACE: 8,
    DEL: 46,
    TAB: 9
  },

  trigger(el, type) {
    const e = document.createEvent('HTMLEvents');
    e.initEvent(type, true, true);
    el.dispatchEvent(e);
  },

  _DEFAULTS: {
    formatDate: 'M d, Y',
    formatTime: 'g:i A',
    format: 'M d, Y h:i A',
    allowBlank: true,
    defaultSelect: false,
    validateOnBlur: false,
    scrollInput: false,
    scrollMonth: false,
    scrollTime: false,
    onSelectDate(value, elem) {
      DateTimePicker.trigger(elem[0], 'change');
    },
  },

  start() {
    SidePanel.onUpdate(() => {
      if ($('input.datetimepicker').length) {
        this.setupFlatpicker($('input.datetimepicker'));
      }
    });
  },

  setupFlatpicker($elements) {
    const flatPickerInstances = $elements.toArray().map(el => {
      const $el = $(el);
      const datePickerOptions = $el.data('datepicker-options');
      const flatpickerOptions = { ...this._DEFAULTS, ...datePickerOptions };

      const instance = $el.flatpickr({
        allowInput: true,
        enableTime: flatpickerOptions.timepicker,
        noCalendar: !flatpickerOptions.datepicker,
        dateFormat: flatpickerOptions.format.replace('A', 'K')
      });

      $el.bind('cut copy paste', e => e.preventDefault());

      return instance;
    });

    $('.sidepanel .sidepanel__content').on('scroll', () => {
      flatPickerInstances.forEach(instance => instance._positionCalendar());
    });
  },

  setupDateTimePicker(entries) {
    $('.datetimepicker').keypress(function(e) {
      e.preventDefault();
    });

    const self = this;
    entries.each(function(index, entry) {
      const $entry = $(entry);
      const options = $entry.data('datepicker-options');
      $entry.datetimepicker($.extend(self._DEFAULTS, options));
      $entry.keydown(function(event) {
        const key = event.which;
        if (key === self.KEYS.TAB) { return true; }
        if (key === self.KEYS.BACKSPACE || key === self.KEYS.DEL) {
          $entry.datetimepicker('hide');
          $entry.val(null);
        }
        return false;
      });
      $entry.bind('cut copy paste', function(e) {
        e.preventDefault();
      });
    });
  },

  teardown() {
    $('.sidepanel .sidepanel__content').off('scroll');
  }
};

export default DateTimePicker;
