import SidePanel from './sidepanel';
import SidePanelForm from './sidepanel_form';

const CONTAINER_SELECTOR = '.js-select-with-add';

const SelectWithAdd = {
  start() {
    const $container = $(CONTAINER_SELECTOR);
    if ($container.length) {
      $container.find('[data-sidepanel]').on('click', this._hookFormSuccess.bind(this));
      $container.find('select').on('change', this._handleSelectChange.bind(this));
    }
  },

  _findContainer(e) {
    return $(e.target)
      .parents(CONTAINER_SELECTOR)
      .first();
  },

  _hookFormSuccess(e) {
    const $container = this._findContainer(e);
    const keepSelection = Boolean(e.currentTarget.dataset.hrefTemplate);
    SidePanelForm.redirectOnSuccessCallback = () => {
      this._reloadSelectItems($container, { keepSelection });
      SidePanel.reset();
    };
  },

  _reloadSelectItems($container, opts = {}) {
    const $select = $container.find('select[data-reload-url]');
    $.ajax({
      url: $select.data('reloadUrl')
    }).done((response) => {
      this._renderSelectItems($select, response, opts);
    });
  },

  _renderSelectItems($select, { items, selected }, { keepSelection }) {
    const $blank = $select.find('option:first').clone();
    const selectedValue = $select.val();
    $select.empty();
    $select.append($blank);
    const options = items.map(([caption, value, attrs]) =>
      $('<option></option>')
        .attr(attrs)
        .attr('value', value)
        .text(caption));
    $select.append(options);
    $select.val(keepSelection ? selectedValue : selected).change();
  },

  _handleSelectChange(e) {
    const $select = $(e.target);
    const $container = this._findContainer(e);
    $container.find('[data-href-template]').each((_, linkContainer) => {
      const newHref = $select.val()
        ? linkContainer.dataset.hrefTemplate.replace('LIST_ID', $select.val())
        : '#';
      $(linkContainer).attr('href', newHref);
    });
  }
};

export default SelectWithAdd;
