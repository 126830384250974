import SidePanel from './sidepanel';

const ListCreation = {
  start() {
    SidePanel.onUpdate(($sidepanel) => {
      const listForm = $sidepanel.find('.list-form');
      if (listForm.length) {
        this.watchSelectListChange($sidepanel);
      }
    });
  },

  setNameForCopy(originalName, $target) {
    const prefix = $target.data('copyPrefix');
    $target
      .closest('form')
      .find('input.js-name-for-copy')
      .val(`${prefix} ${originalName}`)
      .change();
  },

  watchSelectListChange($sidepanel) {
    const $selectWithCopySegmentedList = $sidepanel.find('select.js-copy-segmented-list');
    $selectWithCopySegmentedList.change((event) => {
      const $target = $(event.target);
      const name = $target.find('option:selected').text();
      this.setNameForCopy(name, $target);
    });
    $selectWithCopySegmentedList.change();
  }
};

export default ListCreation;
