<template>
  <ul class="list list--bordered">
    <li class="list__item">
      <div class="list__block list__block--tighter">
        <label class="no-space-out">
          <strong>{{title}}</strong>
        </label>
        <button @click.prevent="clearSort" class="button button--circle button--sky list__button tooltip" data-tooltip="Undo">
          <svg class="icon icon--tiny">
            <use :xlink:href="iconUndoUrl"></use>
          </svg>
        </button>
      </div>
    </li>
    <li class="list__item" v-for="(field, index) in sort">
      <div class="list__block list__block--tighter">
        <select v-model="field.field_id" :name="fieldNamePrefix(index, 'field_id')" class="select has-three-width no-space-out">
          <option value="">Please select</option>
          <option v-for="option in options" :value="option[1]">{{option[0]}}</option>
        </select>
        <fieldset :class="(field.field_id || '') === '' ? 'is-hidden' : ' has-three-width'">
          <input :name="fieldNamePrefix(index, 'direction')"
                 :id="fieldNamePrefix(index, 'direction_asc')"
                 v-model="field.direction"
                 value="asc"
                 type="radio"
                 class="radio">
          <label :for="fieldNamePrefix(index, 'direction_asc')" class="no-space-out voidclick">Ascending</label>
        </fieldset>
        <fieldset :class="(field.field_id || '') === '' ? 'is-hidden' : ' has-three-width'">
          <input :name="fieldNamePrefix(index, 'direction')"
                 :id="fieldNamePrefix(index, 'direction_desc')"
                 v-model="field.direction"
                 value="desc"
                 type="radio"
                 class="radio">
          <label :for="fieldNamePrefix(index, 'direction_desc')" class="no-space-out voidclick">Descending</label>
        </fieldset>
      </div>
    </li>
    <li class="list__item">
      <div class="list__block list__block--tighter">
        <button @click.prevent="addNewSortField" class="button button--sky button--light button--square button--full no-space-out">
          <div class="button__holder">
            <span class="button__text">Then Sort by</span>
            <svg class="icon button__icon">
              <use :xlink:href="iconPlusUrl"></use>
            </svg>
          </div>
        </button>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    'field-name': String,
    'initialSort': String,
    'initialOptions': String,
    'svgIconsUrl': {
      type: String,
      required: true
    },
    'title': {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sort: JSON.parse(this.initialSort),
      options: JSON.parse(this.initialOptions)
    }
  },
  methods: {
    fieldNamePrefix(position, field) {
      return `${this.fieldName}[${position}][${field}]`;
    },
    addNewSortField() {
      this.sort.push({field_id: '', direction: 'asc'});
    },
    clearSort() {
      this.sort = [{field_id: '', direction: 'asc'}];
    }
  },
  computed: {
    iconUndoUrl() {
      return `${this.svgIconsUrl}#icon-undo`;
    },
    iconPlusUrl() {
      return `${this.svgIconsUrl}#icon-plus`;
    },
  }
}
</script>
