<template>
  <div>
    <div v-show='!isEditing'>
      <fieldset>
        <label :for='label1' v-bind:class='labelOptions'>{{label}}</label>
        <input :id='label1' type='text' placeholder='Click here to add your address' v-bind:class="['input', inputOptions]" size=40 v-model='fullAddress' @click='showEditing()'>
      </fieldset>
    </div>
    <input type='text' class='is-hidden' v-model='rawAddress' :name=fieldInputName>
    <div v-show='isEditing'>
      <fieldset>
        <label :for='label2' v-bind:class='labelOptions'>{{label}}</label>
        <input :id='label2' type='text' v-model='addressParts[0]' placeholder='Street 1' class='input input--full'>
      </fieldset>
      <fieldset>
        <input type='text' v-model='addressParts[1]' placeholder='Street 2' class='input input--full'>
      </fieldset>
      <fieldset>
        <input type='text' v-model='addressParts[2]' placeholder='City' class='input input--full'>
      </fieldset>
      <fieldset>
        <input type='text' v-model='addressParts[3]' placeholder='State' class='input input--full'>
      </fieldset>
      <fieldset>
        <input type='text' v-model='addressParts[4]' placeholder='Zipcode' class='input input--full' v-bind:pattern="zipPattern" v-bind:title="zipTitle" maxlength="20">
      </fieldset>
      <fieldset>
        <select class='select select--full no-space-out' v-model='addressParts[5]' @change="countryChange">
          <option :value="undefined">Select a country</option>
          <option v-for="country in countries" v-bind:value="country.name" :disabled="!country.code">
            {{ country.name }}
          </option>
        </select>
      </fieldset>
    </div>
  </div>
</template>

<script>
  import _ from '../../application/vendor/lodash.custom';
  export default {
    props: [
      'initial-raw-address',
      'input-name',
      'label',
      'address-field-id',
      'labelOptions',
      'inputOptions'
    ],

    data: function() {
      return {
        isEditing: false,
        countries: [],
        addressParts: (this.$props.initialRawAddress || '').split('|'),
        zipPattern: null,
        fieldInputName: this.inputName.replace('ADDRESS_FIELD_ID', this.addressFieldId),
        zipTitle: null
      };
    },

    methods: {
      getCountriesList: function() {
        this.$http.get('/countries').then((response) => {
          this.countries = _.uniqBy([
            { name: "United States", code: "US" },
            { name: "Canada", code: "CA" },
            { name: "---------------", code: null },
            ...response.body
          ], 'code');
        });
      },

      showEditing: function() {
        if (!this.countries.length) {
          this.getCountriesList();
        }
        this.isEditing = true;
      },

      countryChange: function() {
        switch (this.addressParts[5]) {
          case 'United States':
            this.zipPattern = "[0-9]{5}(-[0-9]{4})?";
            this.zipTitle = "##### or #####-####, where # is a number";
            break;
          case 'Canada':
            this.zipPattern = "[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d";
            this.zipTitle = "Example: V0N 1B4";
            break;
          default:
            this.zipPattern = null;
            this.zipTitle = null;
            break;
        }
      }
    },

    computed: {
      rawAddress: function() {
        return this.addressParts.join("|");
      },

      fullAddress: function() {
        return this.addressParts.filter(function(v) { return v !== ""; }).join(", ");
      },

      label1() {
        return `address_of_${this.label}_1`;
      },

      label2() {
        return `address_of_${this.label}_2`;
      }
    }
  }
</script>
