const Discount = {
  start() {
    $('.js-apply-coupon').click(this._applyCoupon);
  },

  _applyCoupon(event) {
    event.preventDefault();
    const { currentTarget } = event;
    const { dataset: { calculateUrl } } = currentTarget;
    const discountCode = $('.js-discount-code').val();
    const $couponInput = $('.js-coupon-input');
    currentTarget.disabled = true;

    $.ajax({
      url: calculateUrl,
      method: 'PATCH',
      dataType: 'json',
      data: { coupon: discountCode },
    })
      .done((response) => {
        const { coupon } = response;
        const $section = $('.js-discount-section');
        $couponInput.val(discountCode);
        $section.removeClass('is-hidden');
        $section.find('.js-discount-cation-and-duration')
          .text(`${coupon.discount_caption} (${coupon.discount_duration})`);
        $('.js-total-price').text(coupon.price_with_discount);
        $('.js-billing-info').text(coupon.billing_info);
        currentTarget.disabled = false;
      })
      .fail((response) => {
        const { responseJSON: { error } } = response;
        $couponInput.val('');
        $('.js-coupon-error').text(error.message);
        $('.js-discount-section').addClass('is-hidden');
        $('.js-total-price').text(error.full_price);
        $('.js-billing-info').text(error.billing_info);
        currentTarget.disabled = false;
      });
  }
};

export default Discount;
