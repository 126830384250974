const ListFieldsByUser = {
  start() {
    this.watchAdd();
    this.watchRemove();
    this.watchReorder();
    this.watchSubmit();
  },

  moveItems(origin, dest) {
    $(origin)
      .find(':selected')
      .appendTo(dest);
  },

  watchAdd() {
    $('#add-columns-button').click(() => {
      this.moveItems('#unselected-columns', '#selected-columns');
    });
  },

  watchRemove() {
    $('#remove-columns-button').on('click', () => {
      this.moveItems('#selected-columns', '#unselected-columns');
    });
  },

  watchSubmit() {
    $('.list-fields-by-user-submit').click(function() {
      $('#selected-columns option').prop('selected', true);
      return true;
    });
  },

  watchReorder() {
    const self = this;
    $('.reorder-columns-button').click(function() {
      const $op = $('#selected-columns option:selected');
      const $this = $(this);
      if ($op.length) {
        if ($this.text() === 'Move Up') {
          self._moveUp($op);
        } else {
          self._moveDown($op);
        }
      }
    });
  },

  _moveUp($op) {
    $op.first().prev().before($op);
  },

  _moveDown($op) {
    $op.last().next().after($op);
  }
};

export default ListFieldsByUser;
